import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";

import * as echarts from "echarts";

@Component({
  selector: "app-statusbarchart",
  templateUrl: "./statusbarchart.component.html",
  styleUrls: ["./statusbarchart.component.scss"],
})

/**
 * E-chart component
 */
export class StatusBarchartComponent implements OnInit, OnChanges {
  @Input() gaugeArray: any;
  @Input() seriesdata: any;
  @Input() labels: any;
  constructor(private elementRef: ElementRef) {}
  data = {
    timeData: [
      "15:02:00",
      "15:03:00",
      "15:04:00",
      "15:05:00",
      "15:06:00",
      "15:07:00",
      "15:08:00",
      "15:09:00",
      "15:10:00",
      "15:11:00",
    ],
    // parts: [2,, 2, 2, 2];

    status: [1, 0, 1, 1, 1, 0, 0, 0, 0, 0],
    current: [10, 55, 3, 46, 24, 666, 3, 56, 4, 33],
    parts: [, , [3], , , 666, , 56, 4, ,],
  };
  echartsInstance: any;

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];
    console.log(this.gaugeArray, "combined data");
    console.log(this.seriesdata, "seriesdata", this.labels);
    this.gaugeChart(this.seriesdata);
    // this.gaugeChart(this.data);
  }
  ngOnChanges(): void {
    console.log(this.gaugeArray, "resss..............");
    console.log(this.seriesdata, "seriesdata", this.labels);
    this.gaugeChart(this.seriesdata);
  }
  /**
   * Fetch the chart data
   */
  options: any;
  formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  gaugeChart(seriesdata) {
    console.log("timee");

    this.options = {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          console.log(params);
          var value = params.value;
          // var hours = Math.floor(value / 3600);
          // var minutes = Math.floor((value % 3600) / 60);
          // var seconds = Math.floor(value % 60);
          const hours = Math.floor(value / 60);
          const remainingMinutes = Math.floor(value % 60);
          const seconds = Math.round((value - Math.floor(value)) * 60);
          var formattedValue =
            hours.toString().padStart(2, "0") +
            ":" +
            remainingMinutes.toString().padStart(2, "0") +
            ":" +
            seconds.toString().padStart(2, "0");

          // Format the result
          // var formattedTime = "";
          // if (hours > 0) {
          //   formattedTime += hours.toString().padStart(2, "0") + ":";
          // }
          // formattedTime += minutes.toString().padStart(2, "0") + ":";
          // formattedTime += remainingSeconds.toString().padStart(2, "0");

          return `${params.seriesName}: ${formattedValue} `;
        },
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: seriesdata["date"],
      },
      color: ["#548237", "#f7c030", "#ffff94", "#bfe0a5", "#ee4932"],
      series: [
        {
          name: "Running",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: "{c} mins",
          },
          // label: {
          //   show: true,
          //   formatter: function (params) {
          //     const milliseconds = typeof params === 'object' ? params.value : params;
          //     const date = new Date(milliseconds);

          //     const hours = date.getUTCHours().toString().padStart(2, '0');
          //     const minutes = date.getUTCMinutes().toString().padStart(2, '0');
          //     const seconds = date.getUTCSeconds().toString().padStart(2, '0');

          //     return `${hours}:${minutes}:${seconds}`;
          //   },
          // },

          emphasis: {
            focus: "series",
          },
          colorBy: "series",
          data: seriesdata["Running"].map((value:any)=>value.toFixed(2)),
        },
        {
          name: "Idle",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: "{c} mins",
          },
          emphasis: {
            focus: "series",
          },
          colorBy: "series",
          data: seriesdata["Idle"].map((value:any)=>value.toFixed(2)),
        },
        {
          name: "DryRun",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: "{c} mins",
          },
          emphasis: {
            focus: "series",
          },
          colorBy: "series",
          data: seriesdata["DryRun"].map((value:any)=>value.toFixed(2)),
        },
        {
          name: "Warning",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: "{c} mins",
          },
          emphasis: {
            focus: "series",
          },
          colorBy: "series",
          data: seriesdata["Warning"].map((value:any)=>value.toFixed(2)),
        },
        {
          name: "Alarm",
          type: "bar",
          stack: "total",
          label: {
            show: true,
            formatter: "{c} mins",
          },
          emphasis: {
            focus: "series",
          },
          colorBy: "series",
          data: seriesdata["Alarm"].map((value:any)=>value.toFixed(2)),
        },
      ],
    };
    console.log(this.options, "oprio");
    // option && this.echartsInstance.setOption(option);
  }
}
