import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
// import * as echarts from "echarts/core";
import * as echarts from "echarts";

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts";
import { CustomChart } from "echarts";
import { CanvasRenderer } from "echarts";

// echarts.use([
//   TitleComponent,
//   TooltipComponent,
//   GridComponent,
//   DataZoomComponent,
//   CustomChart,
//   CanvasRenderer,
// ]);
@Component({
  selector: "app-ganttchart",
  templateUrl: "./ganttchart.component.html",
  styleUrls: ["./ganttchart.component.scss"],
})

/**
 * E-chart component
 */
export class GanttchartComponent implements OnInit, AfterViewInit {
  @Input() gaugeArray: any;
  echartsInstance: any;
  constructor(private elementRef: ElementRef) {}
  data = [
    {
      name: "Breakdown",
      reasion: "noData",
      value: [0, 1707678675827, 1707678676139, 312],
      itemStyle: {
        normal: {
          color: "#bd6d6c",
        },
      },
    },
    {
      name: "idle",
      reasion: "noData",
      value: [0, 1707678676139, 1707678680962, 4821],
      itemStyle: {
        normal: {
          color: "#75d874",
        },
      },
    },
    {
      name: "running",
      reasion: "noData",
      value: [0, 1707678680962, 1707678692459, 9525],
      itemStyle: {
        normal: {
          color: "#bd6d6c",
        },
      },
    },
    {
      name: "idle",
      reasion: "noData",
      value: [0, 1707678692459, 1707678702051, 9267],
      itemStyle: {
        normal: {
          color: "#75d874",
        },
      },
    },
    {
      name: "running",
      reasion: "noData",
      value: [0, 1707678702051, 1707678705901, 2503],
      itemStyle: {
        normal: {
          color: "#75d874",
        },
      },
    },
    {
      name: "breakdown",
      reasion: "Hello",
      value: [0, 1707678705901, 1707678714686, 8505],
      itemStyle: {
        normal: {
          color: "#e0bc78",
        },
      },
    },
    {
      name: "dryrun",
      reasion: "No",
      value: [0, 1707678715678, 1707678716388, 710],
      itemStyle: {
        normal: {
          color: "#7b9ce1",
        },
      },
    },
    {
      name: "running",
      value: [0, 1707678717415, 1707678719726, 2311],
      itemStyle: {
        normal: {
          color: "#75d874",
        },
      },
    },
    {
      name: "idle",
      value: [0, 1707678721553, 1707678730524, 8971],
      itemStyle: {
        normal: {
          color: "#bd6d6c",
        },
      },
    },
    {
      name: "Documents",
      value: [0, 1707678731446, 1707678733039, 1593],
      itemStyle: {
        normal: {
          color: "#bd6d6c",
        },
      },
    },
  ];
  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];
    this.chartData();
  }
  ngAfterViewInit(): void {}
  chartData() {
    this.echartsInstance = echarts.init(
      this.elementRef.nativeElement.querySelector("#main")
    );

    // const categories = ["categoryA"];
    // const types = [
    //   { name: "JS Heap", color: "#7b9ce1" },
    //   { name: "Documents", color: "#bd6d6c" },
    //   { name: "Nodes", color: "#75d874" },
    //   { name: "Listeners", color: "#e0bc78" },
    // ];
    // const dataCount = 10;
    // const startTime = +new Date();

    // // const data = [];

    // // Generate mock data
    // categories.forEach((category, index) => {
    //   let baseTime = startTime;
    //   for (let i = 0; i < dataCount; i++) {
    //     const typeItem = types[Math.round(Math.random() * (types.length - 1))];
    //     const duration = Math.round(Math.random() * 10000);
    //     data.push({
    //       name: typeItem.name,
    //       value: [index, baseTime, (baseTime += duration), duration],
    //       itemStyle: {
    //         normal: {
    //           color: typeItem.color,
    //         },
    //       },
    //     });
    //     baseTime += Math.round(Math.random() * 2000);
    //   }
    //   console.log(data);
    // });

    const renderItem = (params, api) => {
      const categoryIndex = api.value(0);
      const start = api.coord([api.value(1), categoryIndex]);
      const end = api.coord([api.value(2), categoryIndex]);
      const height = api.size([0, 1])[1] * 0.6;
      const rectShape = echarts.graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height,
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
        }
      );
      return (
        rectShape && {
          type: "rect",
          transition: ["shape"],
          shape: rectShape,
          style: api.style(),
        }
      );
    };

    const option = {
      tooltip: {
        formatter: function (params) {
          console.log(params);
          return params.marker + params.name + ": " + params.value[3] + " ms";
        },
      },
      title: {
        text: "Profile",
        left: "center",
        show: false,
      },
      dataZoom: [
        {
          type: "inside",
        },
        {
          type: "slider",
          labelFormatter: function (value, valueStr) {
            const date = new Date(value);
            return date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
          },
        },
        // {
        //   type: "slider",
        //   filterMode: "weakFilter",
        //   showDataShadow: true,
        //   top: 400,
        //   labelFormatter: "",
        // },
        // {
        //   type: "inside",
        //   filterMode: "weakFilter",
        // },
      ],
      grid: {
        height: 300,
        bottom: 90,
        width: "auto",
      },
      xAxis: {
        // min: startTime,
        scale: true,
        silent: false,
        splitLine: {
          show: false,
        },
        splitArea: {
          show: false,
        },
        axisLabel: {
          formatter: function (val) {
            const date = new Date(val);
            return date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
            // return millisecondsToTime(Math.max(0, val - startTime));
          },
        },
      },
      yAxis: {
        show: false,
        // data: categories,
        splitArea: {
          show: false,
        },
      },
      series: [
        {
          type: "custom",
          renderItem: renderItem,
          itemStyle: {
            opacity: 0.8,
          },
          encode: {
            x: [1, 2],
            y: 0,
          },
          data: this.data,
        },
      ],
    };

    option && this.echartsInstance.setOption(option);
    function millisecondsToTime(milliseconds: number): string {
      const totalSeconds = milliseconds / 1000;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }

    function pad(num: number): string {
      return num < 10 ? `0${num}` : num.toString();
    }
  }
}
