import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/shared/Services/data.service";

@Component({
  selector: "app-entries",
  templateUrl: "./entries.component.html",
  styleUrls: ["./entries.component.scss"],
})
export class EntriesComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  deviceName;
  deviceList;
  custId: any;
  maxdate: any;
  mindate: any;
  toDate;
  fromDate: any;
  reasonName = "Downtime";
  entryList: any = ["Downtime", "Rejection"];
  downtimeCount = 25;
  longestDuration = "00:44:25";
  totalDuration = "05:20:00";
  showChart: boolean = false;
  pieChart;
  tableData: any = [];
  showDownTime: boolean = true;
  showRejection: boolean = false;
  configuredReasons: any = [];
  entries: any = [];
  downTimeList: any = ['General - Not Specified'];
  rejectionList: any = [];
  combinedData: any;
  currentPage = 1;
  itemsPerPage = 6;
  pageSize
  searchText

  constructor(private dataservice: DataService) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = user["user"]["custId"];
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    this.maxdate = yesterday.toISOString().split("T")[0];
    this.fromDate = yesterday.toISOString().split("T")[0];
    this.toDate = yesterday.toISOString().split("T")[0];
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Entries" },
      { label: "Downtime/Rejection Entries", active: true },
    ];
    this.getDevices();
    this.initChart();
    this.getTableData();
    this.getreasons();
  }

  getDevices() {
    this.dataservice.get("api/device").subscribe((res) => {
      this.deviceList = res;
      this.deviceName = this.deviceList[0];
      console.log(this.deviceList, this.deviceName["deviceId"]);
      var param = {
        custID: this.custId,
        deviceID: this.deviceName["deviceId"],
        reportDate: this.fromDate,
      };
    });
  }

  getreasons() {
    this.dataservice.get("api/reasons").subscribe(
      (res) => {
        console.log(res, "response");
        if (res) {
          this.configuredReasons = res;
          this.configuredReasons.forEach((val) => {
            if (val.reasonType == "downtime") {
              this.downTimeList.push(val.reason);
            } else {
              this.rejectionList.push(val.reason);
            }
          });
        }
      },
      (error) => {
        console.log(error, "err");
        this.configuredReasons = [];
      }
    );
  }

  ChangeDevice(value) {
    console.log(value, "yuuy");
  }

  changeMin() {
    console.log(this.fromDate);
  }

  toggle() {
    console.log(this.fromDate);
  }

  getColor(state: string): string {
    if (state === "Idle") {
      return "#f7c030";
    } else if (state === "Dryrun") {
      return "#ffff94";
    } else {
      return "#ee4932";
    }
  }

  changeDate() {
    var param = {
      custID: this.deviceName["custId"],
      deviceID: this.deviceName["deviceId"],
      reportDate: this.fromDate,
    };
    console.log(param, "abcd");
  }

  ChangereasonEntry(e) {
    console.log(e, "eee");
    if (e == "Rejection") {
      this.showRejection = true;
      this.showDownTime = false;
    } else {
      this.showDownTime = true;
      this.showRejection = false;
    }
  }

  initChart(): void {
    this.pieChart = {
      title: {
        text: "Top 5 Downtime reasons",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: 10,
        top: 20,
        // data: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5']
      },
      series: [
        {
          name: "Data",
          type: "pie",
          radius: "50%",
          data: [
            { value: 335, name: "Category 1" },
            { value: 310, name: "Category 2" },
            { value: 234, name: "Category 3" },
            { value: 135, name: "Category 4" },
            { value: 1548, name: "Category 5" },
          ],
        },
      ],
    };
  }

  getTableData() {
    this.entries = [];
    for(let i =0; i<this.downtimeCount;i++){
      this.tableData.push({
        state: "Idle",
        time: "2024-03-21 10:46 AM",
        duration: "2 min 40" + i,
        reason: "General-Not Specified",
      })
    }

    for (let i = 0; i <= this.tableData.length; i++) {
      this.entries.push(false);
    }
  }

  editReason(reason, i) {
    this.entries.forEach((value, index) => {
      if (index == i) {
        this.entries[index] = true;
      } else {
        this.entries[index] = false;
      }
    });
    console.log(this.entries, "oio");
  }

  saveReason(reason, i) {
    this.entries[i] = false;
    console.log(reason, this.entries, "oio");
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    console.log(pageNum, this.pageSize, "pageNum");
  }
}
