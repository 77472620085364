import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";

import * as echarts from "echarts";

@Component({
  selector: "app-barchart",
  templateUrl: "./barchart.component.html",
  styleUrls: ["./barchart.component.scss"],
})

/**
 * E-chart component
 */
export class BarchartComponent implements OnInit, OnChanges {
  @Input() gaugeArray: any;
  @Input() seriesdata: any;
  @Input() labels: any;
  constructor(private elementRef: ElementRef) {}
  data = {
    timeData: [
      "15:02:00",
      "15:03:00",
      "15:04:00",
      "15:05:00",
      "15:06:00",
      "15:07:00",
      "15:08:00",
      "15:09:00",
      "15:10:00",
      "15:11:00",
    ],
    // parts: [2,, 2, 2, 2];

    status: [1, 0, 1, 1, 1, 0, 0, 0, 0, 0],
    current: [10, 55, 3, 46, 24, 666, 3, 56, 4, 33],
    parts: [, , [3], , , 666, , 56, 4, ,],
  };
  echartsInstance: any;

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];
    console.log(this.gaugeArray, "combined data");
    console.log(this.seriesdata, "seriesdata", this.labels);
    this.gaugeChart(this.seriesdata, this.labels);
    // this.gaugeChart(this.data);
  }
  ngOnChanges(): void {
    console.log(this.gaugeArray, "resss..............");
    console.log(this.seriesdata, "seriesdata", this.labels);
    this.gaugeChart(this.seriesdata, this.labels);
  }
  /**
   * Fetch the chart data
   */
  options: any;
  // gaugeChart(data) {
  //   console.log(data.time,"timee")
  //   // this.echartsInstance = echarts.init(
  //   //   this.elementRef.nativeElement.querySelector("#combine")
  //   // );
  //   this.options = {
  //     title: {
  //       text: "current Vs status",
  //       left: "center",
  //       show: false,
  //     },
  //     tooltip: {
  //       trigger: "axis",
  //       axisPointer: {
  //         animation: false,
  //       },
  //     },
  //     legend: {
  //       data: ["Status", "Current"],
  //       left: 10,
  //     },
  //     // toolbox: {
  //     //   feature: {
  //     //     dataZoom: {
  //     //       yAxisIndex: "none",
  //     //     },
  //     //     restore: {},
  //     //     saveAsImage: {},
  //     //   },
  //     // },
  //     axisPointer: {
  //       link: [
  //         {
  //           xAxisIndex: "all",
  //         },
  //       ],
  //     },
  //     dataZoom: [
  //       {
  //         show: true,
  //         realtime: true,
  //         start: 70,
  //         end: 100,
  //         xAxisIndex: [0, 1],
  //       },
  //       {
  //         type: "inside",
  //         realtime: true,
  //         start: 70,
  //         end: 100,
  //         xAxisIndex: [0, 1],
  //       },
  //     ],
  //     grid: [
  //       {
  //         left: 60,
  //         right: 50,
  //         height: "25%",
  //       },
  //       {
  //         left: 60,
  //         right: 50,
  //         top: "55%",
  //         height: "35%",
  //       },
  //     ],
  //     xAxis: [
  //       {
  //         type: "category",
  //         boundaryGap: false,
  //         axisLine: { onZero: false },
  //         data: data.time[0],
  //         // data: data.timeData,

  //         show: false,
  //       },
  //       {
  //         gridIndex: 1,
  //         type: "category",
  //         boundaryGap: false,
  //         axisLine: { onZero: true },
  //         data: data.time[0],
  //         // data: data.timeData,
  //       },
  //     ],
  //     yAxis: [
  //       {
  //         name: "Status",
  //         type: "value",
  //         max: 1,
  //         min: -1,
  //         show: false,
  //       },
  //       {
  //         gridIndex: 1,
  //         name: "Current(amps)",
  //         type: "value",
  //         inverse: false,
  //       },
  //     ],
  //     visualMap: {
  //       top: 50,
  //       right: 10,
  //       show:false,
  //       pieces: [
  //         {
  //           gte: 0,
  //           lt: 1,
  //           color: '#f8fb20'
  //         },
  //         {
  //           gte: 1,
  //           lt: 2,
  //           color: '#55FF33'
  //         },
  //       ],
  //       outOfRange: {
  //         color: '#f8fb20'
  //       },
  //       seriesIndex: 0
  //     },
  //     series: [
  //       {
  //         name: "status",
  //         type: "line",
  //         step: "end",
  //         // color: "blue",
  //         // color: data.status[0] === 0 ? "yellow" : "green",
  //         showSymbol: false,
  //         lineStyle:{
  //           width: 2
  //         },
  //         // symbolSize: 8,
  //         // prettier-ignore
  //         // data: data.status[0],
  //         data: data.status[0].map(function (item) {
  //           return item;
  //         }),
  //         markLine: {
  //           silent: true,
  //           lineStyle: {
  //             color: ''
  //           },

  //         }
  //       },
  //       {
  //         name: "",
  //         type: "scatter",

  //         areaStyle: {},
  //         xAxisIndex: 1,
  //         yAxisIndex: 1,
  //         symbolSize: 10,
  //         color: "#f20713",
  //         tooltip: {
  //           show: false,
  //         },
  //         // prettier-ignore
  //         data: data.parts?data.parts[0]:[],
  //         // data: data.parts,
  //       },
  //       {
  //         name: "current",
  //         type: "line",
  //         smooth: true,
  //         style: "area",
  //         showSymbol: false,
  //         areaStyle: {},
  //         xAxisIndex: 1,
  //         yAxisIndex: 1,
  //         symbolSize: 8,
  //         color: "#007d79",
  //         // prettier-ignore
  //         data: data.current[0],
  //         // data: data.current,
  //       },
  //     ],
  //     // option && this.echartsInstance.setOption(option);
  //   };
  //   console.log(this.options,"optionss")
  // }
  gaugeChart(seriesdata, labels) {
    // this.echartsInstance = echarts.init(
    //   this.elementRef.nativeElement.querySelector("#barchart")
    // );
    console.log("timee");
    // this.echartsInstance = echarts.init(
    //   this.elementRef.nativeElement.querySelector("#combine")
    // );
    this.options = {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        type: "scroll",
        // show: true,
        data: ["Custom Legend 1", "Custom Legend 2", "Custom Legend 3"],
        // formatter: function (name) {
        //   return "Legend " + name;
        // },
        // textStyle: {
        //   // Customize text style of legend
        //   color: "#333", // Text color
        //   fontSize: 12, // Text font size
        //   // Add more text styles as needed
        // },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: labels,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      // series: [
      //   {
      //     name: "Direct",
      //     type: "bar",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [320],
      //   },
      //   {
      //     name: "Avail",
      //     type: "bar",
      //     stack: "Ad",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [120],
      //   },
      //   {
      //     name: "Perf",
      //     type: "bar",
      //     stack: "Ad",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [220],
      //   },
      //   {
      //     name: "Quality",
      //     type: "bar",
      //     stack: "Ad",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [150],
      //   },
      //   {
      //     name: "Search Engine",
      //     type: "bar",
      //     data: [862],
      //     emphasis: {
      //       focus: "series",
      //     },
      //     markLine: {
      //       lineStyle: {
      //         type: "dashed",
      //       },
      //       data: [[{ type: "min" }, { type: "max" }]],
      //     },
      //   },
      //   {
      //     name: "Baidu",
      //     type: "bar",
      //     barWidth: 5,
      //     stack: "Search Engine",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [620],
      //   },
      //   {
      //     name: "Google",
      //     type: "bar",
      //     stack: "Search Engine",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [120],
      //   },
      //   {
      //     name: "Bing",
      //     type: "bar",
      //     stack: "Search Engine",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [60],
      //   },
      //   {
      //     name: "Others",
      //     type: "bar",
      //     stack: "Search Engine",
      //     emphasis: {
      //       focus: "series",
      //     },
      //     data: [62],
      //   },
      // ],
      series: seriesdata,
    };
    // option && this.echartsInstance.setOption(option);
  }
}
