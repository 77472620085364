<div class="container-fluid">
  <app-pagetitle [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label>Device</label>
            <select
              class="form-control"
              (ngModelChange)="ChangeDevice($event)"
              name="deviceNameName"
              id="device"
              ngModel
              disableOptionCentering
              [(ngModel)]="deviceName"
            >
              <option *ngFor="let range of deviceList" [ngValue]="range">
                {{ range.deviceName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <label>From Date</label>
          <input
            class="form-control"
            type="date"
            [(ngModel)]="fromDate"
            placeholder="{{ 'From-date' }}"
            id="example-date-input"
            [min]="mindate"
            [max]="maxdate"
            (click)="toggle()"
            (change)="changeMin()"
          />
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label>Viewing</label>
            <select
              class="form-control"
              (ngModelChange)="ChangereasonEntry($event)"
              name="reasonName"
              id="reasonName"
              ngModel
              disableOptionCentering
              [(ngModel)]="reasonName"
            >
              <option *ngFor="let reason of entryList" [ngValue]="reason">
                {{ reason }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <button
            style="width: 100%; margin-top: 1.8rem"
            type="button"
            class="btn btn-success btn-rounded"
            (click)="changeDate()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showDownTime">
    <div class="col-xl-12">
      <div class="card">
        <!-- <span>Downtimes</span> -->
        <div class="row">
          <div class="col-md-4 mt-2">
            <strong><p>Occurances</p></strong>
            <i
              class="fas fa-exclamation-triangle"
              style="font-size: 30px; color: red"
            ></i
            >&nbsp;
            <span style="font-size: 24px"
              ><strong>{{ downtimeCount }}</strong></span
            >
          </div>
          <div class="col-md-4 mt-2">
            <strong><p>Total Duration</p></strong>
            <i class="dripicons-alarm" style="font-size: 30px; color: red"></i
            >&nbsp;
            <span style="font-size: 24px"
              ><strong>{{ totalDuration }}</strong></span
            >
          </div>
          <div class="col-md-4 mt-2">
            <strong><p>Longest Duration</p></strong>
            <i class="dripicons-alarm" style="font-size: 30px; color: red"></i
            >&nbsp;
            <span style="font-size: 24px"
              ><strong>{{ longestDuration }}</strong></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showDownTime">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">Gantt Chart</h4>
            </div>
            <div class="col-md-6 text-md-right">
              <div class="d-inline-block mr-2">
                <p
                  class="badge"
                  style="background-color: #f7c030; color: white"
                >
                  Idle
                </p>
              </div>
              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #ffff94">Dryrun</p>
              </div>
              <div class="d-inline-block mr-2">
                <p
                  class="badge"
                  style="background-color: #548237; color: white"
                >
                  Running
                </p>
              </div>
              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #bfe0a5">warning</p>
              </div>

              <div class="d-inline-block mr-2">
                <p
                  class="badge"
                  style="background-color: #ee4932; color: white"
                >
                  alarm
                </p>
              </div>
            </div>
          </div>
          <app-combinechart
            *ngIf="showChart"
            [gaugeArray]="combinedData"
          ></app-combinechart>
          <div *ngIf="!showChart">
            <img src="assets/images/noData.jpg" width="10%" />
            Please wait we are fetching data / nodata
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showDownTime">
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Pie chart</h4> -->
          <div echarts [options]="pieChart" class="demo-chart"></div>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="card" style="height: 95%">
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            [(ngModel)]="searchText"
          />
        </div>
        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
              <tr>
                <th>State</th>
                <th>Occurance</th>
                <th>Duration</th>
                <th>Reason</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let el of tableData
                    | slice : pageSize
                    | slice : 0 : itemsPerPage
                    | filter : searchText;
                  let i = index
                "
              >
                <th>
                  <span class="space"
                    ><i
                      [style.color]="getColor(el.state)"
                      class="ri-checkbox-blank-circle-line"
                    ></i
                  ></span>
                  {{ el.state }}
                </th>
                <td>{{ el.time }}</td>
                <td>{{ el.duration }}</td>
                <td *ngIf="!entries[i]">{{ el.reason }}</td>
                <td *ngIf="entries[i]">
                  <select
                    class="form-control"
                    (ngModelChange)="ChangeDevice($event)"
                    name="deviceNameName"
                    id="device"
                    ngModel
                    disableOptionCentering
                    [(ngModel)]="deviceName"
                  >
                    <option
                      *ngFor="let reason of downTimeList"
                      [ngValue]="reason"
                    >
                      {{ reason }}
                    </option>
                  </select>
                </td>
                <td *ngIf="!entries[i]">
                  <i
                    class="fas fa-edit crs"
                    title="Edit"
                    (click)="editReason(el, i)"
                  ></i>
                </td>
                <td *ngIf="entries[i]">
                  <i
                    class="fas fa-save crs"
                    title="Save"
                    (click)="saveReason(el, i)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-md-6" *ngIf="tableData?.length > 10">
            <ngb-pagination
              class="pagination-rounded"
              [collectionSize]="tableData?.length"
              #numPages
              [pageSize]="itemsPerPage"
              [(page)]="currentPage"
              [maxSize]="1"
              [rotate]="true"
              [ellipses]="false"
              [boundaryLinks]="true"
              (pageChange)="onPageChange(currentPage)"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
