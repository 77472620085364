import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-scatter-chart",
  templateUrl: "./scatter-chart.component.html",
  styleUrls: ["./scatter-chart.component.scss"],
})
export class ScatterChartComponent implements OnInit {
  @Input() clusterArray: any;
  constructor() {}

  options: any;

  ngOnInit(): void {
    console.log(this.clusterArray);
    this.generateChart(this.clusterArray);
  }
  ngOnChanges(): void {
    console.log(this.clusterArray, "resss..............");
    this.generateChart(this.clusterArray);
  }
  generateChart(data) {
    console.log(data, "dataa");

    const formattedData = data.map((item) => {
      const timeParts = item[0].split(":");
      return [
        new Date(
          1970,
          0,
          1,
          parseInt(timeParts[0]),
          parseInt(timeParts[1]),
          parseInt(timeParts[2])
        ).getTime(),
        item[1],
        item[2],
      ];
    });
    console.log(formattedData, "formattedData");
    const chartOption = {
      // title: {
      //   text: 'Life Expectancy and GDP by Country',
      //   left: '5%',
      //   top: '3%'
      // },
      // grid: {
      //   left: "8%",
      //   top: "10%",
      // },
      xAxis: {
        name: 'Time',
        nameLocation: "middle",
        nameGap: 40,
        nameRotate: 0,
        nameTextStyle: {
          fontSize: 12,
          fontWeight: "bold",
          fontFamily: "sans-serif",
        },
        type: "time",
        axisLabel: {
          formatter: function (value) {
            const date = new Date(value);
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      yAxis: {
        name: "Current (amp)",
        nameLocation: "middle",
        nameTextStyle: {
          fontSize: 12,
          fontWeight: "bold",
          fontFamily: "sans-serif",
        },
        nameGap: 40,
        nameRotate: 90,
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
          },
        },
        scale: true,
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: 0,
        },
      ],
      tooltip: {
        formatter: function (params) {
          const date = new Date(params.value[0]);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          return `Time: ${hours}:${minutes}:${seconds}<br/>Value: ${params.value[1].toFixed(
            2
          )}`;
        },
      },
      series: [
        {
          data: formattedData.map((item) => ({
            value: [item[0], item[1]],
            symbolSize: 8,
            itemStyle: {
              color: item[2],
              // shadowBlur: 10,
              // shadowColor: 'rgba(120, 36, 50, 0.5)',
              // shadowOffsetY: 5,
            },
          })),
          type: "scatter",
          // emphasis: {
          //   focus: 'series',
          //   label: {
          //     show: true,
          //     formatter: function (param) {
          //       return param.data[0];
          //     },
          //     position: 'top'
          //   }
          // },
        },
      ],
    };

    this.options = chartOption;
    console.log(this.options, "optionsss");
  }
}
