import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/Services/data.service";

@Component({
  selector: "app-reasons",
  templateUrl: "./reasons.component.html",
  styleUrls: ["./reasons.component.scss"],
})
export class ReasonsComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;
  configuredReasons: any = [];
  reasonform: FormGroup;
  errmsg: string = "";
  delreasonId = "";
  currentPage = 1;
  itemsPerPage = 5;
  pageSize;
  action: any;
  editReasonId;
  submitted: boolean = false;

  constructor(
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Configuration" },
      { label: "Reasons", active: true },
    ];
    this.getreasons();
  }

  getreasons() {
    this.dataservice.get("api/reasons").subscribe(
      (res) => {
        console.log(res, "response");
        if (res) {
          this.configuredReasons = res;
        }
      },
      (error) => {
        console.log(error, "err");
        this.errmsg = "Server Unreachable";
        this.configuredReasons = [];
        this.toastr.error("Server Unreachable");
      }
    );
  }

  initializeForm() {
    let autoreasonCode;
    console.log(this.configuredReasons.length, "this.configuredReasons");
    if (this.configuredReasons.length == 0) {
      autoreasonCode = "R1";
    } else {
      autoreasonCode = "R" + (this.configuredReasons.length + 1);
    }
    this.reasonform = this.formBuilder.group({
      reasonCode: new FormControl({ value: autoreasonCode, disabled: true }),
      reasonType: ["", [Validators.required]],
      reason: ["", [Validators.required]],
    });
  }

  get form() {
    return this.reasonform.controls;
  }

  openModal(content: any, data: any, act: any) {
    this.action = act;
    this.modalService.open(content, { backdrop: "static", centered: true });
    if (act === "Add") {
      this.initializeForm();
    } else if (act === "Edit") {
      if (data) {
        this.editReasonId = data.id
        console.log(data,"data")
        this.initializeForm();
        this.pathValue(this.reasonform, data);
      }
    }
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      if (key == "reasonCode") {
        controls[key].disable();
      }
      controls[key].patchValue(data[key]);
    }
  }

  cancel() {
    this.modalService.dismissAll();
    this.submitted = false;
    this.action = "";
  }

  saveData() {
    this.submitted = true;
    if (this.reasonform.invalid) {
      return this.reasonform.controls;
    } else {
      this.reasonform.enable();
      if (this.action == "Add") {
        // Add
        this.dataservice
          .post("api/reasons", this.reasonform.value)
          .subscribe((res) => {
            console.log(res, "response");
            if (res) {
              this.cancel();
              this.toastr.success("Reason Added Successfully");
              this.getreasons();
            }
          });
      } else {
        // Update
        this.dataservice
          .put("api/reasons/" + this.editReasonId, this.reasonform.value)
          .subscribe((res) => {
            console.log(res, "response");
            if (res) {
              this.cancel();
              this.toastr.success("Reason Updated Successfully");
              this.getreasons();
            }
          });
      }
    }
  }

  openModalDelete(deletetemp, reason) {
    console.log(reason, "Reason");
    this.delreasonId = reason.id;
    this.modalService.open(deletetemp, { backdrop: "static", centered: true });
  }

  delete(id) {
    console.log(id, "idddd");
    this.dataservice.delete("api/reasons/" + id).subscribe((res) => {
      if (res) {
        this.toastr.success("Reason deleted Successfully");
        console.log(res, "res");
        this.modalService.dismissAll();
        this.getreasons();
      }
    });
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    console.log(pageNum, this.pageSize, "pageNum");
  }
}
