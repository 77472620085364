<div class="container-fluid">
  <!-- start page title -->
  <app-pagetitle
    title="Customer-Info"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>
  <!-- end page title -->

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead
                class="table-light text-black text-black"
                style="color: black !important"
              >
                <tr>
                  <th>Customer Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Verification</th>
                  <th>Info</th>
                  <th>Machine</th>
                  <th>Shift</th>
                </tr>
              </thead>
              <tbody
                *ngFor="
                  let item of custList
                    | slice : pageSize
                    | slice : 0 : itemsPerPage;
                  let i = index
                "
              >
                <tr>
                  <th scope="row">{{ item.name }}</th>
                  <td>{{ item.email }}</td>
                  <td>{{ item.mobileNumber }}</td>
                  <td>
                    <span
                      [ngClass]="{
                        'badge badge-danger': item.isValidated == false,
                        'badge badge-success': item.isValidated == true
                      }"
                      >{{
                        acc(item.isValidated ? item.isValidated : false)
                      }}</span
                    >
                  </td>
                  <td>
                    <i
                      class="ri-information-fill"
                      style="cursor: pointer"
                      [style.color]="
                        item.status['customer'] ? '#1cbb8c' : 'red'
                      "
                      title="Info"
                      (click)="openModal(infotemplate, item)"
                    ></i>
                  </td>
                  <td>
                    <i
                      class="ri-device-fill"
                      style="cursor: pointer"
                      [style.color]="item.status['device'] ? '#1cbb8c' : 'red'"
                      title="Device"
                      (click)="navigate(item, 'device')"
                    ></i>
                  </td>
                  <td>
                    <i
                      class="fas fa-business-time"
                      style="cursor: pointer"
                      [style.color]="item.status['shift'] ? '#1cbb8c' : 'red'"
                      title="Shift"
                      (click)="navigate(item, 'shift')"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="custList?.length > 5">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="custList?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <ng-template #infotemplate let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">Customer Info</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="text-left">
        <form
          (ngSubmit)="saveData()"
          [formGroup]="validationform"
          autocomplete="off"
        >
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Enter name"
              formControlName="name"
              [ngClass]="{ 'is-invalid': submitted && form.name.errors }"
            />
            <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
              <span *ngIf="form.name.errors.required">Name is mandatory.</span>
            </div>
          </div>

          <div class="form-group">
            <label for="email">Email address</label>
            <input
              class="form-control"
              id="email"
              placeholder="Enter email"
              formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && form.email.errors }"
            />
            <div
              *ngIf="submitted && form.email.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.email.errors.required"
                >Email address is mendatory.</span
              >
              <span *ngIf="form.email.errors.pattern"
                >Please enter valid email.</span
              >
            </div>
          </div>
          <!-- <div class="form-group">
            <label>Password </label>
            <div>
              <input type="password" class="form-control" placeholder="Password" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && form.password.errors }" />
              <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
                <span *ngIf="form.password.errors.required">This value is required.</span>
                <span *ngIf="form.password.errors.minlength">Password must be at least 6 characters.</span>
              </div>
            </div>
          </div> -->
          <div class="form-group" [hidden]="true">
            <label>Phone </label>
            <!-- <input type="text" class="form-control" placeholder="Enter phone number" formControlName="mobileNumber"
              [ngClass]="{ 'is-invalid': submitted && form.mobileNumber.errors }"/>
            <div *ngIf="submitted && form.mobileNumber.errors" class="invalid-feedback">
              <span *ngIf="form.mobileNumber.errors.required">Phone is mendatory.</span>
              <span *ngIf="form.mobileNumber.errors.minlength">Phone number must be 10 characters.</span>
              <span *ngIf="form.mobileNumber.errors.maxlength">Phone number must be 10 characters.</span>
            </div> -->
            <ngx-intl-tel-input
              [ngClass]="{
                'is-invalid': submitted && form.mobileNumber.errors
              }"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.India"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              name="mobile"
              formControlName="mobileNumber"
            >
            </ngx-intl-tel-input>
          </div>
          <label>Phone </label>
          <div class="form-group auth-form-group-custom">
            <div class="input-group">
              <ngx-intl-tel-input
                [ngClass]="{
                  'is-invalid':
                    submitted && form.mobileNumber.errors?.invalidPhoneNumber
                }"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="mobileNumber"
                formControlName="mobileNumber"
              >
              </ngx-intl-tel-input>
            </div>
            <div
              *ngIf="submitted && form.mobileNumber.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.mobileNumber.errors.required">
                MobileNumber is required
              </div>
              <div
                *ngIf="form.mobileNumber.errors.invalidPhoneNumber"
                class="invalid-feedback"
              >
                Invalid phone number
              </div>
            </div>
          </div>
          <label>Alternate Mobile Number </label>
          <div class="form-group auth-form-group-custom">
            <div class="input-group">
              <ngx-intl-tel-input
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    form.alernateMobileNumber.errors?.invalidPhoneNumber
                }"
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="alernateMobileNumber"
                formControlName="alernateMobileNumber"
              >
              </ngx-intl-tel-input>
            </div>
            <div
              *ngIf="submitted && form.alernateMobileNumber.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.alernateMobileNumber.errors.required">
                alernateMobileNumber is required
              </div>
              <div
                *ngIf="form.alernateMobileNumber.errors.invalidPhoneNumber"
                class="invalid-feedback"
              >
                Invalid phone number
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="name">Plant Name</label>
            <input
              type="text"
              class="form-control"
              id="plant"
              placeholder="Enter plant name"
              formControlName="plant"
              [ngClass]="{ 'is-invalid': submitted && form.plant.errors }"
            />
            <div
              *ngIf="submitted && form.plant.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.plant.errors.required"
                >Plant Name is mandatory.</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="name">Number of Machines</label>
            <input
              type="number"
              class="form-control"
              id="noOfMachines"
              placeholder="Enter No.of Machines"
              formControlName="noOfMachines"
              [ngClass]="{
                'is-invalid': submitted && form.noOfMachines.errors
              }"
            />
            <div
              *ngIf="submitted && form.noOfMachines.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.noOfMachines.errors.required"
                >No.of Machines is mandatory .</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="timeZone">Timezone</label>
            <select
              class="form-control"
              name="timeZone"
              formControlName="timeZone"
              [ngClass]="{ 'is-invalid': submitted && form.timeZone.errors }"
            >
              <option value="" disabled selected>Select TimeZone</option>
              <option *ngFor="let tz of timeZones" [value]="tz">
                {{ tz }}
              </option>
            </select>

            <div
              *ngIf="submitted && form.timeZone.errors"
              class="invalid-feedback"
            >
              <span *ngIf="form.timeZone.errors.required"
                >Timezone is required.</span
              >
            </div>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-success">Update</button>
            <button
              type="button"
              class="btn btn-danger ml-1"
              (click)="cancel()"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!-- container-fluid -->
