<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img
              src="assets/wimera/favicon/Favicon_Green.png"
              alt=""
              height="32"
            />
          </span>
          <span class="logo-lg">
            <img src="assets/wimera/Wimera_Logo_Green.png" alt="" height="50" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-24 header-item waves-effect"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'HEADER.SEARCH' | translate }}"
          />
          <span class="ri-search-line"></span>
        </div>
      </form> -->

      <!-- <div class="dropdown dropdown-mega d-none d-lg-block ml-2" ngbDropdown>
        <button
          ngbDropdownToggle
          type="button"
          class="btn header-item waves-effect"
          data-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
        >
          {{ "HEADER.MEGA_MENU" | translate }}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.UI.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.LIGHTBOX" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RANGE_SLIDER" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.SWEET_ALERT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RATING" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.FORMS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.TABLES" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.CHARTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.APPLICATIONS.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.ECOMMERCE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.CALENDAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.EMAIL" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.PROJECTS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.TASKS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.CONTACTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.EXTRA_PAGES.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.LIGHT_SIDEBAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.COMPACT_SIDEBAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.MAINTENANCE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.COMING SOON" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.TIMELINE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.FAQ" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.UI.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.LIGHTBOX" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RANGE_SLIDER" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.SWEET_ALERT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RATING" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.FORMS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.TABLES" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.CHARTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img
                      src="assets/images/megamenu-img.png"
                      alt=""
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="d-flex">
      <div
        class="d-sm-inline-block d-lg-none d-md-none d-xl-none"
        style="margin: auto"
      >
        <img src="assets/wimera/favicon/Favicon_Green.png" alt="" height="32" />
      </div>
      <div class="d-inline-block" style="margin: auto">
        <!-- <span class="align-middle"
          >{{ convertTimestampToTime(shift_Time.start) }} to
          {{ convertTimestampToTime(shift_Time.end) }}
        </span> -->
      </div>

      <!-- <div class="dropdown d-none d-lg-inline-block ml-1">
        <button
          type="button"
          class="btn header-item noti-icon waves-effect"
          (click)="fullscreen()"
        >
          <i class="ri-fullscreen-line"></i>
        </button>
      </div> -->

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          ngbDropdownToggle
          class="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="ri-notification-3-line"></i>
          <span class="noti-dot"></span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{
                  "HEADER.NOTIFICATIONS.VIEW_ALL" | translate
                }}</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar
            style="position: relative; height: 230px"
            [config]="configData"
          >
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="ri-shopping-cart-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ "HEADER.NOTIFICATIONS.FIRST.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FIRST.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ "HEADER.NOTIFICATIONS.FIRST.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img
                  src="assets/images/users/avatar-3.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ "HEADER.NOTIFICATIONS.SECOND.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.SECOND.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.SECOND.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ "HEADER.NOTIFICATIONS.THIRD.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.THIRD.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.THIRD.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img
                  src="assets/images/users/avatar-4.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ "HEADER.NOTIFICATIONS.FOUR.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FOUR.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.FOUR.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-link font-size-14 btn-block text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle mr-1"></i>
              {{ "HEADER.NOTIFICATIONS.LOAD_MORE" | translate }}
            </a>
          </div>
        </div>
      </div> -->
      <div>
      <div class="avatar">
        <span class="avatar-content">{{ custName["name"] ? custName["name"][0].toUpperCase() : '' }}</span>
      </div>
      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button
          type="button"
          ngbDropdownToggle
          class="btn header-item waves-effect"
          id="page-header-user-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <!-- <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/Nagarajan.png"
            alt="Header Avatar"
          /> -->
          <span class="d-none d-xl-inline-block ml-1">{{
            custName["name"]
          }}</span>
          <!-- <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> -->
        </button>

        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <!-- <a class="dropdown-item" href="javascript: void(0);"
            ><i class="ri-user-line align-middle mr-1"></i>
            {{ "HEADER.LOGIN.PROFILE" | translate }}</a
          >
          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="ri-wallet-2-line align-middle mr-1"></i
            >{{ "HEADER.LOGIN.MY_WALLET" | translate }}</a
          >
          <a class="dropdown-item d-block" href="javascript: void(0);"
            ><span class="badge badge-success float-right mt-1">11</span
            ><i class="ri-settings-2-line align-middle mr-1"></i
            >{{ "HEADER.LOGIN.SETTINGS" | translate }}
          </a>
          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="ri-lock-unlock-line align-middle mr-1"></i>
            {{ "HEADER.LOGIN.LOCK_SCREEN" | translate }}</a
          > -->
          <div class="dropdown-divider"></div>
          <a *ngIf="role === 'CUSTOMER' && existingdataflag == false"
            class="dropdown-item text-primary"
            href="javascript: void(0);"
            (click)="openModal(content, '', 'Add')"
            ><i class="ri-settings-3-line align-middle mr-1 text-primary"></i>
            SETTINGS</a
          >
          <a *ngIf="role === 'CUSTOMER' && existingdataflag == true"
            class="dropdown-item text-primary"
            href="javascript: void(0);"
            (click)="openModal(content, existingdata, 'Edit')"
            ><i class="ri-settings-3-line align-middle mr-1 text-primary"></i>
            SETTINGS</a
          >
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            LOGOUT</a
          >
        </div>
      </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="ri-settings-2-line"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>

<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ action }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="validationform">
        <div class="form-group">
          <label for="name">Deductible Idle Time(Min)</label>
          <input
            type="number"
            class="form-control"
            id="idleTime"
            placeholder="Enter Idle Time"
            formControlName="idleTime"
            [ngClass]="{ 'is-invalid': submitted && form.idleTime.errors }"
          />
          <div
            *ngIf="submitted && form.idleTime.errors"
            class="invalid-feedback"
          >
            <span *ngIf="form.idleTime.errors.required"
              >Idle Time is mendatory.</span
            >
          </div>
        </div>

        <div class="form-group">
          <label for="name">Production Cost (Hourly)</label>
          <input
            type="number"
            class="form-control"
            id="productionCost"
            placeholder="Enter Production Cost"
            formControlName="productionCost"
            [ngClass]="{
              'is-invalid': submitted && form.productionCost.errors
            }"
          />
          <div
            *ngIf="submitted && form.productionCost.errors"
            class="invalid-feedback"
          >
            <span *ngIf="form.productionCost.errors.required"
              >Production Cost is mendatory.</span
            >
          </div>
        </div>

        <div class="form-group">
          <label for="name">Power Unit Cost</label>
          <input
            type="number"
            class="form-control"
            id="powerUnitCost"
            placeholder="Enter Power Unit Cost"
            formControlName="powerUnitCost"
            [ngClass]="{ 'is-invalid': submitted && form.powerUnitCost.errors }"
          />
          <div
            *ngIf="submitted && form.powerUnitCost.errors"
            class="invalid-feedback"
          >
            <span *ngIf="form.powerUnitCost.errors.required"
              >Power Unit Cost is mendatory.</span
            >
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">Save</button>
          <button
            type="button"
            class="btn btn-danger ml-1"
            (click)="modal('close click')"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
