import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  url = environment.url;
  firmwareUrl = environment.firmwareUrl;
  private apiUrl = 'https://k2-clustering-partcount.azurewebsites.net/api/partcount';
  private apiKey = 'bxdtvcycVRS2vEuyIDsN1HIHbkeoasCGPChgKYm7Aw-TAzFuXBIzfA==';
  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // Add any other necessary headers here
  });
  get(params: any) {
    return this.http.get(this.url + params);
  }

  post(params: any, data: any) {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Referrer-Policy': 'strict-origin-when-cross-origin',
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS', // Specify the allowed HTTP methods
    //   'Access-Control-Allow-Headers':
    //     'Origin, X-Requested-With, Content-Type, Accept, Authorization', // Specify the allowed headers
    //   // Add any other necessary headers here
    // });
    return this.http.post(this.url + params, data);
  }
  put(params: any, data: any) {
    return this.http.put(this.url + params, data);
  }
  delete(params: any) {
    return this.http.delete(this.url + params);
  }

  upload(data: any) {
    return this.http.put(this.url + "upload", data);
  }
  certCreate(params: any, data: any) {
    return this.http.post(this.url + params, data);
  }
  getFirmware(params: any) {
    return this.http.get(this.firmwareUrl + params);
  }
  postFirmware(params: any, data: any) {
    return this.http.post(this.firmwareUrl + params, data);
  }
  deleteFirmware(params: any) {
    return this.http.delete(this.firmwareUrl + params);
  }
  publishDataFirmware(params: any) {
    return this.http.post(this.firmwareUrl + params, {});
  }
  partCount(param):Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-functions-key': this.apiKey,
    });
    return this.http.post(this.apiUrl, param, { headers });
  }
}
