import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/Services/data.service";

@Component({
  selector: "app-shift_info",
  templateUrl: "./shift_info.component.html",
  styleUrls: ["./shift_info.component.scss"],
})
export class ShiftInfoComponent implements OnInit {
  // bread crumb items
  submit: boolean = false;
  showDowntime: boolean = false;
  custId: any = "";
  loginRole: any = "";
  shiftsArr: any = [];
  planneddowntime: FormArray;
  validationform: FormGroup;
  selectValue: any;
  plannedInterval = [];
  disabledTime = ["Select Time"];
  selectedOptionIndex: number = -1;
  selectedShiftOptionIndex: number = -1;
  shiftInterval = [];
  errmsg: String;
  isAdd: boolean = false;
  is24hr: boolean = false;
  showadd: boolean = true;
  starttime;
  endtime;
  isEdit: boolean = false;
  id: "";
  delShiftId;

  breadCrumbItems: Array<{}>;

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private toastr: ToastrService
  ) {
    let userDetails = JSON.parse(sessionStorage.getItem("currentUser"));
    let user = userDetails["user"];
    this.loginRole = user["role"];
  }

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Configuration" },
      { label: "Shift", active: true },
    ];
    bsCustomFileInput.init();
    this.custId = this.route.snapshot.paramMap.get("id");
    console.log(this.custId);
    if (this.custId) {
      this.getCustShiftDetails(this.custId);
    } else {
      this.getshiftDetails();
    }
  }

  getCustShiftDetails(custId) {
    console.log(custId, "custId");
    this.dataservice.get(`api/shift?custId=${custId}`).subscribe((res: any) => {
      console.log(res.length, "res");
      if (res.length) {
        this.shiftsArr = res;
        if (this.shiftsArr.length != 0) {
          let lastShift = this.shiftsArr[this.shiftsArr.length - 1];
          let endTime = moment(lastShift.endTime, "HH:mm:ss").add(1, "minutes");
          console.log(endTime, "end");
          let firstShift = this.shiftsArr[0];
          this.shiftsArr.forEach((val) => {
            if (val.isLastShift) {
              this.is24hr = true;
            } else {
              this.is24hr = false;
            }
          });
          this.selectValue = this.getTimeIntervals(
            moment(endTime, "HH:mm:ss").format('HH"mm'),
            moment(firstShift.startTime, "HH:mm:ss").format("HH:mm")
          );
        } else {
          this.is24hr = false;
          this.selectValue = this.getTimeIntervals("00:00", "23:59");
          this.errmsg = "Please configure shifts";
        }
      } else {
        this.shiftsArr = [];
        this.is24hr = false;
        this.selectValue = this.getTimeIntervals("00:00", "23:59");
        this.errmsg = "Please configure shifts";
      }
      (error) => {
        console.log(error, "err");
        this.selectValue = this.getTimeIntervals("00:00", "23:59");
        this.errmsg = "Please configure shifts";
      };
    });
  }

  getshiftDetails() {
    this.shiftsArr = [];
    this.dataservice.get(`api/shift`).subscribe((res) => {
      console.log(res, "res");
      if (res) {
        this.shiftsArr = res;
      }
    });
  }

  addshift() {
    this.initializeForm();
    this.isAdd = true;
  }

  initializeForm() {
    console.log(this.shiftsArr.length);
    let usershiftId;
    if (this.shiftsArr.length > 0) {
      usershiftId = "SH" + (this.shiftsArr.length + 1);
    } else {
      usershiftId = "SH1";
    }
    console.log(usershiftId);
    this.validationform = this.formBuilder.group({
      usershiftId: [
        usershiftId,
        [Validators.required, Validators.pattern("[a-zA-Z0-9]+")],
      ],
      shiftName: ["", [Validators.required]],
      startTime: ["", [Validators.required]],
      endTime: ["", [Validators.required]],
      checkplanned: [false, [Validators.required]],
      planneddowntime: this.formBuilder.array([]),
    });
  }

  createItem() {
    return this.formBuilder.group({
      downtimename: ["", Validators.required],
      duration: ["", Validators.required],
      plannedstarttime: ["", Validators.required],
      plannedendtime: ["", Validators.required],
    });
  }

  downtimeAdd(e) {
    if (e.target.checked) {
      this.showadd = true;
      if (this.isEdit) {
        // Do Nothing
      } else {
        this.starttime = this.validationform.value["startTime"];
        this.starttime = moment(this.starttime, "HH:mm:ss").format("HH:mm");
        this.endtime = this.validationform.value["endTime"];
        this.plannedInterval = this.getTimeIntervals(
          this.starttime,
          this.endtime
        );
        this.shiftInterval = this.getTimeIntervals("00:00", "23:59");
      }

      console.log(
        this.starttime,
        this.endtime,
        this.plannedInterval.length,
        this.shiftInterval.length,
        "start"
      );
      this.validationform.get("startTime").disable();
      this.validationform.get("endTime").disable();
    } else {
      if (this.isEdit) {
        this.validationform.get("startTime").disable();
        this.validationform.get("endTime").disable();
      } else {
        this.validationform.get("startTime").enable();
        this.validationform.get("endTime").enable();
      }

      this.plannedInterval = [];
      const plannedarray = this.validationform.get(
        "planneddowntime"
      ) as FormArray;
      while (plannedarray.length !== 0) {
        plannedarray.removeAt(0);
      }

      this.showadd = true;
    }
  }

  get form() {
    return this.validationform.controls;
  }

  save() {
    this.submit = true;
    this.validationform.enable();
    console.log(this.validationform.value, this.shiftsArr, "GOPS");
    if (this.validationform.invalid) {
      return this.validationform.controls;
    } else {
      if (!this.isEdit) {
        if (this.loginRole == "SUPERADMIN") {
          this.dataservice
            .post(`api/shift?custId=${this.custId}`, this.validationform.value)
            .subscribe(
              (res) => {
                console.log(res, "response");
                if (res) {
                  this.toastr.success("Shift Added Successfully");
                  this.cancel();
                }
              },
              (error) => {
                console.log(error, "err");
                if (
                  error ==
                  "Planned Down time is not within shift time duration.Please configure within the shift duration."
                ) {
                  this.toastr.error("Please check the planned downtime");
                  this.validationform.get("startTime").disable();
                  this.validationform.get("endTime").disable();
                  this.disablePreviousRows();
                } else {
                  this.toastr.success("Server Unreachable");
                }
              }
            );
        } else {
          // Do Nothing
        }
      } else {
        let params = {
          id: this.id,
          custId: this.custId,
        };
        if (this.loginRole == "SUPERADMIN") {
          this.dataservice
            .put(
              `api/shift/` + params["id"] + "/" + params["custId"],
              this.validationform.value
            )
            .subscribe(
              (res) => {
                console.log(res, "response");
                if (res) {
                  this.toastr.success("Shift Details updated Successfully");
                  this.cancel();
                }
              },
              (error) => {
                console.log(error, "err");
                if (
                  error ==
                  "Planned Down time is not within shift time duration.Please configure within the shift duration."
                ) {
                  this.toastr.error(error);
                  this.validationform.get("startTime").disable();
                  this.validationform.get("endTime").disable();
                  this.disablePreviousRows();
                } else {
                  this.toastr.success("Server Unreachable");
                }
              }
            );
        } else {
          this.dataservice
            .put(
              `api/shift/` + params["id"] + "/" + params["custId"],
              this.validationform.value
            )
            .subscribe(
              (res) => {
                console.log(res, "response");
                if (res) {
                  this.toastr.success("Shift Details updated Successfully");
                  this.cancel();
                }
              },
              (error) => {
                console.log(error, "err");
                if (
                  error ==
                  "Planned Down time is not within shift time duration.Please configure within the shift duration."
                ) {
                  this.toastr.error(error);
                  this.validationform.get("startTime").disable();
                  this.validationform.get("endTime").disable();
                  this.disablePreviousRows();
                } else {
                  this.toastr.success("Server Unreachable");
                }
              }
            );
        }
      }
    }
  }

  plannedendTime(e) {
    let shiftEndtime;
    let lastIndexEnd;
    console.log(
      e,
      this.endtime,
      moment(this.endtime, "HH:mm").add(1, "minute").format("HH:mm"),
      "eeeeeeeeeeee"
    );
    let planneddowntime = this.validationform.value["planneddowntime"];
    if (planneddowntime.length) {
      if (this.isEdit) {
        shiftEndtime = moment(this.endtime, "HH:mm").format("HH:mm");
      } else {
        shiftEndtime = this.endtime;
      }
      let lastIndex = planneddowntime.length - 1;
      let lastIndexDuration = planneddowntime[lastIndex].duration;
      let lastIndexStart = planneddowntime[lastIndex].plannedstarttime;
      console.log(lastIndexDuration, planneddowntime, "last");
      if (lastIndexDuration && lastIndexStart) {
        lastIndexEnd = this.addHHMM(lastIndexDuration, lastIndexStart);
        console.log(lastIndexEnd, lastIndexStart, shiftEndtime, "ghgh");
        const analogArray = this.validationform.get(
          "planneddowntime"
        ) as FormArray;
        const lastItem = analogArray.at(analogArray.length - 1);
        lastItem.get("plannedendtime").setValue(lastIndexEnd);
        if (lastIndexEnd == shiftEndtime) {
          this.showadd = false;
        } else {
          this.showadd = true;
        }
      }
    }
  }

  getTimeIntervals(start, end) {
    let startTime = moment(start, "HH:mm");
    let endTime = moment(end, "HH:mm");
    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }
    let timeStops = [];
    while (startTime <= endTime) {
      timeStops.push(moment(startTime).format("HH:mm"));
      startTime.add(1, "minutes");
    }
    return timeStops;
  }

  addItem(): void {
    this.planneddowntime = this.validationform.get(
      "planneddowntime"
    ) as FormArray;
    this.planneddowntime.push(this.createItem());
    this.disablePreviousRows();
  }

  getInterval() {
    console.log(this.validationform.value, "jkj");
    let shiftStarttime = this.validationform.value["startTime"];
    // shiftStarttime = moment(shiftStarttime).format("HH:mm")
    let shiftEndtime = this.validationform.value["endTime"];
    let planneddowntime = this.validationform.value["planneddowntime"];
    if (planneddowntime.length) {
      let lastIndex = planneddowntime.length - 1;
      let lastIndexDuration = planneddowntime[lastIndex].duration;
      console.log(lastIndexDuration, planneddowntime, "last");
      let lastIndexStart = planneddowntime[lastIndex].plannedstarttime;
      let lastIndexEnd = this.addHHMM(lastIndexDuration, lastIndexStart);
      console.log(planneddowntime, "planned", lastIndexEnd);
      let updateEndTime =
        this.validationform.value["planneddowntime"][lastIndex];
      this.validationform.value["planneddowntime"][lastIndex][
        "plannedendtime"
      ] = lastIndexEnd;
      let indexofplannedtime = this.plannedInterval.indexOf(lastIndexEnd);
      this.selectedOptionIndex = indexofplannedtime;
      console.log(updateEndTime, "last");
    } else {
      // this.plannedInterval = this.getTimeIntervals(shiftStarttime, shiftEndtime)
      this.selectedOptionIndex = -1;
      console.log(this.plannedInterval, "last");
    }
  }

  isOptionDisabled(index: number): boolean {
    return index <= this.selectedOptionIndex;
  }

  isShiftOptionDisabled(index: number): boolean {
    return index <= this.selectedShiftOptionIndex;
  }

  removeRow(index) {
    let lastIndex;
    (<FormArray>this.validationform.get("planneddowntime")).removeAt(index);
    const plannedArray = this.validationform.get(
      "planneddowntime"
    ) as FormArray;

    console.log(plannedArray["value"].length, plannedArray, "plannedArray");
    let lastIndexObject =
      plannedArray["value"][plannedArray["value"].length - 1];
    if (lastIndexObject?.plannedendtime != this.endtime) {
      this.showadd = true;
    }
    console.log(lastIndexObject, "uygfdfghjhgf");
    if (plannedArray["value"].length == 0) {
      this.selectedOptionIndex = -1;
    } else if (plannedArray["value"].length == 1) {
      lastIndex = 1;
      let indexofplannedtime = this.plannedInterval.indexOf(this.starttime);
      this.selectedOptionIndex = indexofplannedtime;
    } else {
      lastIndex = plannedArray["value"].length - 1;
      let previousObject = plannedArray["value"][lastIndex - 1];
      console.log(previousObject, "previousObjectpreviousObject");

      let indexofplannedtime = this.plannedInterval.indexOf(
        previousObject.plannedendtime
      );
      this.selectedOptionIndex = indexofplannedtime;
    }
    this.disablePreviousRows();
  }

  cancel() {
    this.isAdd = false;
    this.isEdit = false;
    this.submit = false;
    this.validationform.reset();
    if (this.loginRole == "SUPERADMIN") {
      this.getCustShiftDetails(this.custId);
    } else {
      this.getshiftDetails();
    }
  }

  addHHMM(time1: string, time2: string): string {
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);
    let totalMinutes = (hours1 + hours2) * 60 + minutes1 + minutes2;
    totalMinutes = totalMinutes % (24 * 60);
    if (totalMinutes < 0) {
      totalMinutes += 24 * 60;
    }
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const result = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return result;
  }

  disablePreviousRows(): void {
    const rows = this.validationform.get("planneddowntime") as FormArray;
    const lastRowIndex = rows.length - 1;
    rows.controls.forEach((row, index) => {
      const control = row;
      console.log(control, "hgfghjh");
      if (control) {
        if (index < lastRowIndex) {
          control.get("duration").disable();
          control.get("plannedstarttime").disable();
        } else {
          control.enable();
        }
      }
    });
  }

  disableAllRows(): void {
    const rows = this.validationform.get("planneddowntime") as FormArray;
    const lastRowIndex = rows.length - 1;
    rows.controls.forEach((row, index) => {
      const control = row;
      console.log(control, "hgfghjh");
      if (control) {
        if (index <= lastRowIndex) {
          control.get("duration").disable();
          control.get("plannedstarttime").disable();
        } else {
          control.enable();
        }
      }
    });
  }

  enableAllControls(): void {
    const rows = this.validationform.get("planneddowntime") as FormArray;
    rows.controls.forEach((row) => {
      row.enable();
    });
  }

  edit(data) {
    this.plannedInterval = [];
    let lastIndex;
    console.log(data, "editt");
    this.initializeForm();
    this.selectValue = this.getTimeIntervals("00:00", "23:59");
    this.isEdit = true;
    if (data) {
      const { usershiftId, shiftName, startTime, endTime, checkplanned } = data;

      this.endtime = moment(endTime, "HH:mm:ss")
        .add(1, "seconds")
        .format("HH:mm");
      this.starttime = moment(startTime, "HH:mm:ss").format("HH:mm");
      console.log(this.endtime, this.selectedShiftOptionIndex, "end");
      this.shiftInterval = this.getTimeIntervals("00:00", "23:59");
      this.plannedInterval = this.getTimeIntervals(
        moment(startTime, "HH:mm:ss").format("HH:mm"),
        moment(endTime, "HH:mm:ss").add(1, "seconds").format("HH:mm")
      );
      console.log(this.plannedInterval, "planned");
      this.validationform.patchValue({
        usershiftId: usershiftId || "",
        shiftName: shiftName || "",
        startTime: startTime
          ? moment(startTime, "HH:mm:ss").format("HH:mm")
          : "" || "",
        endTime: endTime
          ? moment(endTime, "HH:mm:ss").format("HH:mm")
          : "" || "",
        checkplanned: checkplanned || false,
      });
      this.id = data.id;
      if (
        data["planneddowntime"]?.length != 0 &&
        data["planneddowntime"] != undefined
      ) {
        let shiftendTime;
        if (this.isEdit) {
          shiftendTime = moment(data.endTime, "HH:mm:ss")
            .add(1, "seconds")
            .format("HH:mm");
        }
        // else {
        //   shiftendTime = moment(data.endTime, "HH:mm:ss")
        //     .add(1, "seconds")
        //     .format("HH:mm");
        // }
        let plannedDowntime = data["planneddowntime"];

        let lastPlanned = plannedDowntime[plannedDowntime?.length - 1];
        let lastplannedEndTime = moment(lastPlanned.plannedendtime, "HH:mm:ss")
          .add(1, "seconds")
          .format("HH:mm");
        if (data["planneddowntime"]?.length == 1) {
          lastIndex = 1;
          let indexofplannedtime = this.plannedInterval.indexOf(
            moment(startTime, "HH:mm:ss").format("HH:mm")
          );
          this.selectedOptionIndex = indexofplannedtime;
        } else {
          lastIndex = plannedDowntime?.length - 1;
          let previousObject = plannedDowntime[lastIndex - 1];
          let indexofplannedtime = this.plannedInterval.indexOf(
            moment(previousObject.plannedendtime, "HH:mm:ss")
              .add(1, "seconds")
              .format("HH:mm")
          );
          this.selectedOptionIndex = indexofplannedtime;
        }

        console.log(
          lastPlanned.plannedendtime,
          shiftendTime,
          this.selectedOptionIndex,
          "last"
        );
        if (lastplannedEndTime == shiftendTime) {
          this.showadd = false;
        } else {
          this.showadd = true;
        }
      } else {
        this.showadd = true;
      }

      if (this.loginRole == "SUPERADMIN") {
        let planneddowntime = data?.planneddowntime;
        const plannedArray = this.validationform.get(
          "planneddowntime"
        ) as FormArray;
        planneddowntime?.forEach((item) => {
          plannedArray.push(
            this.formBuilder.group({
              downtimename: [item.downtimename, Validators.required],
              duration: [
                moment(item.duration, "HH:mm:ss")
                  .add(1, "seconds")
                  .format("HH:mm"),
                Validators.required,
              ],
              plannedstarttime: [
                moment(item.plannedstarttime, "HH:mm:ss").format("HH:mm"),
                Validators.required,
              ],
              plannedendtime: [
                moment(item.plannedendtime, "HH:mm:ss")
                  .add(1, "seconds")
                  .format("HH:mm"),
                Validators.required,
              ],
            })
          );
        });
      } else {
        let planneddowntime = data?.planneddowntime;
        const plannedArray = this.validationform.get(
          "planneddowntime"
        ) as FormArray;
        planneddowntime?.forEach((item) => {
          plannedArray.push(
            this.formBuilder.group({
              downtimename: [item.downtimename, Validators.required],
              duration: new FormControl({
                value: moment(item.duration, "HH:mm:ss")
                  .add(1, "seconds")
                  .format("HH:mm"),
                disabled: true,
              }),
              plannedstarttime: new FormControl({
                value: moment(item.plannedstarttime, "HH:mm:ss").format(
                  "HH:mm"
                ),
                disabled: true,
              }),
              plannedendtime: new FormControl({
                value: moment(item.plannedendtime, "HH:mm:ss")
                  .add(1, "seconds")
                  .format("HH:mm"),
                disabled: true,
              }),
            })
          );
        });
      }

      if (this.loginRole == "CUSTOMER") {
        this.custId = data.custId;
        // this.showadd = false
        // this.validationform.get('checkplanned').disable()
        // this.disableAllRows()
        this.disablePreviousRows();
      } else {
        this.disablePreviousRows();
      }
      console.log(this.validationform.value);
      this.validationform.get("startTime").disable();
      this.validationform.get("endTime").disable();
      this.validationform.get("usershiftId").disable();
      this.isAdd = true;
    }
  }

  openModal(deletetemp: any, delId: any) {
    console.log(delId, "delId");
    this.modalService.open(deletetemp, { centered: true });
    this.delShiftId = delId;
  }

  delete(id) {
    console.log(id, "idddd");
    if (this.loginRole == "SUPERADMIN") {
      this.dataservice.delete("api/shift/" + id).subscribe(
        (res) => {
          console.log(res, "res");
          if (res) {
            this.modalService.dismissAll();
            this.toastr.error("Shift Deleted");
            this.getCustShiftDetails(this.custId);
          }
        },
        (err) => {
          console.log(err, "err");
          this.toastr.error("Server Unreachable");
        }
      );
    }
  }
}
