import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/auth.models";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthfakeauthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  url = environment.url;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    console.log(email, password);
    return this.http
      .post<any>(this.url + `auth/signin`, { email, password })
      .pipe(
        map((user) => {
          console.log(user, "auth");
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }
  register(user: User) {
    const apiUrl ="https://k2prod.azurewebsites.net";
    const apiKey = "r4ksPGDe9yntY6dGOH7o91D2xKtsLWJ7j3mU4S6a";
        const headers = new HttpHeaders().set("x-api-key", apiKey);
       return this.http.post<any>(`${apiUrl}/auth/signup`,  user).pipe( map((user)=>{
return user;
       }) 
      )
          //             headers,
          //           })
          //           .subscribe(
          //             (result: any) => {
          //               this.toastr.success("ThankYou for contacting us");
          //               this.router.navigate(["/"]);
          
          //               console.log(result);
          //             },
          //             (error: any) => {
          //               console.error(error);
          //             }
          //           );
    // return this.http.post<any>(this.url + `users/register`, user).pipe(
      // map((user) => {
        // console.log(user, "auth");
        // login successful if there's a jwt token in the response
        // if (user && user.token) {
        //   // store user details and jwt token in local storage to keep user logged in between page refreshes
        //   sessionStorage.setItem("currentUser", JSON.stringify(user));
        //   this.currentUserSubject.next(user);
        // }
        // return user;
      // })
    // );
  }
  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
}
