import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-recordsample",
  templateUrl: "./recordsample.component.html",
  styleUrls: ["./recordsample.component.scss"],
})
export class RecordSampleComponent implements OnInit, OnDestroy {
  constructor(
    private dataservice: DataService,
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = user["user"]["custId"];
  }
  private destroy$: Subject<void> = new Subject<void>();
  private deactivate: boolean = true;
  addRecord: boolean = true;
  custId: any;
  errmsg: String = "";
  currentTime: Date;
  timer: any;
  duration;
  breadCrumbItems: Array<{}>;
  deviceList:any;
  currentPage = 1;
  itemsPerPage = 3;
  pageSize:any;
  sampleData: any = [];
  submitted: boolean = false;
  validationform: FormGroup;
  timerRunning = false;
  startTime:any = '';
  endTime:any = '';
  apiInterval: any;
  hideMachine: boolean = false;
  showfields: boolean = false;
  livecurrentVal: any;
  deviceId: any;
  machine_List:any = [];
  showRecord: boolean = false;
  selectedMachineName;
  sampleId:any

  ngOnInit(): void {
    this.selectedMachineName = this.route.snapshot.paramMap.get("mch");
    let viewAction = this.route.snapshot.paramMap.get("view");
    if (viewAction == "records") {
      this.hideMachine = true;
    } else {
      // this.deactivate = false;
      sessionStorage.setItem("Deactivate", "true");
      this.hideMachine = false;
      this.showRecord = true;
    }

    console.log(this.selectedMachineName, viewAction, "deviceId");
    this.breadCrumbItems = [{ label: "K2" }, { label: "Sample", active: true }];
    this.initalizeform();
    this.getDevices();
    this.updateTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
    this.getCustData(this.custId);
    this.apiInterval = setInterval(() => {
      this.getCustData(this.custId);
    }, 30000);
  }

  initalizeform() {
    this.validationform = this.formBuilder.group({
      program: ["", [Validators.required]],
      sample_name: ["", [Validators.required]],
    });
  }

  get form() {
    return this.validationform.controls;
  }

  getDevices() {
    this.dataservice.get("api/device").subscribe(
      (res) => {
        if (res) {
          this.deviceList = res;
          this.deviceList = this.deviceList.filter((val) => {
            if (val.deviceName == this.selectedMachineName) {
              return val;
            }
          });
          this.getRecords();
        }
      },
      (err) => {
        console.log(err, "err");
        this.errmsg = err;
        this.toastr.warning("Server Unreachable. Please try again");
      }
    );
  }

  getRecords() {
    this.dataservice.get("api/getsample").subscribe(
      (res) => {
        if (res) {
          console.log(res, "response");
          this.sampleData = res;
        }
      },
      (err) => {
        console.log(err, "err");
        this.errmsg = err;
        this.toastr.warning("Server Unreachable. Please try again");
      }
    );
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    console.log(pageNum, this.pageSize, "pageNum");
  }

  saveData() {
    this.submitted = true;
    console.log(
      this.validationform.value,
      (this.submitted = true),
      this.validationform,
      "valueee"
    );
    console.log(this.endTime, "endTime", this.startTime, this.deviceList);
    let data = {};
    data["sample"] = this.validationform.value["sample_name"];
    data["program"] = this.validationform.value["program"];
    data["custId"] = this.deviceList[0].custId;
    data["startTime"] = this.epoch(this.startTime);
    data["endTime"] = this.epoch(this.endTime);
    data["duration"] = this.duration;
    data["deviceId"] = this.deviceList[0].deviceId;
    data["deviceName"] = this.selectedMachineName;
    if (this.validationform.status === "VALID") {
      console.log(data, "dataaa", this.hideMachine);
      this.dataservice.post("api/addsample", data).subscribe((res) => {
        console.log(res, "resss");
        if (res) {
          this.toastr.success("Sample record Added Successfully");
          this.endTime = "";
          this.startTime = "";
          this.duration = "";
          this.submitted = false;
          this.deactivate = true;
          sessionStorage.setItem("Deactivate", "true");
          this.router.navigate(["/machineInfo"]);
        }
      });
    } else {
      // Do Nothing
      return this.validationform.controls;
    }
  }

  epoch(time) {
    let currentDate = new Date();
    let [hours, minutes, seconds] = time.split(":").map(Number);
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(seconds);
    let epochTime = currentDate.getTime();
    return epochTime;
  }

  updateTime() {
    this.currentTime = new Date();
  }

  startTimer() {
    console.log(this.deactivate, "ghgh");
    this.addRecord = false
    this.deactivate = !this.deactivate;
    sessionStorage.setItem("Deactivate", "false");
    this.timerRunning = true;
    console.log(this.currentTime, (this.currentTime = new Date()));
    this.startTime = this.converdata(this.currentTime);
  }

  converdata(dateString) {
    const date = new Date(dateString);
    const hours = ("0" + date.getHours()).slice(-2); // Extract hours and ensure two digits
    const minutes = ("0" + date.getMinutes()).slice(-2); // Extract minutes and ensure two digits
    const seconds = ("0" + date.getSeconds()).slice(-2); // Extract seconds and ensure two digits
    return hours + ":" + minutes + ":" + seconds;
  }

  stopTimer() {
    clearInterval(this.timer);
    this.timerRunning = false;
    this.endTime = this.converdata(this.currentTime);
    console.log(this.startTime, this.endTime);
    this.duration = this.getTimeDifference(this.startTime, this.endTime);
    console.log(this.getTimeDifference(this.startTime, this.endTime));
    this.showfields = !this.showfields;
  }

  getTimeDifference(time1, time2) {
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);
    const milliseconds1 = (hours1 * 3600 + minutes1 * 60 + seconds1) * 1000;
    const milliseconds2 = (hours2 * 3600 + minutes2 * 60 + seconds2) * 1000;
    const diffMilliseconds = Math.abs(milliseconds2 - milliseconds1);
    const hours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);
    const formattedHours = ("0" + hours).slice(-2);
    const formattedMinutes = ("0" + minutes).slice(-2);
    const formattedSeconds = ("0" + seconds).slice(-2);
    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  }

  getCustData(custId) {
    var device = {
      custID: custId,
    };
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/devices_oee?code=IDafVwREaejNu5cZkPFu9KcbltWw2a4YKmBewQD5HrteAzFuh1QEQg==",
        device
      )
      .subscribe((res) => {
        console.log(res, "abcd");
        this.machine_List = res["device_List"];
        this.machine_List = this.machine_List.filter((val) => {
          if (val.device_name == this.selectedMachineName) {
            return val;
          }
        });
        console.log(this.selectedMachineName, "selected", this.machine_List);
        let current = this.machine_List.find(
          (item) => item.device_name == this.selectedMachineName
        );
        if (current !== undefined) {
          this.livecurrentVal = current.current;
        }
        console.log(current, "currentt");
      });
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  statusColor(data) {
    console.log(data, "ststsus");

    switch (data) {
      case 1: {
        return "#ffff94";
        break;
      }
      case 0: {
        return "#f7c030";
        break;
      }
      case 2: {
        return "#548237";
        break;
      }
      case 3: {
        return "#bfe0a5";
        break;
      }
      case 4: {
        return "#ee4932";
        break;
      }
      default: {
        return "#0092ff";
        //statements;
        break;
      }
    }
  }

  epochToHHMMSS(epochTime) {
    var epoch = parseInt(epochTime);
    var date = new Date(epoch);
    var day = ("0" + date.getDate()).slice(-2);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var year = ("0" + date.getFullYear()).slice(-2);

    // Get hours, minutes, and seconds
    var hours = date.getHours();
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);

    // Format hours in 12-hour format
    var amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    // Construct the time string
    var formattedTime =
      day +
      "/" +
      month +
      "/" +
      year +
      "  " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      amPm;

    return formattedTime;
  }

  openModalDelete(deletetemp, id) {
    console.log(id, "sample");
    this.sampleId = id;
    this.modalService.open(deletetemp, { backdrop: "static", centered: true });
  }


  deleteRecords(id) {
    this.dataservice.delete("api/sample/" + id).subscribe(
      (res) => {
        if (res) {
          this.toastr.success("Sample deleted Successfully");
          console.log(res, "res");
          this.modalService.dismissAll()
          this.getRecords();
        }
      },
      (err) => {
        console.log(err, "err");
        this.toastr.error("Unable to delete.Server Unreachable");
      }
    );
  }


  close() {
    this.deactivate = true;
    sessionStorage.setItem("Deactivate", "true");
    this.hideMachine = false;
    this.router.navigate(["/machineInfo"]);
  }

  showNavigationNotAllowedToast() {
    this.toastr.error(
      "You are not allowed to navigate away.",
      "Permission Denied"
    );
  }

  converttohh(time){
    return moment(time).format("HH:mm:ss")
  }

  discard() {
    // this.addRecord = true
    // this.hideMachine = true;
    // this.startTime = "";
    // this.endTime = "";
    // this.startTime = "";
    // this.duration = "";
    // this.showfields = false;
    sessionStorage.setItem("Deactivate", "true");
    this.router.navigate(["/machineInfo"]);
    // this.timer = setInterval(() => {
    //   this.updateTime();
    // }, 1000);
    // this.getCustData(this.custId);
  }

  canDeactivate(): boolean {
    if (!this.deactivate) {
      this.showNavigationNotAllowedToast();
    } else {
      return this.deactivate;
    }
  }
}
