import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import bsCustomFileInput from "bs-custom-file-input";
import { DataService } from "src/app/shared/Services/data.service";
import {
  maxDigitsValidator,
  maxDigitsValidatorforConfig,
} from "src/app/shared/Services/custom-validator";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Component({
  selector: "app-device",
  templateUrl: "./deviceadd.component.html",
  styleUrls: ["./deviceadd.component.scss"],
})
export class DeviceComponent implements OnInit {
  typesubmit: boolean;
  deviceForm: FormGroup;
  machineTypeform: FormGroup;
  pins: FormArray;
  analog: FormArray;
  ct: FormArray;
  isAdd: boolean = false;
  breadCrumbItems: Array<{}>;
  deviceList: any = [];
  custId: any = "";
  loginRole: any = "";
  currentPage = 1;
  itemsPerPage = 5;
  isEdit: boolean = false;
  id: "";
  pageSize;
  submit: boolean = false;
  delMachId = "";
  machineTypeList: any = [];
  partConfigOption = [true, false];
  selectedOptionIndex: number = -1;
  phaseSequence: string[] = ["1P2W", "2P3W", "3P3W", "3P4W"];
  FrequencySequency: string[] = ["60Hz", "50Hz"];

  constructor(
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    let userDetails = JSON.parse(sessionStorage.getItem("currentUser"));
    let user = userDetails["user"];
    this.loginRole = user["role"];
  }

  ngOnInit() {
    // this.loginRole = "SUPERADMIN"
    // console.log(this.loginRole, "this.loginRole")
    this.custId = this.route.snapshot.paramMap.get("id");
    console.log(this.custId, "custId");
    this.getMachineType();
    if (this.custId) {
      this.custDevices(this.custId);
    } else {
      this.getDevices();
    }

    this.breadCrumbItems = [
      { label: "Configuration" },
      { label: "Device", active: true },
    ];
    bsCustomFileInput.init();
  }

  initializeForm() {
    const eMicConfigData = [
      {
        eMICName: ["Frequency", [Validators.required]],
        eMICType: "50Hz",
      },
      {
        eMICName: ["Phase Sequence", [Validators.required]],
        eMICType: "3P4W",
      },
      {
        eMICName: "PGA Gain Configuration",
        eMICType: [
          "",
          [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)],
        ],
      },
    ];
    this.deviceForm = this.formBuilder.group({
      deviceId: ["", [Validators.required, Validators.maxLength(12)]],
      deviceName: ["", [Validators.required]],
      deviceType: ["", Validators.required],
      deviceModelNo: ["", [Validators.required]],
      deviceController: ["", [Validators.required]],
      deviceMake: ["", [Validators.required]],
      devicesupplier: ["", [Validators.required]],
      deviceage: ["", [Validators.required, maxDigitsValidator(3)]],
      voltage: ["", [Validators.required, maxDigitsValidator(3)]],
      powerfactor: ["", [Validators.required, maxDigitsValidator(3)]],
      current: ["", [Validators.required, maxDigitsValidator(3)]],
      axis: ["", [Validators.required, maxDigitsValidator(3)]],
      targetPart: ["", [Validators.required]],
      spindle: ["", [Validators.required, maxDigitsValidator(3)]],
      oee: ["", Validators.required],
      licensing: [3],
      meterSupplier: ["", Validators.required],
      meterModel: ["", [Validators.required]],
      connectionType: ["", [Validators.required]],
      pins: this.formBuilder.array([]),
      analog: this.formBuilder.array([], Validators.required),
      ct: this.formBuilder.array([]),
      transmissionFrequency: 30,
      eMICConfig: this.formBuilder.array(
        eMicConfigData.map((item) => this.createEMICConfig(item))
      ),
    });
    console.log(this.deviceForm);
  }

  onInput(event: Event, i: any) {
    console.log(i,"iiiiii")
    const input = event.target as HTMLInputElement;
    if (parseFloat(input.value) < 0) {
      this.eMICConfigArray.at(i).get('eMICType')?.setValue('')
      input.value = "";
    }
  }

  get eMICConfigArray(): FormArray {
    return this.deviceForm.get("eMICConfig") as FormArray;
  }
  getDevices() {
    this.dataservice.get("api/device").subscribe((res) => {
      console.log(res);
      this.deviceList = res;
    });
  }

  custDevices(id) {
    this.dataservice.get(`api/device?custId=${id}`).subscribe((res) => {
      console.log(res);
      this.deviceList = res;
    });
  }

  addDigitalPin() {
    const arrayControl = this.deviceForm.get("pins") as FormArray;
    let previousArray = arrayControl.value;
    if (previousArray[0]?.partConfig == "true") {
      this.selectedOptionIndex = 0;
    } else {
      this.selectedOptionIndex = -1;
    }
    if (arrayControl.length < 2) {
      arrayControl.push(
        this.formBuilder.group({
          index: new FormControl({
            value: arrayControl.length,
            disabled: true,
          }),
          pinName: ["", [Validators.required]],
          pinType: ["none", [Validators.required]],
          partConfig: [false],
        })
      );
    }
  }

  isOptionDisabled(index: number): boolean {
    console.log(this.selectedOptionIndex);
    return index == this.selectedOptionIndex;
  }

  addAnalogPin() {
    const arrayControl = this.deviceForm.get("analog") as FormArray;
    if (arrayControl.length < 4) {
      arrayControl.push(
        this.formBuilder.group({
          index: new FormControl({
            value: arrayControl.length,
            disabled: true,
          }),
          analogName: ["Demo", [Validators.required]],
          portType: ["None", [Validators.required]],
          channel: [1, [Validators.required]],
          upperLimit: [
            0,
            [
              Validators.pattern(/^\d*\.?\d*$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          lowerLimit: [
            0,
            [
              Validators.pattern(/^\d*\.?\d*$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          scaleFactor: [
            0,
            [
              Validators.pattern(/^\d*\.?\d*$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          ctRatio: [
            0,
            [
              Validators.pattern(/^\d*\.?\d*$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          divisionFactor: [
            0,
            [
              Validators.pattern(/^\d*\.?\d*$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          multiFactor: [
            0,
            [
              Validators.pattern(/^\d*\.?\d*$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
        })
      );
    }
  }

  addCurrentPin() {
    const arrayControl = this.deviceForm.get("ct") as FormArray;
    if (arrayControl.length < 2) {
      arrayControl.push(
        this.formBuilder.group({
          index: new FormControl({
            value: arrayControl.length,
            disabled: true,
          }),
          ctName: ["", [Validators.required]],
          ctRate: [
            "",
            [Validators.required, maxDigitsValidatorforConfig(4, 5)],
          ],
          upperLimit: [
            "",
            [
              Validators.required,
              Validators.pattern(/^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          upperSet: [
            "",
            [
              Validators.required,
              Validators.pattern(/^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          lowerLimit: [
            "",
            [
              Validators.required,
              Validators.pattern(/^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
          lowerSet: [
            "",
            [
              Validators.required,
              Validators.pattern(/^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/),
              maxDigitsValidatorforConfig(4, 5),
            ],
          ],
        })
      );
    }
  }

  removeArrayItem(index: number) {
    const arrayControl = this.deviceForm.get("pins") as FormArray;
    arrayControl.removeAt(index);
    let previousArray = arrayControl.value;
    if (previousArray[0]?.partConfig == "true") {
      this.selectedOptionIndex = 0;
    } else {
      this.selectedOptionIndex = -1;
    }
    for (let i = index; i < arrayControl.length; i++) {
      arrayControl.at(i).get("index").setValue(i);
    }
  }

  onMeterChange(e) {
    console.log(e, "onMeterChange");
    this.deviceForm.get("meterModel").setValue("");
  }

  ondeviceTypeChange(e) {
    console.log(e, "event");
    if (e !== "CNC") {
      this.deviceForm.get("axis").setValue(0);
      this.deviceForm.get("spindle").setValue(0);
    }
  }

  onOEEChange(e) {
    console.log(e, "event");
    if (e !== "parts") {
      this.deviceForm.get("targetPart").setValue(0);
    }
  }
  removeAnalogItem(index: number) {
    const arrayControl = this.deviceForm.get("analog") as FormArray;
    arrayControl.removeAt(index);
    for (let i = index; i < arrayControl.length; i++) {
      arrayControl.at(i).get("index").setValue(i);
    }
  }
  removeCurrentItem(index: number) {
    const arrayControl = this.deviceForm.get("ct") as FormArray;
    arrayControl.removeAt(index);
    for (let i = index; i < arrayControl.length; i++) {
      arrayControl.at(i).get("index").setValue(i);
    }
  }
  get type() {
    return this.deviceForm.controls;
  }
  get form() {
    return this.machineTypeform.controls;
  }
  createEMICConfig(configData: any): FormGroup {
    return this.formBuilder.group({
      eMICName: configData.eMICName,
      eMICType: [configData.eMICType, Validators.required],
    });
  }
  typeSubmit() {
    this.typesubmit = true;
    this.deviceForm.enable();
    console.log(this.deviceForm.value, this.deviceForm, "deviceForm");
    if (this.deviceForm.status == "INVALID") {
      if (this.isEdit) {
        this.disableParams();
      }
      return;
    } else {
      if (this.isEdit) {
        let params = {
          id: this.id,
          custId: this.custId,
        };
        // Update API
        if (this.loginRole == "SUPERADMIN") {
          this.dataservice
            .put(
              `api/device/` + params["id"] + "/" + params["custId"],
              this.deviceForm.value
            )
            .subscribe((res) => {
              console.log(res, "response");
              if (res) {
                this.toastr.success("Machine Updated Successfully");
                this.cancel();
                this.custDevices(this.custId);
              } else {
                // Do Nothing
              }
            });
        } else {
          this.dataservice
            .put(
              `api/device/` + params["id"] + "/" + params["custId"],
              this.deviceForm.value
            )
            .subscribe((res) => {
              console.log(res, "response");
              if (res) {
                this.toastr.success("Machine Updated Successfully");
                this.cancel();
                this.getDevices();
              } else {
                // Do Nothing
              }
            });
        }
      } else {
        // Adding API
        if (this.loginRole == "SUPERADMIN") {
          this.dataservice
            .post(`api/device?custId=${this.custId}`, this.deviceForm.value)
            .subscribe((res) => {
              console.log(res, "response");
              if (res) {
                console.log(this.deviceForm.value["deviceId"]);
                this.createCertificate(this.deviceForm.value["deviceId"]);
                this.toastr.success("Machine added Successfully");
                this.cancel();
                this.custDevices(this.custId);
              } else {
                // Do Nothing
              }
            });
        } else {
          this.dataservice
            .post(`api/device`, this.deviceForm.value)
            .subscribe((res) => {
              console.log(res, "response");
              if (res) {
                this.createCertificate(this.deviceForm.value["deviceId"]);

                this.toastr.success("Machine added Successfully");
                this.cancel();
                this.getDevices();
              } else {
                // Do Nothing
              }
            });
        }
      }
    }
    console.log(this.deviceForm.value, this.deviceForm, "form");
  }
  createCertificate(id) {
    var params = {
      deviceID: id,
    };
    this.http
      .post(
        `https://k2-oee-prod.azurewebsites.net/api/manage_devices?code=4nodmcXNn0Dp4sCGzci1QbqUdmbz6W6yJZc4yC1ef4GDAzFuc4l26Q%3D%3D`,
        params
      )
      .subscribe((res) => {
        console.log(res);
        if (res["certs_created"] === "Yes") {
          this.toastr.success("Certificate Created Successfully");
        } else {
          this.toastr.warning("Certificate Not Created");
        }
      });
  }
  deleteCertificate(id) {
    // var params={
    //   "deviceID": id
    // }
    const body = JSON.stringify({ deviceID: id }); // Convert body to JSON string
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    // const params = new HttpParams().set('deviceID', id);
    this.http
      .request(
        "DELETE",
        `https://k2-oee-prod.azurewebsites.net/api/manage_devices?code=4nodmcXNn0Dp4sCGzci1QbqUdmbz6W6yJZc4yC1ef4GDAzFuc4l26Q%3D%3D`,
        { headers: headers, body: body }
      )
      .subscribe((res) => {
        console.log(res);
        if (res["certs_deleted"] === "Yes") {
          this.toastr.success("Certificate Deleted Successfully");
        } else {
          this.toastr.warning("Certificate Not Deleted");
        }
      });
  }
  adddevice() {
    this.initializeForm();
    console.log(this.deviceForm, "jhgfdfgh");
    this.isAdd = true;
    this.addAnalogPin();
  }

  disableParams() {
    if (this.loginRole == "SUPERADMIN") {
      const digitalPins = this.deviceForm.get("pins") as FormArray;
      digitalPins.controls.forEach((control) => {
        control.get("index").disable();
        control.get("pinName").enable();
        control.get("pinType").enable();
        control.get("partConfig").enable();
      });

      // Disable analog signals
      const analogSignals = this.deviceForm.get("analog") as FormArray;
      analogSignals.controls.forEach((control) => {
        control.get("index").disable();
        control.get("analogName").enable();
        control.get("portType").enable();
        control.get("channel").enable();
        control.get("upperLimit").enable();
        control.get("lowerLimit").enable();
        control.get("scaleFactor").enable();
        control.get("ctRatio").enable();
        control.get("divisionFactor").enable();
        control.get("multiFactor").enable();
      });

      // Disable CTs
      const cts = this.deviceForm.get("ct") as FormArray;
      cts.controls.forEach((control) => {
        control.get("index").disable();
        control.get("ctName").enable();
        control.get("ctRate").enable();
        control.get("upperLimit").enable();
        control.get("upperSet").enable();
        control.get("lowerLimit").enable();
        control.get("lowerSet").enable();
      });

      // Disable EMICOnfigs
      const emi = this.deviceForm.get("eMICConfig") as FormArray;
      emi.controls.forEach((control) => {
        control.get("eMICName").disable();
      });

      this.deviceForm.get("deviceId").disable();
    } else {
      this.deviceForm.get("licensing").disable();
      this.deviceForm.get("oee").disable();
      this.deviceForm.get("deviceId").disable();

      const digitalPins = this.deviceForm.get("pins") as FormArray;
      digitalPins.controls.forEach((control) => {
        control.get("index").disable();
        control.get("pinName").enable();
        control.get("pinType").disable();
        control.get("partConfig").disable();
      });

      // Disable analog signals
      const analogSignals = this.deviceForm.get("analog") as FormArray;
      analogSignals.controls.forEach((control) => {
        control.get("index").disable();
        control.get("analogName").enable();
        control.get("portType").disable();
        control.get("channel").disable();
        control.get("upperLimit").disable();
        control.get("lowerLimit").disable();
        control.get("scaleFactor").disable();
        control.get("ctRatio").disable();
        control.get("divisionFactor").disable();
        control.get("multiFactor").disable();
      });

      // Disable CTs
      const cts = this.deviceForm.get("ct") as FormArray;
      cts.controls.forEach((control) => {
        control.get("index").disable();
        control.get("ctName").enable();
        control.get("ctRate").disable();
        control.get("upperLimit").disable();
        control.get("upperSet").disable();
        control.get("lowerLimit").disable();
        control.get("lowerSet").disable();
      });

      // Disable EMICOnfigs
      const emi = this.deviceForm.get("eMICConfig") as FormArray;
      emi.controls.forEach((control) => {
        control.get("eMICName").disable();
      });
    }
  }
  edit(data) {
    this.isEdit = true;
    this.initializeForm();
    console.log(data, "kjkj");
    if (data) {
      this.id = data.id;
      const {
        deviceId,
        deviceName,
        deviceType,
        deviceModelNo,
        deviceController,
        deviceMake,
        devicesupplier,
        deviceage,
        current,
        axis,
        spindle,
        voltage,
        oee,
        powerfactor,
        targetPart,
        licensing,
        meterSupplier,
        meterModel,
        connectionType,
      } = data;
      console.log(deviceId, powerfactor, voltage);
      if (this.loginRole == "SUPERADMIN") {
        let digitalPins = data.pins;
        let analogSignal = data.analog;
        let cts = data.ct;
        let eMs = data.eMICConfig;

        const pins = this.deviceForm.get("pins") as FormArray;
        digitalPins?.forEach((item) => {
          pins.push(
            this.formBuilder.group({
              index: new FormControl({ value: item.index, disabled: true }),
              pinName: [item.pinName, [Validators.required]],
              pinType: [item.pinType, [Validators.required]],
              partConfig: [item.partConfig],
            })
          );
        });
        const analog = this.deviceForm.get("analog") as FormArray;
        analogSignal?.forEach((item) => {
          analog.push(
            this.formBuilder.group({
              index: new FormControl({ value: item.index, disabled: true }),
              analogName: [item.analogName, [Validators.required]],
              portType: [item.portType, [Validators.required]],
              channel: [item.channel, [Validators.required]],
              upperLimit: [
                item.upperLimit,
                [
                  Validators.pattern(/^\d*\.?\d*$/),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              lowerLimit: [
                item.lowerLimit,
                [
                  Validators.pattern(/^\d*\.?\d*$/),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              scaleFactor: [
                item.scaleFactor,
                [
                  Validators.pattern(/^\d*\.?\d*$/),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              ctRatio: [
                item.ctRatio,
                [
                  Validators.pattern(/^\d*\.?\d*$/),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              divisionFactor: [
                item.divisionFactor,
                [
                  Validators.pattern(/^\d*\.?\d*$/),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              multiFactor: [
                item.multiFactor,
                [
                  Validators.pattern(/^\d*\.?\d*$/),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
            })
          );
        });
        const ct = this.deviceForm.get("ct") as FormArray;
        cts?.forEach((item) => {
          ct.push(
            this.formBuilder.group({
              index: new FormControl({ value: item.index, disabled: true }),
              ctName: [item.ctName, [Validators.required]],
              ctRate: [
                item.ctRate,
                [Validators.required, maxDigitsValidatorforConfig(4, 5)],
              ],
              upperLimit: [
                item.upperLimit,
                [
                  Validators.required,
                  Validators.pattern(
                    /^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/
                  ),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              upperSet: [
                item.upperSet,
                [
                  Validators.required,
                  Validators.pattern(
                    /^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/
                  ),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              lowerLimit: [
                item.lowerLimit,
                [
                  Validators.required,
                  Validators.pattern(
                    /^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/
                  ),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
              lowerSet: [
                item.lowerSet,
                [
                  Validators.required,
                  Validators.pattern(
                    /^\d{1,4}(\.\d{1,3}[05]?|\.([05]0?|00))?$/
                  ),
                  maxDigitsValidatorforConfig(4, 5),
                ],
              ],
            })
          );
        });
        const eM = this.deviceForm.get("eMICConfig") as FormArray;
        eMs?.forEach((item, index) => {
          const formGroup = this.formBuilder.group({
            eMICName: [item.eMICName, [Validators.required]],
            eMICType: [item.eMICType, [Validators.required]],
          });

          if (eM.at(index)) {
            // If the form group already exists at the index, update it
            eM.at(index).patchValue(formGroup.value);
          } else {
            // If the form group doesn't exist, add a new one
            eM.push(formGroup);
          }
        });
      } else {
        let digitalPins = data.pins;
        let analogSignal = data.analog;
        let cts = data.ct;
        let eMs = data.eMICConfig;

        const pins = this.deviceForm.get("pins") as FormArray;
        digitalPins?.forEach((item) => {
          pins.push(
            this.formBuilder.group({
              index: new FormControl({ value: item.index, disabled: true }),
              pinName: [item.pinName, [Validators.required]],
              pinType: new FormControl({ value: item.pinType, disabled: true }),
              partConfig: new FormControl({
                value: item.partConfig,
                disabled: true,
              }),
            })
          );
        });
        const analog = this.deviceForm.get("analog") as FormArray;
        analogSignal?.forEach((item) => {
          analog.push(
            this.formBuilder.group({
              index: new FormControl({ value: item.index, disabled: true }),
              analogName: [item.analogName, [Validators.required]],
              portType: new FormControl({
                value: item.portType,
                disabled: true,
              }),
              channel: new FormControl({
                value: item.channel,
                disabled: true,
              }),
              upperLimit: new FormControl({
                value: item.upperLimit,
                disabled: true,
              }),
              lowerLimit: new FormControl({
                value: item.lowerLimit,
                disabled: true,
              }),
              scaleFactor: new FormControl({
                value: item.scaleFactor,
                disabled: true,
              }),
              multiFactor: new FormControl({
                value: item.multiFactor,
                disabled: true,
              }),
              divisionFactor: new FormControl({
                value: item.divisionFactor,
                disabled: true,
              }),
              ctRatio: new FormControl({
                value: item.ctRatio,
                disabled: true,
              }),
            })
          );
        });
        const ct = this.deviceForm.get("ct") as FormArray;
        cts?.forEach((item) => {
          ct.push(
            this.formBuilder.group({
              index: new FormControl({ value: item.index, disabled: true }),
              ctName: [item.ctName, [Validators.required]],
              ctRate: new FormControl({ value: item.ctRate, disabled: true }),
              upperLimit: new FormControl({
                value: item.upperLimit,
                disabled: true,
              }),
              upperSet: new FormControl({
                value: item.upperSet,
                disabled: true,
              }),
              lowerLimit: new FormControl({
                value: item.lowerLimit,
                disabled: true,
              }),
              lowerSet: new FormControl({
                value: item.lowerSet,
                disabled: true,
              }),
            })
          );
        });
        const eM = this.deviceForm.get("eMICConfig") as FormArray;
        eMs?.forEach((item) => {
          eM.push(
            this.formBuilder.group({
              eMICName: [item.eMICName, [Validators.required]],
              eMICType: [item.eMICType, [Validators.required]],
            })
          );
        });
      }

      this.deviceForm.patchValue({
        deviceId: deviceId || "",
        deviceName: deviceName || "",
        deviceType: deviceType || [""],
        deviceModelNo: deviceModelNo || "",
        deviceController: deviceController || "",
        deviceMake: deviceMake || "",
        devicesupplier: devicesupplier || "",
        deviceage: deviceage || "",
        powerfactor: powerfactor || "",
        voltage: voltage || "",
        current: current || "",
        axis: axis || 0,
        spindle: spindle || 0,
        oee: oee || [""],
        targetPart: targetPart || 0,
        licensing: licensing || [3],
        meterModel: meterModel || "",
        meterSupplier: meterSupplier || "",
        connectionType: connectionType || "",
        transmissionFrequency: 30,
      });
      if (this.loginRole == "CUSTOMER") {
        this.custId = data.custId;
        this.deviceForm.get("licensing").disable();
        this.deviceForm.get("oee").disable();
      }
      this.deviceForm.get("deviceId").disable();
      this.isAdd = true;
      console.log(this.deviceForm.value, "edit");
    }
  }

  cancel() {
    // this.deviceForm.reset()
    this.typesubmit = false;
    this.isAdd = false;
    this.isEdit = false;
  }

  delete(id, deviceId) {
    console.log(id, "idddd");
    if (this.loginRole == "SUPERADMIN") {
      this.dataservice.delete("api/device/" + id).subscribe((res) => {
        if (res) {
          this.toastr.success("Machine deleted Successfully");
          console.log(res, "res");
          this.deleteCertificate(deviceId);
          this.modalService.dismissAll();
          this.custDevices(this.custId);
        }
      });
    }
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    console.log(pageNum, this.pageSize, "pageNum");
  }

  openModal(infotemplate: any, act: any) {
    this.isAdd = false;
    console.log(act, "data");
    this.modalService.open(infotemplate, { centered: true });
    this.machineTypeform = this.formBuilder.group({
      deviceType: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
    // Edit
    // if (data) {
    //   this.id = data.id
    //   this.pathValue(this.machineTypeform, data);
    // }
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      controls[key].patchValue(data[key]);
    }
  }

  getMachineType() {
    this.machineTypeList = [];
    this.dataservice.get("api/deviceType").subscribe((res: any) => {
      console.log(res, "response");
      if (res) {
        let machineTypeList = res;
        machineTypeList.forEach((element) => {
          this.machineTypeList.push(element.deviceType);
        });
      } else {
        this.machineTypeList = [];
      }
    });
  }

  saveData() {
    this.submit = true;
    if (this.machineTypeform.invalid) {
      return;
    } else {
      console.log(this.machineTypeform.value, "jjkj");
      this.dataservice
        .post("api/deviceType", this.machineTypeform.value)
        .subscribe((res) => {
          console.log(res, "response");
          if (res) {
            this.toastr.success("Machine Type added Successfully");
            this.cancelModal();
            this.getMachineType();
          }
        });
    }
  }
  deviceId;
  openModalDelete(deletetemp, id, item) {
    this.delMachId = id;
    this.deviceId = item["deviceId"];
    // console.log(item)
    this.modalService.open(deletetemp, { centered: true });
  }

  cancelModal() {
    this.machineTypeform.reset();
    this.modalService.dismissAll();
    this.submit = false;
  }
}
