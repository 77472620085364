import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ShareService {
  checkedItems: any;
  checkedList = new BehaviorSubject<any>("");
  shiftData: any;
  shiftList = new BehaviorSubject<any>("");
  constructor() {}
  setCheckedList(val: any) {
    this.checkedItems = val;
    this.checkedList.next(this.checkedItems);
  }
  getCheckedItems(): BehaviorSubject<any> {
    return this.checkedList.getValue();
  }
  setShiftData(val: any) {
    this.shiftData = val;
    console.log(this.shiftData, "shared");
    this.shiftList.next(this.shiftData);
  }
  getshiftItem(): BehaviorSubject<any> {
    return this.shiftList.getValue();
  }
}
