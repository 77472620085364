<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div
      class="page-title-box d-flex align-items-center justify-content-between"
    >
      <h4 class="mb-0 d-inline-block">
        {{ title }}
        <span>
          <i
            *ngIf="deviceInfo"
            class="mdi mdi-information title-hover font-size-18 ml-2"
            title="Device Information"
            style="cursor: pointer"
            (click)="openModal(content, deviceData, 'Edit')"
          ></i>
        </span>
      </h4>

      <div class="d-flex align-items-center justify-content-between">
        <button
          *ngIf="deviceInfo"
          (click)="openModal(content, deviceData, 'Edit')"
          class="btn btn-info"
          [hidden]="true"
        >
          Device Information
        </button>
        &nbsp;
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li class="breadcrumb-item" *ngIf="!item.active">
              <a href="javascript: void(0);">{{ item.label }}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="item.active">
              {{ item.label }}
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>

<!-- Contacts model  -->
<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Device Information</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form [formGroup]="validationform" autocomplete="off">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" *ngIf="deviceData.MAC">
              <label for="mac">MAC</label>
              <input
                type="text"
                class="form-control"
                id="mac"
                placeholder="Enter MAC"
                formControlName="MAC"
                [ngClass]="{
                  'is-invalid': submitted && validationform.get('MAC').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('MAC').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('MAC').errors.required"
                  >MAC is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.wifiName">
              <label for="wifiName">Wifi Name</label>
              <input
                type="text"
                class="form-control"
                id="wifiName"
                placeholder="Enter Wifi Name"
                formControlName="wifiName"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('wifiName').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('wifiName').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('wifiName').errors.required"
                  >Wifi Name is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.firmwareVer">
              <label for="firmwareVer">Firmware Version</label>
              <input
                type="text"
                class="form-control"
                id="firmwareVer"
                placeholder="Enter Firmware Version"
                formControlName="firmwareVer"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('firmwareVer').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('firmwareVer').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('firmwareVer').errors.required"
                  >Firmware Version is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.signalQuality">
              <label for="signalQuality">Signal Quality</label>
              <input
                type="text"
                class="form-control"
                id="signalQuality"
                placeholder="Enter Signal Quality"
                formControlName="signalQuality"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('signalQuality').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('signalQuality').errors"
                class="invalid-feedback"
              >
                <span
                  *ngIf="validationform.get('signalQuality').errors.required"
                  >Signal Quality is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.Ip">
              <label for="Ip">IP Address</label>
              <input
                type="text"
                class="form-control"
                id="Ip"
                placeholder="Enter IP Address"
                formControlName="Ip"
                [ngClass]="{
                  'is-invalid': submitted && validationform.get('Ip').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('Ip').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('Ip').errors.required"
                  >IP Address is mandatory.</span
                >
              </div>
            </div>
            <div class="form-group" *ngIf="deviceData.Ki">
              <label for="Ki">Ki</label>
              <input
                type="text"
                class="form-control"
                id="Ki"
                placeholder="Enter Ki"
                formControlName="Ki"
                [ngClass]="{
                  'is-invalid': submitted && validationform.get('Ki').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('Ki').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('Ki').errors.required"
                  >Ki is mandatory.</span
                >
              </div>
            </div>
            <div class="form-group" *ngIf="deviceData.Ku">
              <label for="Ku">Ku</label>
              <input
                type="text"
                class="form-control"
                id="Ku"
                placeholder="Enter Ku"
                formControlName="Ku"
                [ngClass]="{
                  'is-invalid': submitted && validationform.get('Ku').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('Ku').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('Ku').errors.required"
                  >Ku is mandatory.</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" *ngIf="deviceData.chipVersion">
              <label for="chipVersion">Chip Version</label>
              <input
                type="text"
                class="form-control"
                id="chipVersion"
                placeholder="Enter Chip Version"
                formControlName="chipVersion"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('chipVersion').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('chipVersion').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('chipVersion').errors.required"
                  >Chip Version is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.compileTime">
              <label for="compileTime">Compile Time</label>
              <input
                type="text"
                class="form-control"
                id="compileTime"
                placeholder="Enter Compile Time"
                formControlName="compileTime"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('compileTime').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('compileTime').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('compileTime').errors.required"
                  >Compile Time is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.buildDateTime">
              <label for="buildDateTime">Build Date Time</label>
              <input
                type="text"
                class="form-control"
                id="buildDateTime"
                placeholder="Enter Build Date Time"
                formControlName="buildDateTime"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('buildDateTime').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('buildDateTime').errors"
                class="invalid-feedback"
              >
                <span
                  *ngIf="validationform.get('buildDateTime').errors.required"
                  >Build Date Time is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData['ESP-IDF']">
              <label for="ESP-IDF">ESP-IDF</label>
              <input
                type="text"
                class="form-control"
                id="ESP-IDF"
                placeholder="Enter ESP-IDF"
                formControlName="ESP-IDF"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('ESP-IDF').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('ESP-IDF').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('ESP-IDF').errors.required"
                  >ESP-IDF is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.otaUrl">
              <label for="otaUrl">OTA Url</label>
              <input
                type="text"
                class="form-control"
                id="otaUrl"
                placeholder="Enter OTA Url"
                formControlName="otaUrl"
                [ngClass]="{
                  'is-invalid': submitted && validationform.get('otaUrl').errors
                }"
              />
              <div
                *ngIf="submitted && validationform.get('otaUrl').errors"
                class="invalid-feedback"
              >
                <span *ngIf="validationform.get('otaUrl').errors.required"
                  >OTA Url is mandatory.</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="deviceData.deviceConfigUrl">
              <label for="deviceConfigUrl">Device Config Url</label>
              <input
                type="text"
                class="form-control"
                id="deviceConfigUrl"
                placeholder="Enter Device Config Url"
                formControlName="deviceConfigUrl"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('deviceConfigUrl').errors
                }"
              />
              <div
                *ngIf="
                  submitted && validationform.get('deviceConfigUrl').errors
                "
                class="invalid-feedback"
              >
                <span
                  *ngIf="validationform.get('deviceConfigUrl').errors.required"
                  >Device Config Url is mandatory.</span
                >
              </div>
            </div>
            <div class="form-group" *ngIf="deviceData['PLConstant Value']">
              <label for="PLConstant">PLConstant Value</label>
              <input
                type="text"
                class="form-control"
                id="PLConstant"
                placeholder="Enter Device Config Url"
                formControlName="PLConstant Value"
                [ngClass]="{
                  'is-invalid':
                    submitted && validationform.get('PLConstant Value').errors
                }"
              />
              <div
                *ngIf="
                  submitted && validationform.get('PLConstant Value').errors
                "
                class="invalid-feedback"
              >
                <span
                  *ngIf="validationform.get('PLConstant Value').errors.required"
                  >PLConstant Value is mandatory.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
            type="button"
            class="btn btn-warning"
            (click)="factoryReset()"
          >
            Factory Reset
          </button>
          <button
            type="button"
            class="btn btn-danger ml-1"
            (click)="deviceReset()"
          >
            Device Reset
          </button>
          <button
            type="button"
            class="btn btn-primary ml-1"
            (click)="clearWifiCredentials()"
          >
            Clear WiFi Credentials
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
