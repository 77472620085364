<div class="container-fluid">
  <app-pagetitle
    title="firmware Dashboard"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>
  <div class="d-flex justify-content-end">
    <div class="example-button-row">
      <button class="btn btn-primary" title="Add" (click)="addFirmware()">
        Add Firmware
      </button>
    </div>
  </div>
  <div class="row mt-2" *ngIf="!isAdd && !isEdit">
    <!-- table -->
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead
                class="table-light text-black text-black"
                style="color: black !important"
              >
                <tr>
                  <th>Project Name</th>
                  <th>Version</th>
                  <th>Uploads</th>
                  <th>LastModifiedAt</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody
                *ngFor="
                  let item of versions
                    | slice : pageSize
                    | slice : 0 : itemsPerPage;
                  let i = index
                "
              >
                <tr>
                  <td>{{ item.project }}</td>
                  <td>{{ item.version }}</td>
                  <td>
                    <div *ngFor="let file of item.name">
                      {{ file }}
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let date of item.lastModify">
                      {{ format(date) }}
                    </div>
                  </td>
                  <td>
                    <a class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                      <i
                        class="mdi mdi-pencil font-size-18"
                        style="cursor: pointer"
                        (click)="edit(item)"
                      ></i>
                    </a>
                    <a
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      (click)="openModalDelete(deletetemplate, item.project)"
                    >
                      <i
                        class="mdi mdi-trash-can font-size-18"
                        style="cursor: pointer"
                      ></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="versions?.length > 2">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="versions?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- table -->
  </div>
  <div class="row mt-2" *ngIf="isAdd || isEdit">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Firmware Configuration</h4>
          <form (ngSubmit)="save()" [formGroup]="firmwareForm">
            <div class="row">
              <div class="form-group col-md-3">
                <label>Project</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="project"
                  [ngClass]="{
                    'is-invalid': submitted && type.project.errors
                  }"
                  placeholder="Project Name"
                />
                <div
                  *ngIf="submitted && type.project.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.project.errors.required"
                    >Project Name is required.</span
                  >
                </div>
              </div>
              <div class="form-group col-md-3">
                <label>Version</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="version"
                  [ngClass]="{
                    'is-invalid': submitted && type.version.errors
                  }"
                  placeholder="1.2"
                />
                <div
                  *ngIf="submitted && type.version.errors"
                  class="invalid-feedback"
                >
                  <span *ngIf="type.version.errors.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Upload File</label>
                <div class="custom-file">
                  <input
                    type="file"
                    accept=".bin"
                    [ngClass]="{
                      'is-invalid': submitted && type.files.errors
                    }"
                    class="custom-file-input form-control"
                    id="importFile"
                    (change)="fileInputChange($event)"
                    required
                  />
                  <label class="custom-file-label" #labelImport for="importFile"
                    >Select .bin files to upload</label
                  >
                  <div
                    *ngIf="
                      submitted &&
                      firmwareForm.get('files')?.errors?.invalidFileType
                    "
                    class="invalid-feedback"
                  >
                    <span>Only .bin files are allowed.</span>
                  </div>
                  <div
                    *ngIf="submitted && type.files.errors"
                    class="invalid-feedback"
                  >
                    <span *ngIf="type.files.errors.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
                <!-- <input type="file" multiple formControlName="files"
                  class="form-control"
                  (change)="fileInputChange($event)"
                   [ngClass]="{
                    'is-invalid': submitted && type.files.errors
                  }"
                  required #file
                   /> -->
                &nbsp;
                <div formArrayName="files">
                  <div class="row">
                    <div
                      *ngFor="let fileControl of files.controls; let i = index"
                      class="files"
                    >
                      <div [formGroupName]="i">
                        {{ showFiles(fileControl) }}
                        &nbsp;
                        <!-- <a
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Remove File"
                  (click)="removeFile(i)"
                >
                  <i
                    class="mdi mdi-close font-size-18"
                    style="cursor: pointer"
                    
                  ></i>
                </a> -->
                      </div>
                    </div>
                    &nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mt-2">
              <div>
                <button
                  *ngIf="!isEdit"
                  type="submit"
                  class="btn btn-primary mr-1"
                >
                  <span>Submit</span>
                </button>
                <button
                  *ngIf="isEdit"
                  type="submit"
                  class="btn btn-primary mr-1"
                >
                  <span>Update</span>
                </button>
                <button
                  type="reset"
                  class="btn btn-secondary"
                  (click)="cancel()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deletetemplate role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Delete Firmware</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="example-container">
      <form>
        <div>
          <p>Are you sure to Delete?</p>
        </div>
        <button
          type="submit"
          class="btn btn-danger round waves-effect headr"
          (click)="delete(delVersion)"
        >
          Delete
        </button>
        &nbsp;
        <button
          type="submit"
          class="btn btn-info round waves-effect headr"
          (click)="modal('Cross click')"
        >
          Cancel
        </button>
      </form>
    </div>
  </div>
</ng-template>
