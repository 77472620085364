<div id="layout-wrapper">
  <app-topbar
    (mobileMenuButtonClicked)="onToggleMobileMenu()"
    (settingsButtonClicked)="onSettingsButtonClicked()"
  >
  </app-topbar>
  <app-sidebar></app-sidebar>
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content">
    <div class="page-content">
      <!-- <ngb-alert
        #selfClosingAlert
        type="success"
        (closed)="successMessage = ''"
        >{{ successMessage }}</ngb-alert
      > -->
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>

  <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->
