import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";

import { Router } from "@angular/router";

import { DataService } from "src/app/shared/Services/data.service";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ShareService } from "src/app/shared/Services/shared.service";

import pdfMake from "pdfmake/build/pdfMake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as moment from "moment";
import * as momenttz from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import * as echarts from "echarts";
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-Report",
  templateUrl: "./Reports.component.html",
  styleUrls: ["./Reports.component.scss"],
})

/**
 * Report Component
 */
export class ReportsComponent implements OnInit, OnDestroy {
  timezone: any;
  custId;
  maxdate: any;
  mindate: any;
  toDate;
  fromDate: any;
  featureDates: Date[] = []; // Array to hold feature dates
  byRange = ["Yesterday", "7 Days", "15 Days", "Custom"];
  selectedRange = "Yesterday";
  showChart: boolean = false;
  action: any;
  mailform: FormGroup;
  shift_list: any = [];
  seriesdata: any = [];
  chartDataURL: any;
  chartDataURLPie: any;
  reportList = [{ reportName: "Shift" }, { reportName: "Day" }];
  reportType: any;
  selectedType: any;
  currentPage = 1;
  itemsPerPage = 5;
  pageSize;
  tableDayData: any;
  constructor(
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private http: HttpClient,
    private modalService: NgbModal,
    private router: Router,
    private sharedService: ShareService,
    private toastr: ToastrService
  ) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = user["user"]["custId"];
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // this.fromDate = today.toISOString().split("T")[0];
    this.mindate = this.fromDate;
    this.maxdate = yesterday.toISOString().split("T")[0];
    this.fromDate = yesterday.toISOString().split("T")[0];
    this.toDate = yesterday.toISOString().split("T")[0];
  }

  changeMin() {
    console.log(event, this.fromDate);
  }
  changeMax() {
    console.log(event, this.toDate);
    const today = new Date().toISOString().split("T")[0];
    this.maxdate = today; // Update max date when a date is selected
  }
  rangeDataa(value) {
    this.selectedRange = value;
    console.log(this.selectedRange, "this.selectedRange");
    if (this.selectedRange === "Yesterday") {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      this.fromDate = yesterday.toISOString().split("T")[0];
      this.toDate = yesterday.toISOString().split("T")[0];
    } else if (this.selectedRange === "7 Days") {
      const today = new Date();
      const yesterDay = new Date(today);
      yesterDay.setDate(yesterDay.getDate() - 1);
      const past7 = new Date(today);
      past7.setDate(yesterDay.getDate() - 6);
      this.fromDate = past7.toISOString().split("T")[0];
      this.toDate = yesterDay.toISOString().split("T")[0];
      console.log(this.fromDate, "7dayss", this.toDate);
    } else if (this.selectedRange === "15 Days") {
      const today = new Date();
      const yesterDay = new Date(today);
      yesterDay.setDate(yesterDay.getDate() - 1);
      const past15 = new Date(today);
      past15.setDate(yesterDay.getDate() - 14);
      this.fromDate = past15.toISOString().split("T")[0];
      this.toDate = yesterDay.toISOString().split("T")[0];
      console.log(this.fromDate, "15dayss", this.toDate);
    }
    // this.getRemonDevices();
  }
  deviceList;
  deviceName;
  pdfBool: boolean = false;
  csvBool: boolean = false;
  userDetails: any;
  custName: any;
  deviceId: any;
  statusGraph: any;
  totalPart;
  currantAverage;
  total_energy;
  react_energy;
  appa_energy;
  dropdownSettings = {};
  getDevices() {
    this.dataservice.get("api/device").subscribe((res) => {
      this.deviceList = res;
      this.deviceName = this.deviceList[0];
      (this.deviceId = this.deviceName["deviceId"]),
        console.log(
          this.deviceId,
          this.deviceList,
          this.deviceName,
          this.custId,
          this.fromDate
        );
      var param = {
        deviceIds: [this.deviceId],
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      // var param = {
      //   custID: "FLPR7",
      //   deviceID: "348518678A68",
      //   reportDate: "2024-03-06",
      // };
      // console.log(param,'abcd')
      this.getData(param);
    });
  }
  select_Device(value) {
    this.deviceId = value["deviceId"];
    console.log(this.deviceId, value);
  }
  getUtilizationData(range) {}
  // bread crumb items
  breadCrumbItems: Array<{}>;
  toggle() {
    console.log(this.fromDate);
  }
  ngOnInit(): void {
    this.reportType = this.reportList[0].reportName;
    this.selectedType = this.reportList[0].reportName;
    this.breadCrumbItems = [{ label: "K2" }, { label: "Report", active: true }];
    this.userDetails = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custName = this.userDetails["user"]["name"];
    this.getDevices();
    // this.gattchart();
    this.mailform = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.convertImageToBase64();
    // this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: "item_id",
    //   textField: "item_text",
    //   selectAllText: "Select All",
    //   unSelectAllText: "UnSelect All",
    //   itemsShowLimit: 3,
    //   allowSearchFilter: true,
    // };
  }
  get email() {
    return this.mailform.get("email");
  }

  isEmailInvalid() {
    return this.email.invalid && (this.email.dirty || this.email.touched);
  }
  changeDate() {
    var param = {
      // custID: this.deviceName["custId"],
      deviceIds: [this.deviceId],
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    if (this.reportType === "Day") {
      this.selectedType = "Day";
      this.getDayReport(param);
    } else {
      this.selectedType = "Shift";
      this.getData(param);
    }
    console.log(param, "abcd");
  }
  ngOnDestroy() {}
  combinedData;
  tableData;
  showTable: boolean = false;
  getData(param) {
    console.log(param);
    // this.gattchart(param);
    this.toastr.warning("please wait while fetching record");
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/machine_report?code=WmMXTRUj25Oj61swNkmqR7aLa_fnyGUAPh5t5-hsWqkwAzFuhlrQdg==",
        param
      )
      .subscribe(
        (res) => {
          console.log(res);
          if (res["tabledata"].length) {
            this.showTable = true;
            this.tableData = res["tabledata"];
            console.log(this.tableData);
            this.graphData(this.tableData);
          } else {
            this.toastr.error("no record");
            this.totalPart = 0;
            this.currantAverage = 0;
            this.total_energy = 0;
            this.react_energy = 0;
            this.appa_energy = 0;
            this.showTable = false;
          }
        },
        (err) => {
          this.toastr.error("no record");
          this.showTable = false;
          console.log(err);
        }
      );
  }
  gattchart(param) {
    console.log(param);
    this.http
      .post(
        "https://k2-oee-reports.azurewebsites.net/api/gantt_chart_report?code=4WpjSD8iMoaTcvE_Lzw1wOFCTRwIR_BcLrovZAFTxRcbAzFu7cGVIw==",
        param
      )
      .subscribe((res) => {
        console.log(res);
        if (res["time"][0]?.length > 0) {
          // this.showPieChart = true;
          this.showChart = true;
          console.log(res, "ganttchart");
          // var outputData = res["chart_data"][0];
          if (res.hasOwnProperty("chart_data")) {
            var pieStatus = res["chart_data"][0];
            const outputData = pieStatus.map((item) => {
              var color;
              if (item.status === "dryrun") {
                color = "#ffff94";
              } else if (item.status === "running") {
                color = "#548237";
              } else if (item.status === "idle") {
                color = "#f7c030";
              } else if (item.status === "warning") {
                color = "#bfe0a5";
              } else if (item.status === "alarm") {
                color = "#ee4932";
              }
              return {
                value: item.duration,
                name: item.status,
                percent: item.percent,
                itemStyle: {
                  color: color,
                },
              };
            });
            // this.showPieChart = true;

            // console.log(res, outputData, "ganttchart");
            // this.donughnutChart = this.pieChart(outputData);
          }
          this.combinedData = res;
        }
      });
  }
  secondsToHms(d) {
    d = Number(d) * 60;
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? (h < 10 ? "0" + h : h) + ":" : "00:";
    const mDisplay = m > 0 ? (m < 10 ? "0" + m : m) + ":" : "00:";
    const sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
    return hDisplay + mDisplay + sDisplay;
  }
  openModal(templatepdf: any, data: any, act: any) {
    console.log(templatepdf, data, act, "modal");
    this.action = act;
    this.modalService.open(templatepdf, { centered: true });
    console.log(this.action, "action");
  }
  pdf() {
    this.pdfBool = true;
    this.csvBool = false;
  }
  csv() {
    this.csvBool = true;
    this.pdfBool = false;
  }
  table: any = [];
  base64String;
  convertImageToBase64() {
    const imagePath = "assets/wimera/Wimera_Logo_wSlogan_Green.png";
    this.http.get(imagePath, { responseType: "blob" }).subscribe(
      (data: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.base64String = reader.result as string;
        };
        reader.readAsDataURL(data);
      },
      (error) => {
        console.error("Error loading the image:", error);
      }
    );
  }
  format(value) {
    // var hours = Math.floor(value / 3600);
    // var minutes = Math.floor((value % 3600) / 60);  //to convert secs to hhmmss
    // var seconds = Math.floor(value % 60);
    var hours = Math.floor(value / 60);
    var remainingMinutes = Math.floor(value % 60); // to convert mins to hhmmss
    var seconds = Math.round((value - Math.floor(value)) * 60);
    var formattedValue =
      hours.toString().padStart(2, "0") +
      ":" +
      remainingMinutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return formattedValue;
  }
  machineStatusTable: any = [];
  statusData: any;
  download() {
    console.log(
      "download clicked",
      this.base64String,
      this.table,
      this.pdfBool,
      this.csvBool,
      this.exportViaMail
    );
    console.log(
      this.deviceName,
      this.selectedRange,
      this.fromDate,
      this.toDate,
      "query"
    );
    if (!this.pdfBool && !this.csvBool) this.pdfBool = true;
    var reportDate = momenttz.tz(this.timezone).format("DD-MM-YYYY HH:MM:ss");
    var today = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    if (this.pdfBool && this.base64String != undefined && !this.exportViaMail) {
      console.log("pdf export");
      this.statusData = [];
      this.machineStatusTable = [];
      var start;
      var startDate;
      var endDate;
      var yesterDay;
      if (this.selectedRange == "Yesterday") {
        // start = moment().startOf("day").subtract(1, "day").utc().format();
        // startDate = moment(start)
        //   .startOf("day")
        //   .add(1, "day")
        //   .utc()
        //   .format("DD-MM-YYYY 00:00:00");
        // endDate = moment(start)
        //   .startOf("day")
        //   .add(1, "day")
        //   .utc()
        //   .format("DD-MM-YYYY 23:59:59");
        yesterDay = moment(this.fromDate).format("MMMM-DD-YYYY");
        // yesterDay = moment(start).format("MMMM-DD-YYYY");
      } else {
        startDate = moment(this.fromDate).format("MMMM-DD-YYYY");
        endDate = moment(this.toDate).format("MMMM-DD-YYYY");
      }
      this.table = [];
      var pdfName;
      var year = moment(new Date()).format("YYYY");
      pdfName = "Machine_Reports " + reportDate;
      if (this.reportType == "Shift") {
        let response = JSON.parse(JSON.stringify(this.tableData));
        console.log(response, "res");
        let objectdata = {}
        objectdata['tabledata'] = response,
        objectdata['reportType'] = this.reportType
        objectdata["fromDate"] = moment(this.fromDate).format("MMMM-DD-YYYY"),
        objectdata["toDate"] = moment(this.toDate).format("MMMM-DD-YYYY"),
        this.dataservice.post("api/chartBase64", objectdata).subscribe(
          (res) => {
            this.chartDataURL = "data:image/png;base64," + res["barchart"];
            this.chartDataURLPie = "data:image/png;base64," + res["piechart"];
            this.statusData = res["statusData"];
            this.machineStatusTable.push([
              this.formattime(this.statusData.idle),
              this.formattime(this.statusData.running),
              this.formattime(this.statusData.dryrun),
              this.formattime(this.statusData.warning),
              this.formattime(this.statusData.alarm),
              // this.format(this.statusData.alarm),
            ]);
            for (let element of this.tableData) {
              this.table.push([
                element.date ? element.date : "N/A",
                element.shift_name ? element.shift_name : "-",
                element.current_avg ? element.current_avg : 0,
                // element.min,
                // element.max,
                element.perf ? element.perf : 0,
                element.quality ? element.quality : 0,
                element.avail ? element.avail : 0,
                element.oee ? element.oee : 0,
                element.idle ? this.formattime(element.idle) : 0,
                element.running ? this.formattime(element.running) : 0,
                element.dryrun ? this.formattime(element.dryrun) : 0,
                element.warning ? this.formattime(element.warning) : 0,
                // element.alarm ? this.format(element.alarm) : 0,
                element.alarm ? this.formattime(element.alarm) : 0,
                element.part_count ? element.part_count : 0,
                element.apparent_power ? element.apparent_power : 0,
                element.real_power ? element.real_power : 0,
                // element.energy_consumption ? element.energy_consumption : 0,
                element.total_energy ? element.total_energy : 0,
                // element.partproduced,
                // element.idle_hour,
                // element.running_hour,
                // element.settingup_time,
              ]);
            }
            var pdfObject;
            pdfObject = {
              pageOrientation: "landscape",
              header: function () {
                return [
                  {
                    text: today,
                    alignment: "right",
                    margin: [0, 5, 20, 0],
                    fontSize: 10,
                  },
                ];
              },
              footer: function (currentPage, pageCount) {
                return [
                  {
                    text: "Page " + currentPage.toString() + " of " + pageCount,
                    alignment: "center",
                    fontSize: 7,
                  },
                  {
                    text: "© " + year + " " + "Wimera Systems Pvt Ltd",
                    alignment: "right",
                    fontSize: 7,
                    margin: [5, 2, 10, 20],
                  },
                ];
              },
              content: [
                {
                  columns: [
                    {
                      image: `${this.base64String}`,
                      width: 140,
                      height: 45,
                      alignment: "left",
                      margin: [-30, -18, 0, 0],
                    },
                  ],
                },
                // {
                //   columns: [
                //     {
                //       text: "REPORTS FOR CUSTOMER " + this.custName,
                //       fontSize: 15,
                //       bold: true,
                //       alignment: "center",
                //       margin: [-55, -20, 0, 0],
                //     },
                //   ],
                // },
                {
                  columns: [
                    {
                      text: "MACHINE REPORTS",
                      fontSize: 15,
                      bold: true,
                      alignment: "center",
                      margin: [-60, -5, 0, 0],
                    },
                  ],
                },
                {
                  columns:
                    this.selectedRange == "Yesterday"
                      ? [
                          {
                            text: "Duration : " + yesterDay,
                            margin: [-60, 0, 0, 0],
                            fontSize: 10,
                            bold: true,
                            alignment: "center",
                          },
                        ]
                      : [
                          {
                            text:
                              "(Duration : From " +
                              startDate +
                              " to " +
                              endDate +
                              ")",
                            margin: [-60, 0, 0, 0],
                            fontSize: 10,
                            bold: true,
                            alignment: "center",
                          },
                        ],
                },
                {
                  canvas: [
                    {
                      type: "line",
                      lineColor: "#00ab41",
                      x1: -40,
                      y1: 0,
                      x2: 800,
                      y2: 0,
                      linewidth: 0.05,
                    },
                  ],
                  margin: [0, 0, 0, 10],
                },
                {
                  text: "CustomerName : " + this.custName,
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                {
                  text: "DeviceName : " + this.deviceName["deviceName"],
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                {
                  text: "DAYWISE PERFORMANCE",
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 20],
                },
                {
                  columns: [
                    {
                      image: this.chartDataURL,
                      width: 550,
                      height: 200,
                      margin: [50, 0, 0, 10],
                    },
                  ],
                },
                {
                  text: "DAYWISE MACHINE STATUS",
                  fontSize: 12,
                  bold: true,
                  margin: [10, 170, 0, 10],
                },
                {
                  columns: [
                    {
                      image: this.chartDataURLPie,
                      width: 300,
                      height: 200,
                      margin: [100, 0, 0, 30],
                      // alignment: "center",
                    },
                  ],
                },
                {
                  fontSize: 10,
                  margin: [450, -150, 0, 30],
                  table: {
                    headerRows: 1,
                    widths: "auto",
                    body: [
                      [
                        {
                          text: "Idle",
                          fillColor: "#f7c030",
                          bold: true,
                        },
                        {
                          text: "Running",
                          fillColor: "#548237",
                          bold: true,
                        },
                        {
                          text: "Dryrun",
                          fillColor: "#ffff94",
                          bold: true,
                        },
                        {
                          text: "Warning",
                          fillColor: "#bfe0a5",
                          bold: true,
                        },
                        {
                          text: "Alarm",
                          fillColor: "#ee4932",
                          bold: true,
                        },
                      ],
                      ...this.machineStatusTable,
                    ],
                  },
                  defaultStyle: {
                    font: "Roboto",
                  },
                },
                {
                  text: "SHIFTWISE",
                  fontSize: 12,
                  bold: true,
                  margin: [10, 120, 0, 10],
                },
                {
                  fontSize: 10,
                  margin: [10, 30, 0, 10],
                  table: {
                    headerRows: 1,
                    widths: "auto",
                    body: [
                      [
                        {
                          text: "Date",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "shift",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "current",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        // {
                        //   text: "min",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "max",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        {
                          text: "perf",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "quality",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "avail",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "oee",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "idle",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "running",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "dryrun",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "warning",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "alarm",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "partcount",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "apparentpower",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "realpower",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        // {
                        //   text: "e_consumption",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        {
                          text: "t_energy",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        // {
                        //   text: "partproduced",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "idle_hour",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "running_hour",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "settingup_time",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                      ],
                      ...this.table,
                    ],
                  },
                  defaultStyle: {
                    font: "Roboto",
                  },
                  layout: {
                    fillColor: function (rowIndex) {
                      return rowIndex % 2 == 0 ? "#abf7b1" : "#fafafa";
                    },
                    hLineWidth: function (i, node) {
                      return i === 0 || i === node.table.body.length
                        ? 0.1
                        : 0.1;
                    },
                    vLineWidth: function (i, node) {
                      return i === 0 || i === node.table.widths.length
                        ? 0.1
                        : 0.1;
                    },
                  },
                },
              ],
            };
            pdfMake.createPdf(pdfObject).download(pdfName + ".pdf");
          },
          (err) => {
            console.log(err.message, "get chart img error");
          }
        );
      } else if (this.reportType == "Day") {
        let response = JSON.parse(JSON.stringify(this.tableDayData));
        console.log(response, "resday");
        let finalresponse = response.map(
          ({
            PartitionKey,
            RowKey,
            avg_apparent_power,
            avg_avail,
            avg_current,
            avg_energy_consumption,
            avg_oee,
            avg_perf,
            avg_quality,
            avg_real_power,
            avg_total_energy,
            date,
            total_dryrun,
            total_idle,
            total_part_count,
            total_running,
            total_warning,
            total_alarm,
            ...res
          }) => ({
            PartitionKey: PartitionKey,
            RowKey: RowKey,
            apparent_power: avg_apparent_power,
            avail: avg_avail,
            current_avg: avg_current,
            date: date,
            energy_consumption: avg_energy_consumption,
            oee: avg_oee,
            perf: avg_perf,
            quality: avg_quality,
            real_power: avg_real_power,
            total_energy: avg_total_energy,
            dryrun: total_dryrun,
            idle: total_idle,
            part_count: total_part_count,
            running: total_running,
            warning: total_warning,
            alarm: total_alarm,
            ...res,
          })
        );
        let objectdata = {}
        objectdata['tabledata'] = finalresponse
        objectdata['reportType'] = this.reportType
        objectdata["fromDate"] = moment(this.fromDate).format("MMMM-DD-YYYY"),
        objectdata["toDate"] = moment(this.toDate).format("MMMM-DD-YYYY"),
        this.dataservice.post("api/chartBase64", objectdata).subscribe(
          (res) => {
            console.log(res, "resssday");
            this.chartDataURL = "data:image/png;base64," + res["barchart"];
            this.chartDataURLPie = "data:image/png;base64," + res["piechart"];
            this.statusData = res["statusData"];
            this.machineStatusTable.push([
              this.formattime(this.statusData.idle),
              this.formattime(this.statusData.running),
              this.formattime(this.statusData.dryrun),
              this.formattime(this.statusData.warning),
              // this.format(this.statusData.alarm),
              this.formattime(this.statusData.alarm),
            ]);
            for (let element of this.tableDayData) {
              this.table.push([
                element.date ? element.date : "N/A",
                // element.shift_name ? element.shift_name : "-",
                element.avg_current ? element.avg_current : 0,
                // element.min,
                // element.max,
                // element.avg_perf ? element.avg_perf : 0,
                // element.avg_quality ? element.avg_quality : 0,
                // element.avg_avail ? element.avg_avail : 0,
                element.avg_oee ? element.avg_oee : 0,
                element.total_idle ? this.formattime(element.total_idle) : 0,
                element.total_running ? this.formattime(element.total_running) : 0,
                element.total_dryrun ? this.formattime(element.total_dryrun) : 0,
                element.total_warning ? this.formattime(element.total_warning) : 0,
                element.total_alarm ? this.formattime(element.total_alarm) : 0,
                element.total_part_count ? element.total_part_count : 0,
                element.avg_apparent_power ? element.avg_apparent_power : 0,
                element.avg_real_power ? element.avg_real_power : 0,
                // element.energy_consumption ? element.energy_consumption : 0,
                element.avg_total_energy ? element.avg_total_energy : 0,
                element.power_unit_cost ? element.power_unit_cost : 0,
                element.total_prod_loss ? element.total_prod_loss : 0,
                element.prod_loss_hours ? this.formattime(element.prod_loss_hours) : 0,
                // element.partproduced,
                // element.idle_hour,
                // element.running_hour,
                // element.settingup_time,
              ]);
            }
            var pdfObject;
            pdfObject = {
              pageOrientation: "landscape",
              header: function () {
                return [
                  {
                    text: today,
                    alignment: "right",
                    margin: [0, 5, 20, 0],
                    fontSize: 10,
                  },
                ];
              },
              footer: function (currentPage, pageCount) {
                return [
                  {
                    text: "Page " + currentPage.toString() + " of " + pageCount,
                    alignment: "center",
                    fontSize: 7,
                  },
                  {
                    text: "© " + year + " " + "Wimera Systems Pvt Ltd",
                    alignment: "right",
                    fontSize: 7,
                    margin: [5, 2, 10, 20],
                  },
                ];
              },
              content: [
                {
                  columns: [
                    {
                      image: `${this.base64String}`,
                      width: 140,
                      height: 45,
                      alignment: "left",
                      margin: [-30, -18, 0, 0],
                    },
                  ],
                },
                // {
                //   columns: [
                //     {
                //       text: "REPORTS FOR CUSTOMER " + this.custName,
                //       fontSize: 15,
                //       bold: true,
                //       alignment: "center",
                //       margin: [-55, -20, 0, 0],
                //     },
                //   ],
                // },
                {
                  columns: [
                    {
                      text: "MACHINE REPORTS",
                      fontSize: 15,
                      bold: true,
                      alignment: "center",
                      margin: [-60, -5, 0, 0],
                    },
                  ],
                },
                {
                  columns:
                    this.selectedRange == "Yesterday"
                      ? [
                          {
                            text: "Duration : " + yesterDay,
                            margin: [-60, 0, 0, 0],
                            fontSize: 10,
                            bold: true,
                            alignment: "center",
                          },
                        ]
                      : [
                          {
                            text:
                              "(Duration : From " +
                              startDate +
                              " to " +
                              endDate +
                              ")",
                            margin: [-60, 0, 0, 0],
                            fontSize: 10,
                            bold: true,
                            alignment: "center",
                          },
                        ],
                },
                {
                  canvas: [
                    {
                      type: "line",
                      lineColor: "#00ab41",
                      x1: -40,
                      y1: 0,
                      x2: 800,
                      y2: 0,
                      linewidth: 0.05,
                    },
                  ],
                  margin: [0, 0, 0, 10],
                },
                {
                  text: "CustomerName : " + this.custName,
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                {
                  text: "DeviceName : " + this.deviceName["deviceName"],
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                {
                  text: "DAYWISE PERFORMANCE",
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 0, 20],
                },
                {
                  columns: [
                    {
                      image: this.chartDataURL,
                      width: 550,
                      height: 200,
                      margin: [50, 0, 0, 10],
                    },
                  ],
                },
                {
                  text: "DAYWISE MACHINE STATUS",
                  fontSize: 12,
                  bold: true,
                  margin: [10, 170, 0, 10],
                },
                {
                  columns: [
                    {
                      image: this.chartDataURLPie,
                      width: 300,
                      height: 200,
                      margin: [100, 0, 0, 30],
                      // alignment: "center",
                    },
                  ],
                },
                {
                  fontSize: 10,
                  margin: [450, -150, 0, 30],
                  table: {
                    headerRows: 1,
                    widths: "auto",
                    body: [
                      [
                        {
                          text: "Idle",
                          fillColor: "#f7c030",
                          bold: true,
                        },
                        {
                          text: "Running",
                          fillColor: "#548237",
                          bold: true,
                        },
                        {
                          text: "Dryrun",
                          fillColor: "#ffff94",
                          bold: true,
                        },
                        {
                          text: "Warning",
                          fillColor: "#bfe0a5",
                          bold: true,
                        },
                        {
                          text: "Alarm",
                          fillColor: "#ee4932",
                          bold: true,
                        },
                      ],
                      ...this.machineStatusTable,
                    ],
                  },
                  defaultStyle: {
                    font: "Roboto",
                  },
                },
                {
                  text: "DAYWISE",
                  fontSize: 12,
                  bold: true,
                  margin: [10, 120, 0, 10],
                },
                {
                  fontSize: 10,
                  margin: [10, 30, 0, 10],
                  table: {
                    headerRows: 1,
                    widths: "auto",
                    body: [
                      [
                        {
                          text: "Date",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        // {
                        //   text: "shift",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        {
                          text: "current",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        // {
                        //   text: "min",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "max",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "perf",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "quality",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "avail",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        {
                          text: "oee",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "idle",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "running",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "dryrun",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "warning",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "alarm",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "partcount",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "a_power",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "realpower",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        // {
                        //   text: "e_consumption",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        {
                          text: "t_energy",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "unit_cost",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "prod_loss",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        {
                          text: "prod_loss_hours",
                          fillColor: "#83f28f",
                          bold: true,
                        },
                        // {
                        //   text: "partproduced",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "idle_hour",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "running_hour",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                        // {
                        //   text: "settingup_time",
                        //   fillColor: "#83f28f",
                        //   bold: true,
                        // },
                      ],
                      ...this.table,
                    ],
                  },
                  defaultStyle: {
                    font: "Roboto",
                  },
                  layout: {
                    fillColor: function (rowIndex) {
                      return rowIndex % 2 == 0 ? "#abf7b1" : "#fafafa";
                    },
                    hLineWidth: function (i, node) {
                      return i === 0 || i === node.table.body.length
                        ? 0.1
                        : 0.1;
                    },
                    vLineWidth: function (i, node) {
                      return i === 0 || i === node.table.widths.length
                        ? 0.1
                        : 0.1;
                    },
                  },
                },
              ],
            };
            pdfMake.createPdf(pdfObject).download(pdfName + ".pdf");
          },
          (err) => {
            console.log(err.message, "get chart img error");
          }
        );
      }
    } else if (this.csvBool && !this.exportViaMail) {
      console.log("csv export");
      var res = [];
      var duration;
      var masterData = this.tableData;
      var masterDayData = this.tableDayData;
      var title = "Machine CSV Report";
      var customerName = ["Customer Name: " + this.custName, "\n"];
      var device = ["Device Name: " + this.deviceName["deviceName"], "\n"];
      if (this.selectedRange == "Yesterday") {
        // start = moment().startOf("day").subtract(1, "day").utc().format();
        yesterDay = moment(this.fromDate).format("MMMM-DD-YYYY");
        duration = "Duration: " + yesterDay;
      } else {
        startDate = moment(this.fromDate).format("MMMM-DD-YYYY");
        endDate = moment(this.toDate).format("MMMM-DD-YYYY");
        duration = "Duration: From " + startDate + " to " + endDate;
      }
      var details = [title, "\n"];
      var durationData = [duration, "\n"];
      var headersKey;
      headersKey = [
        "date",
        "shift_name",
        "current_avg",
        // "min",
        // "max",
        "perf",
        "quality",
        "avail",
        "oee",
        "idle",
        "running",
        "dryrun",
        "warning",
        "alarm",
        "part_count",
        "apparent_power",
        "real_power",
        // "energy_consumption",
        "total_energy",
        // "partproduced",
        // "idle_hour",
        // "running_hour",
        // "settingup_time",
      ];
      var headersDayKey;
      headersDayKey = [
        "date",
        "avg_current",
        // "min",
        // "max",
        // "avg_perf",
        // "avg_quality",
        // "avg_avail",
        "avg_oee",
        "total_idle",
        "total_running",
        "total_dryrun",
        "total_warning",
        "total_alarm",
        "total_part_count",
        "avg_apparent_power",
        "avg_real_power",
        // "energy_consumption",
        "avg_total_energy",
        "power_unit_cost",
        "total_prod_loss",
        "prod_loss_hours",
        // "partproduced",
        // "idle_hour",
        // "running_hour",
        // "settingup_time",
      ];
      res.push(details);
      res.push(durationData);
      res.push(customerName);
      res.push(device);
      // if(this.reportType == 'Shift'){
      // res.push(headersKey);
      // }else if(this.reportType == 'Day') {
      //   res.push(headersDayKey)
      // }
      let dataLocal = {};
      if (this.reportType == "Shift") {
        res.push(headersKey);
        for (let el in masterData) {
          for (let i = 0; i < headersKey.length; i++) {
            if (masterData[el][`${headersKey[i]}`] != undefined) {
              dataLocal[headersKey[i]] =
                masterData[el][`${headersKey[i]}`] === "N/A"
                  ? "No Data"
                  : masterData[el][`${headersKey[i]}`];
            } else {
              dataLocal[headersKey[i]] = 0;
            }
          }
          let tempData = Object.values(dataLocal);
          res.push([...tempData]);
          console.log(res, "shiftres");
        }
      } else if (this.reportType == "Day") {
        res.push(headersDayKey);
        for (let el in masterDayData) {
          for (let i = 0; i < headersDayKey.length; i++) {
            if (masterDayData[el][`${headersDayKey[i]}`] != undefined) {
              dataLocal[headersDayKey[i]] =
                masterDayData[el][`${headersDayKey[i]}`] === "N/A"
                  ? "No Data"
                  : masterDayData[el][`${headersDayKey[i]}`];
            } else {
              dataLocal[headersDayKey[i]] = 0;
            }
          }
          let tempData = Object.values(dataLocal);
          res.push([...tempData]);
          console.log(res, "resss");
        }
      }
      console.log(res, "resss");
      let downloadConfigration = {
        fileName: "Machine_Reports ",
        res: res,
      };
      var csvdata = downloadConfigration.res;
      let csvContent =
        "data:text/csv;charset=utf-8," +
        csvdata.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      var downloadDate = moment(new Date()).format("DD-MM-YYYY HH_mm_ss");
      link.setAttribute(
        "download",
        `${downloadConfigration.fileName}` + " " + downloadDate + ".csv"
      );
      document.body.appendChild(link);
      link.click();
    } else if (this.pdfBool && this.exportViaMail) {
      console.log("mailpdf export");
      let masterData = this.tableData;
      let masterDayData = this.tableDayData;
      let masterResult = [];
      if (this.reportType == "Shift") {
        for (let el in masterData) {
          masterResult.push({
            date: masterData[el].date ? masterData[el].date : "N/A",
            shift_name: masterData[el].shift_name
              ? masterData[el].shift_name
              : "-",
            current_avg: masterData[el].current_avg
              ? masterData[el].current_avg
              : 0,
            // min: masterData[el].min,
            // max: masterData[el].max,
            perf: masterData[el].perf ? masterData[el].perf : 0,
            quality: masterData[el].quality ? masterData[el].quality : 0,
            avail: masterData[el].avail ? masterData[el].avail : 0,
            oee: masterData[el].oee ? masterData[el].oee : 0,
            idle: masterData[el].idle ? masterData[el].idle : 0,
            running: masterData[el].running ? masterData[el].running : 0,
            dryrun: masterData[el].dryrun ? masterData[el].dryrun : 0,
            warning: masterData[el].warning ? masterData[el].warning : 0,
            alarm: masterData[el].alarm ? masterData[el].alarm : 0,
            part_count: masterData[el].part_count
              ? masterData[el].part_count
              : 0,
            apparent_power: masterData[el].apparent_power
              ? masterData[el].apparent_power
              : 0,
            real_power: masterData[el].real_power
              ? masterData[el].real_power
              : 0,
            // energy_consumption:masterData[el].energy_consumption ? masterData[el].energy_consumption : 0,
            total_energy: masterData[el].total_energy
              ? masterData[el].total_energy
              : 0,

            // partproduced: masterData[el].partproduced,
            // idle_hour: masterData[el].idle_hour,
            // running_hour: masterData[el].running_hour,
            // settingup_time: masterData[el].settingup_time,
          });
        }
      } else if (this.reportType == "Day") {
        for (let el in masterDayData) {
          masterResult.push({
            date: masterDayData[el].date ? masterDayData[el].date : "N/A",
            // shift_name: masterDayData[el].shift_name
            //   ? masterDayData[el].shift_name
            //   : "-",
            current_avg: masterDayData[el].avg_current
              ? masterDayData[el].avg_current
              : 0,
            // min: masterDayData[el].min,
            // max: masterDayData[el].max,
            perf: masterDayData[el].avg_perf ? masterDayData[el].avg_perf : 0,
            quality: masterDayData[el].avg_quality
              ? masterDayData[el].avg_quality
              : 0,
            avail: masterDayData[el].avg_avail
              ? masterDayData[el].avg_avail
              : 0,
            oee: masterDayData[el].avg_oee ? masterDayData[el].avg_oee : 0,
            idle: masterDayData[el].total_idle
              ? masterDayData[el].total_idle
              : 0,
            running: masterDayData[el].total_running
              ? masterDayData[el].total_running
              : 0,
            dryrun: masterDayData[el].total_dryrun
              ? masterDayData[el].total_dryrun
              : 0,
            warning: masterDayData[el].total_warning
              ? masterDayData[el].total_warning
              : 0,
            alarm: masterDayData[el].total_alarm
              ? masterDayData[el].total_alarm
              : 0,
            part_count: masterDayData[el].total_part_count
              ? masterDayData[el].total_part_count
              : 0,
            apparent_power: masterDayData[el].avg_apparent_power
              ? masterDayData[el].avg_apparent_power
              : 0,
            real_power: masterDayData[el].avg_real_power
              ? masterDayData[el].avg_real_power
              : 0,
            // energy_consumption:masterDayData[el].energy_consumption ? masterDayData[el].energy_consumption : 0,
            total_energy: masterDayData[el].avg_total_energy
              ? masterDayData[el].avg_total_energy
              : 0,
            power_unit_cost: masterDayData[el].power_unit_cost
              ? masterDayData[el].power_unit_cost
              : 0,
            total_prod_loss: masterDayData[el].total_prod_loss
              ? masterDayData[el].total_prod_loss
              : 0,
            prod_loss_hours: masterDayData[el].prod_loss_hours
              ? masterDayData[el].prod_loss_hours
              : 0,
            // partproduced: masterDayData[el].partproduced,
            // idle_hour: masterDayData[el].idle_hour,
            // running_hour: masterDayData[el].running_hour,
            // settingup_time: masterDayData[el].settingup_time,
          });
        }
      }
      let data = this.mailform.value;
      let maildata = {};
      (maildata["to"] = data["email"]),
        (maildata["tabledata"] = masterResult),
        (maildata["range"] = this.selectedRange),
        (maildata["reportname"] = "Machine Reports"),
        (maildata["reportDate"] = today),
        (maildata["fromDate"] = moment(this.fromDate).format("MMMM-DD-YYYY")),
        (maildata["toDate"] = moment(this.toDate).format("MMMM-DD-YYYY")),
        (maildata["customerName"] = this.custName),
        (maildata["deviceName"] = this.deviceName["deviceName"]),
        (maildata["reportType"] = this.reportType),
        (maildata["filetype"] = "pdf");
      this.toastr.info("please wait while processing");
      this.dataservice.post("api/sendMail", maildata).subscribe(
        (res) => {
          this.toastr.success("Mail sent successfully!");
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
      this.exportViaMail = false;
    } else if (this.csvBool && this.exportViaMail) {
      console.log("csvmail export", this.tableData);
      let masterData = this.tableData;
      let masterDayData = this.tableDayData;
      let masterResult = [];
      if (this.reportType == "Shift") {
        for (let el in masterData) {
          masterResult.push({
            date: masterData[el].date ? masterData[el].date : "N/A",
            shift_name: masterData[el].shift_name
              ? masterData[el].shift_name
              : "-",
            current_avg: masterData[el].current_avg
              ? masterData[el].current_avg
              : 0,
            // min: masterData[el].min,
            // max: masterData[el].max,
            perf: masterData[el].perf ? masterData[el].perf : 0,
            quality: masterData[el].quality ? masterData[el].quality : 0,
            avail: masterData[el].avail ? masterData[el].avail : 0,
            oee: masterData[el].oee ? masterData[el].oee : 0,
            idle: masterData[el].idle ? masterData[el].idle : 0,
            running: masterData[el].running ? masterData[el].running : 0,
            dryrun: masterData[el].dryrun ? masterData[el].dryrun : 0,
            warning: masterData[el].warning ? masterData[el].warning : 0,
            alarm: masterData[el].alarm ? masterData[el].alarm : 0,
            part_count: masterData[el].part_count
              ? masterData[el].part_count
              : 0,
            apparent_power: masterData[el].apparent_power
              ? masterData[el].apparent_power
              : 0,
            real_power: masterData[el].real_power
              ? masterData[el].real_power
              : 0,
            // energy_consumption:masterData[el].energy_consumption ? masterData[el].energy_consumption : 0,
            total_energy: masterData[el].total_energy
              ? masterData[el].total_energy
              : 0,
            // partproduced: masterData[el].partproduced,
            // idle_hour: masterData[el].idle_hour,
            // running_hour: masterData[el].running_hour,
            // settingup_time: masterData[el].settingup_time,
          });
        }
      } else if (this.reportType == "Day") {
        for (let el in masterDayData) {
          masterResult.push({
            date: masterDayData[el].date ? masterDayData[el].date : "N/A",
            current_avg: masterDayData[el].avg_current
              ? masterDayData[el].avg_current
              : 0,
            // min: masterDayData[el].min,
            // max: masterDayData[el].max,
            perf: masterDayData[el].avg_perf ? masterDayData[el].avg_perf : 0,
            quality: masterDayData[el].avg_quality
              ? masterDayData[el].avg_quality
              : 0,
            avail: masterDayData[el].avg_avail
              ? masterDayData[el].avg_avail
              : 0,
            oee: masterDayData[el].avg_oee ? masterDayData[el].avg_oee : 0,
            idle: masterDayData[el].total_idle
              ? masterDayData[el].total_idle
              : 0,
            running: masterDayData[el].total_running
              ? masterDayData[el].total_running
              : 0,
            dryrun: masterDayData[el].total_dryrun
              ? masterDayData[el].total_dryrun
              : 0,
            warning: masterDayData[el].total_warning
              ? masterDayData[el].total_warning
              : 0,
            alarm: masterDayData[el].total_alarm
              ? masterDayData[el].total_alarm
              : 0,
            part_count: masterDayData[el].total_part_count
              ? masterDayData[el].total_part_count
              : 0,
            apparent_power: masterDayData[el].avg_apparent_power
              ? masterDayData[el].avg_apparent_power
              : 0,
            real_power: masterDayData[el].avg_real_power
              ? masterDayData[el].avg_real_power
              : 0,
            // energy_consumption:masterDayData[el].energy_consumption ? masterDayData[el].energy_consumption : 0,
            total_energy: masterDayData[el].avg_total_energy
              ? masterDayData[el].avg_total_energy
              : 0,
            power_unit_cost: masterDayData[el].power_unit_cost
              ? masterDayData[el].power_unit_cost
              : 0,
            total_prod_loss: masterDayData[el].total_prod_loss
              ? masterDayData[el].total_prod_loss
              : 0,
            prod_loss_hours: masterDayData[el].prod_loss_hours
              ? masterDayData[el].prod_loss_hours
              : 0,
            // partproduced: masterDayData[el].partproduced,
            // idle_hour: masterDayData[el].idle_hour,
            // running_hour: masterDayData[el].running_hour,
            // settingup_time: masterDayData[el].settingup_time,
          });
        }
      }
      let data = this.mailform.value;
      let maildata = {};
      (maildata["to"] = data["email"]),
        (maildata["tabledata"] = masterResult),
        (maildata["reportname"] = "Machine Reports"),
        (maildata["range"] = this.selectedRange),
        (maildata["fromDate"] = moment(this.fromDate).format("MMMM-DD-YYYY")),
        (maildata["toDate"] = moment(this.toDate).format("MMMM-DD-YYYY")),
        (maildata["customerName"] = this.custName),
        (maildata["deviceName"] = this.deviceName["deviceName"]),
        (maildata["reportType"] = this.reportType),
        (maildata["filetype"] = "csv");
      this.dataservice.post("api/sendMail", maildata).subscribe(
        (res) => {
          this.toastr.success("Mail sent successfully!");
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
    }
    this.pdfBool = false;
    this.csvBool = false;
    this.mailform.reset();
  }

  cancel() {
    this.pdfBool = false;
    this.csvBool = false;
    this.mailform.reset();
    this.exportViaMail = false;
  }
  exportViaMail: boolean = false;
  mailexport() {
    this.pdfBool = false;
    this.csvBool = false;
    this.exportViaMail = true;
    console.log(this.exportViaMail, this.pdfBool, this.csvBool, "eee");
  }
  directExpo() {
    this.exportViaMail = false;
    this.pdfBool = false;
    this.csvBool = false;
    this.mailform.reset();
  }
  filteredData: any = [];
  diff_dates(date1,date2){
    const start:any = new Date(date1);
    const end:any = new Date(date2);
    const differenceInMilliseconds = Math.abs(end - start);
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    
    console.log("Difference in days:", differenceInDays);
    return differenceInDays
  }
  graphData(data) {
    console.log(data);

    // this.oeearray(data);
    const uniqueDates = new Set();
    this.filteredData = [];
    const uniqueShiftNames = new Set();
    this.shift_list = [];
    const oeeByShift = {};
    const perfShift = {};
    const qualityShift = {};
    const availShift = {};
    let differncedates = this.diff_dates(this.fromDate,this.toDate)
    // Filter out duplicate dates and store them in uniqueDates set
    data.forEach((entry) => {
      if (!uniqueDates.has(entry.date)) {
        uniqueDates.add(entry.date);
        // if(this.fromDate == this.toDate) {
        this.filteredData.push(entry.date);
        // }
      }
      // });

      // data.forEach((entry) => {
      uniqueShiftNames.add(entry.shift_name);
      this.shift_list.push(entry.shift_name);
    });
    // if(this.fromDate != this.toDate) {
    // let dates = `${data[0].date} - ${data[data.length].date}`
    // console.log(dates,"dates")
    // this.filteredData.push(dates)
    // }

    data.forEach((entry) => {
      const oee = `${entry.shift_name}_oee`;
      if (!oeeByShift[oee]) {
        oeeByShift[oee] = [];
      }
      oeeByShift[oee].push(parseFloat(entry.oee));
      // });
      // data.forEach((entry) => {
      const perf = `${entry.shift_name}_perf`;
      if (!perfShift[perf]) {
        perfShift[perf] = [];
      }
      perfShift[perf].push(parseFloat(entry.perf));
      // });
      // data.forEach((entry) => {
      const qual = `${entry.shift_name}_quality`;
      if (!qualityShift[qual]) {
        qualityShift[qual] = [];
      }
      qualityShift[qual].push(parseFloat(entry.quality));
      // });
      // data.forEach((entry) => {
      const avail = `${entry.shift_name}_avail`;
      if (!availShift[avail]) {
        availShift[avail] = [];
      }
      availShift[avail].push(parseFloat(entry.avail));
    });

    this.seriesdata = [];
    // let avail_color = ["#08de70", "#21f789", "#52f9a3", "#84fbbd"];
    let avail_color = "#08de70"
    const gradientColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: "rgba(0, 0, 0, 0.8)" }, // Start color
      { offset: 1, color: "rgba(72, 250, 0, 0.6)" }, // End color
    ]);
    let sum = 0
    if(this.fromDate == this.toDate) {
      Object.keys(availShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "avail",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: avail_color[index],
          color: avail_color
        },
        data: availShift[x],
        // tooltip: {
        //   // Custom tooltip settings for this series
        //   trigger: "axis", // Trigger type
        //   formatter: "{b} <br/>{a}: {c}", // Tooltip content format

        // },
      });
      });
    }else{
         this.filteredData = []
         this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
         console.log(this.filteredData,"filteredData")
         let keys = Object.keys(availShift); 
         keys.map((x, index) => {
            let sum = availShift[x].reduce((acc, cur) => acc + cur, 0);
            let average:any = sum / differncedates;
            average = average.toFixed(2)
            console.log(average,"average")
            this.seriesdata.push({
               name: x,
               type: "bar",
               emphasis: {
                 focus: "series",
               },
               label: {
                 show: true,
                 formatter: "{c}",
                 position: 'top'
               },
               itemStyle: {
                 color: avail_color, 
               },
               data: [average],
            });
            console.log(this.seriesdata,"seriesdata")
        });
    }
    let perf = [];
    // let perf_color = ["#08debb", "#21f7d4", "#52f9de", "#84fbe7"];
    let perf_color = "#08debb"
    if(this.fromDate == this.toDate) { 
    Object.keys(perfShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "perf",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: perf_color[index],
          color: perf_color
        },
        data: perfShift[x],
      });
    });
    }else{
      this.filteredData = []
      this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
      console.log(this.filteredData,"filteredData")
    let keys = Object.keys(perfShift); 
    keys.map((x, index) => {
       let sum = perfShift[x].reduce((acc, cur) => acc + cur, 0);
       let average:any = sum / differncedates;
       average = average.toFixed(2)
       this.seriesdata.push({
          name: x,
          type: "bar",
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
            formatter: "{c}",
            position: 'top'
          },
          itemStyle: {
            color: perf_color, 
          },
          data: [average],
       });
   });
    }
    let qualty = [];
    // let quality_color = ["#0865de", "#217ef7", "#529bf9", "#84b8fb"];
    let quality_color = "#0865de"
    if(this.fromDate == this.toDate) {
    Object.keys(qualityShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "qual",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: quality_color[index],
          color: quality_color
        },
        data: qualityShift[x],
        // tooltip: {
        //   // Custom tooltip settings for this series
        //   trigger: "axis", // Trigger type
        //   formatter: "{b} <br/>{a}: {c}", // Tooltip content format

        // },
      });
    });
    }else{
      this.filteredData = []
      this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
      console.log(this.filteredData,"filteredData")
    let keys = Object.keys(qualityShift); 
    keys.map((x, index) => {
       let sum = qualityShift[x].reduce((acc, cur) => acc + cur, 0);
       let average:any = sum / differncedates;
       average = average.toFixed(2)
       this.seriesdata.push({
          name: x,
          type: "bar",
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
            formatter: "{c}",
            position: 'top'
          },
          itemStyle: {
            color: quality_color, 
          },
          data: [average],
       });
       console.log(this.seriesdata,"seriesdata")
    });
    }
    let oee = [];
    // let oee_color = ["#de7a08", "#f79421", "#f9ac52", "#fbc384"];
    let oee_color = "#de7a08"
    if(this.fromDate == this.toDate) {
    Object.keys(oeeByShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "Ad",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: oee_color[index],
          color: oee_color
        },
        data: oeeByShift[x],
        // tooltip: {
        //   // Custom tooltip settings for this series
        //   trigger: "axis", // Trigger type
        //   formatter: "{b} <br/>{a}: {c}", // Tooltip content format

        // },
      });
    });
    }else{
      this.filteredData = []
      this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
      console.log(this.filteredData,"filteredData")
    let keys = Object.keys(oeeByShift); 
    keys.map((x, index) => {
       let sum = oeeByShift[x].reduce((acc, cur) => acc + cur, 0);
       let average:any = sum / differncedates;
       average = average.toFixed(2)
       this.seriesdata.push({
          name: x,
          type: "bar",
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
            formatter: "{c}",
            position: 'top'
          },
          itemStyle: {
            color: oee_color, 
          },
          data: [average],
       });
      });
    }
    this.runningdata(data);
    console.log(availShift, this.seriesdata);
    console.log(perfShift);
    console.log(qualityShift);
    console.log(oeeByShift);
    console.log(this.shift_list);
    console.log(this.filteredData);
  }

  runningdata(dataArray) {
    const runningByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.running;
      return acc;
    }, {});
    const dryrunByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.dryrun || 0;
      return acc;
    }, {});
    const idleByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.idle || 0;
      return acc;
    }, {});
    const warningByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.warning || 0;
      return acc;
    }, {});
    const alarmByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.alarm || 0;
      return acc;
    }, {});
    const TotalPartsDate = dataArray.reduce((acc, item) => {
      const partCount = parseFloat(item.part_count) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += partCount;
      return acc;
    }, {});
    const TotalCurrentDate = dataArray.reduce((acc, item) => {
      const avecurrent = parseFloat(item.current_avg) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += avecurrent;
      return acc;
    }, {});
    const apparent_power_Date = dataArray.reduce((acc, item) => {
      const apparent_power = parseFloat(item.apparent_power) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += apparent_power;
      return acc;
    }, {});
    const energy_consumption_Date = dataArray.reduce((acc, item) => {
      const energy_consumption = parseFloat(item.energy_consumption) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += energy_consumption;
      return acc;
    }, {});
    const real_power_Date = dataArray.reduce((acc, item) => {
      const real_power = parseFloat(item.real_power) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += real_power;
      return acc;
    }, {});
    const total_energy_Date = dataArray.reduce((acc, item) => {
      const total_energy = parseFloat(item.total_energy) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += total_energy;
      return acc;
    }, {});
    // Extract dates and running sums for all shifts
    const dates = Object.keys(runningByDate);
    const runningSums = dates.map((date) => runningByDate[date]);
    const idleSums = dates.map((date) => idleByDate[date]);
    const dryrunSums = dates.map((date) => dryrunByDate[date]);
    const warningSums = dates.map((date) => warningByDate[date]);
    const alarmSums = dates.map((date) => alarmByDate[date]);
    const partSum = dates.map((date) => TotalPartsDate[date]);
    this.totalPart = partSum.reduce((total, value) => total + value, 0);
    const currentSum = dates.map((date) => TotalCurrentDate[date]);
    const apparent_powerSum = dates.map((date) => apparent_power_Date[date]);

    const energy_consumptionSum = dates.map(
      (date) => energy_consumption_Date[date]
    );
    const real_powerSum = dates.map((date) => real_power_Date[date]);
    const total_energySum = dates.map((date) => total_energy_Date[date]);
    this.total_energy = this.average(total_energySum).toFixed(2);
    this.react_energy = this.average(real_powerSum).toFixed(2);
    this.appa_energy = this.average(apparent_powerSum).toFixed(2);

    // const sum = currentSum.reduce((total, value) => total + value, 0);
    // var c_average = sum / currentSum.length;
    this.currantAverage = this.average(currentSum).toFixed(2);

    this.statusGraph = {
      date: dates,
      Running: runningSums,
      Idle: idleSums,
      DryRun: dryrunSums,
      Warning: warningSums,
      Alarm: alarmSums,
    };
    console.log({
      date: dates,
      Running: runningSums,
      Idle: idleSums,
      DryRun: dryrunSums,
      Warning: warningSums,
      Alarm: alarmSums,
      parts: partSum,
    });
  }
  average(data) {
    const sum = data.reduce((total, value) => total + value, 0);
    return sum / data.length;
  }

  select_Report(eve) {
    console.log(eve, "eveee");
    if (eve === "Day") {
    }
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  getDayReport(param) {
    console.log(param, "dayreport param");
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/daily_report?code=aEORtox8O5lhx3xbed539cPrWgfKqcv-0CqepLnxtisbAzFusYvC4w==",
        param
      )
      .subscribe(
        (res) => {
          console.log(res);
          if (res["tabledata"].length) {
            this.showTable = true;
            this.tableDayData = res["tabledata"];
            console.log(this.tableDayData);
            this.graphDayData(this.tableDayData);
          } else {
            this.toastr.error("no record");
            this.totalPart = 0;
            this.currantAverage = 0;
            this.total_energy = 0;
            this.react_energy = 0;
            this.appa_energy = 0;
            this.showTable = false;
          }
        },
        (err) => {
          this.toastr.error("no record");
          this.showTable = false;
          console.log(err);
        }
      );
  }

  graphDayData(data) {
    console.log(data);

    // this.oeearray(data);
    const uniqueDates = new Set();
    this.filteredData = [];
    const uniqueShiftNames = new Set();
    this.shift_list = [];
    const oeeByShift = {};
    const perfShift = {};
    const qualityShift = {};
    const availShift = {};
    let differncedates = this.diff_dates(this.fromDate,this.toDate)
    // Filter out duplicate dates and store them in uniqueDates set
    data.forEach((entry) => {
      if (!uniqueDates.has(entry.date)) {
        uniqueDates.add(entry.date);
        // if(this.fromDate == this.toDate) {
        this.filteredData.push(entry.date);
        // }
      }
      // });

      // data.forEach((entry) => {
      // uniqueShiftNames.add(entry.shift_name);
      // this.shift_list.push(entry.shift_name);
    });
    // if(this.fromDate !== this.toDate) {
    // let dates = `${data[0].date} - ${data[data.length].date}`
    // console.log(dates,"dates")
    // this.filteredData.push(dates)
    // }
    data.forEach((entry) => {
      const oee = `Day_avg_oee`;
      if (!oeeByShift[oee]) {
        oeeByShift[oee] = [];
      }
      oeeByShift[oee].push(parseFloat(entry.avg_oee));
      // });
      // data.forEach((entry) => {
      const perf = `Day_avg_perf`;
      if (!perfShift[perf]) {
        perfShift[perf] = [];
      }
      perfShift[perf].push(parseFloat(entry.avg_perf));
      // });
      // data.forEach((entry) => {
      const qual = `Day_avg_quality`;
      if (!qualityShift[qual]) {
        qualityShift[qual] = [];
      }
      qualityShift[qual].push(parseFloat(entry.avg_quality));
      // });
      // data.forEach((entry) => {
      const avail = `Day_avg_avail`;
      if (!availShift[avail]) {
        availShift[avail] = [];
      }
      availShift[avail].push(parseFloat(entry.avg_avail));
    });
    this.seriesdata = [];
    // let avail_color = ["#08de70", "#21f789", "#52f9a3", "#84fbbd"];
    let avail_color = "#08de70"
    const gradientColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: "rgba(0, 0, 0, 0.8)" }, // Start color
      { offset: 1, color: "rgba(72, 250, 0, 0.6)" }, // End color
    ]);
    if(this.fromDate == this.toDate) {
    Object.keys(availShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "avail",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: avail_color[index],
          color: avail_color
        },
        data: availShift[x],
        // tooltip: {
        //   // Custom tooltip settings for this series
        //   trigger: "axis", // Trigger type
        //   formatter: "{b} <br/>{a}: {c}", // Tooltip content format

        // },
      });
    });
    }else{
      this.filteredData = []
      this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
      console.log(this.filteredData,"filteredData")
      let keys = Object.keys(availShift); 
      keys.map((x, index) => {
       let sum = availShift[x].reduce((acc, cur) => acc + cur, 0);
       let average:any = sum / differncedates;
       average = average.toFixed(2)
       this.seriesdata.push({
          name: x,
          type: "bar",
          emphasis: {
            focus: "series",
          },
          label: {
            show: true,
            formatter: "{c}",
            position: 'top'
          },
          itemStyle: {
            color: avail_color, 
          },
          data: [average],
       });
      });
    }
    let perf = [];
    // let perf_color = ["#08debb", "#21f7d4", "#52f9de", "#84fbe7"];
    let perf_color = "#08debb"
   if(this.fromDate == this.toDate) { 
    Object.keys(perfShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "perf",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: perf_color[index],
          color: perf_color
        },
        data: perfShift[x],
      });
    });
   }else{
    this.filteredData = []
    this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
    console.log(this.filteredData,"filteredData")
    let keys = Object.keys(perfShift); 
    keys.map((x, index) => {
     let sum = perfShift[x].reduce((acc, cur) => acc + cur, 0);
     let average:any = sum / differncedates;
     average = average.toFixed(2)
     this.seriesdata.push({
        name: x,
        type: "bar",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          color: perf_color, 
        },
        data: [average],
     });
    });
   }
    let qualty = [];
    // let quality_color = ["#0865de", "#217ef7", "#529bf9", "#84b8fb"];
    let quality_color = "#0865de"
   if(this.fromDate == this.toDate) { 
    Object.keys(qualityShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "qual",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: quality_color[index],
          color: quality_color
        },
        data: qualityShift[x],
        // tooltip: {
        //   // Custom tooltip settings for this series
        //   trigger: "axis", // Trigger type
        //   formatter: "{b} <br/>{a}: {c}", // Tooltip content format

        // },
      });
    });
   }else{
    this.filteredData = []
    this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
    console.log(this.filteredData,"filteredData")
    let keys = Object.keys(qualityShift); 
    keys.map((x, index) => {
     let sum = qualityShift[x].reduce((acc, cur) => acc + cur, 0);
     let average:any = sum / differncedates;
     average = average.toFixed(2)
     this.seriesdata.push({
        name: x,
        type: "bar",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          color: quality_color, 
        },
        data: [average],
     });
    });
   }
    let oee = [];
    // let oee_color = ["#de7a08", "#f79421", "#f9ac52", "#fbc384"];
    let oee_color = "#de7a08"
   if(this.fromDate == this.toDate) { 
    Object.keys(oeeByShift).map((x, index) => {
      this.seriesdata.push({
        name: x,
        type: "bar",
        // stack: "Ad",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          // color: oee_color[index],
          color: oee_color
        },
        data: oeeByShift[x],
        // tooltip: {
        //   // Custom tooltip settings for this series
        //   trigger: "axis", // Trigger type
        //   formatter: "{b} <br/>{a}: {c}", // Tooltip content format

        // },
      });
    });
   }else{
    this.filteredData = []
    this.filteredData.push(`${this.fromDate} - ${this.toDate}`)
    console.log(this.filteredData,"filteredData")
    let keys = Object.keys(oeeByShift); 
    keys.map((x, index) => {
     let sum = oeeByShift[x].reduce((acc, cur) => acc + cur, 0);
     let average:any = sum / differncedates;
     average = average.toFixed(2)
     this.seriesdata.push({
        name: x,
        type: "bar",
        emphasis: {
          focus: "series",
        },
        label: {
          show: true,
          formatter: "{c}",
          position: 'top'
        },
        itemStyle: {
          color: oee_color, 
        },
        data: [average],
     });
    });
    }
    this.runningdaydata(data);
    console.log(availShift, this.seriesdata);
    console.log(perfShift);
    console.log(qualityShift);
    console.log(oeeByShift);
    console.log(this.shift_list);
    console.log(this.filteredData);
  }

  runningdaydata(dataArray) {
    const runningByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.total_running;
      return acc;
    }, {});
    const dryrunByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.total_dryrun || 0;
      return acc;
    }, {});
    const idleByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.total_idle || 0;
      return acc;
    }, {});
    const warningByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.total_warning || 0;
      return acc;
    }, {});
    const alarmByDate = dataArray.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += item.total_alarm || 0;
      return acc;
    }, {});
    const TotalPartsDate = dataArray.reduce((acc, item) => {
      const partCount = parseFloat(item.total_part_count) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += partCount;
      return acc;
    }, {});
    const TotalCurrentDate = dataArray.reduce((acc, item) => {
      const avecurrent = parseFloat(item.avg_current) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += avecurrent;
      return acc;
    }, {});
    const apparent_power_Date = dataArray.reduce((acc, item) => {
      const apparent_power = parseFloat(item.avg_apparent_power) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += apparent_power;
      return acc;
    }, {});
    const energy_consumption_Date = dataArray.reduce((acc, item) => {
      const energy_consumption = parseFloat(item.avg_energy_consumption) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += energy_consumption;
      return acc;
    }, {});
    const real_power_Date = dataArray.reduce((acc, item) => {
      const real_power = parseFloat(item.avg_real_power) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += real_power;
      return acc;
    }, {});
    const total_energy_Date = dataArray.reduce((acc, item) => {
      const total_energy = parseFloat(item.avg_total_energy) || 0;
      if (!acc[item.date]) {
        acc[item.date] = 0;
      }
      acc[item.date] += total_energy;
      return acc;
    }, {});
    // Extract dates and running sums for all shifts
    const dates = Object.keys(runningByDate);
    const runningSums = dates.map((date) => runningByDate[date]);
    const idleSums = dates.map((date) => idleByDate[date]);
    const dryrunSums = dates.map((date) => dryrunByDate[date]);
    const warningSums = dates.map((date) => warningByDate[date]);
    const alarmSums = dates.map((date) => alarmByDate[date]);
    const partSum = dates.map((date) => TotalPartsDate[date]);
    this.totalPart = partSum.reduce((total, value) => total + value, 0);
    const currentSum = dates.map((date) => TotalCurrentDate[date]);
    const apparent_powerSum = dates.map((date) => apparent_power_Date[date]);

    const energy_consumptionSum = dates.map(
      (date) => energy_consumption_Date[date]
    );
    const real_powerSum = dates.map((date) => real_power_Date[date]);
    const total_energySum = dates.map((date) => total_energy_Date[date]);
    this.total_energy = this.average(total_energySum).toFixed(2);
    this.react_energy = this.average(real_powerSum).toFixed(2);
    this.appa_energy = this.average(apparent_powerSum).toFixed(2);

    // const sum = currentSum.reduce((total, value) => total + value, 0);
    // var c_average = sum / currentSum.length;
    this.currantAverage = this.average(currentSum).toFixed(2);

    this.statusGraph = {
      date: dates,
      Running: runningSums,
      Idle: idleSums,
      DryRun: dryrunSums,
      Warning: warningSums,
      Alarm: alarmSums,
    };
    console.log({
      date: dates,
      Running: runningSums,
      Idle: idleSums,
      DryRun: dryrunSums,
      Warning: warningSums,
      Alarm: alarmSums,
      parts: partSum,
    });
  }

  formattime(time){
    console.log(time,"time")
    const hours = Math.floor(time / 60);
    const minutes = Math.floor(time % 60);
    const seconds = Math.round((time * 60) % 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }  
}
