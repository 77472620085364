import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { RecordSampleComponent } from 'src/app/pages/records/recordsample.component';

@Injectable({
  providedIn: 'root'
})
export class RecordSampleGuardService implements CanDeactivate<RecordSampleComponent> {
  canDeactivate(component: RecordSampleComponent): Observable<boolean> | Promise<boolean> | boolean {
    console.log(component,"component")
    return component.canDeactivate();
  }
}

