import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";

import * as echarts from "echarts";
import * as moment from "moment-timezone";

@Component({
  selector: "app-combinechart",
  templateUrl: "./combinechart.component.html",
  styleUrls: ["./combinechart.component.scss"],
})

/**
 * E-chart component
 */
export class CombinechartComponent implements OnInit, OnChanges {
  @Input() gaugeArray: any;

  constructor(private elementRef: ElementRef) {}
  data = {
    timeData: [
      "15:02:00",
      "15:03:00",
      "15:04:00",
      "15:05:00",
      "15:06:00",
      "15:07:00",
      "15:08:00",
      "15:09:00",
      "15:10:00",
      "15:11:00",
      "17:00:00",
      "17:05:00",
      "17:06:00",
      "17:07:00",
    ],
    // parts: [2,, 2, 2, 2];

    status: [1, 0, 1, 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    current: [10, 55, 3, 46, 24, 666, 3, 56, 4, 33, 32, 25, 41, 36],
    parts: [22, 11, 3, 623, 47, 666, 48, 56, 4, 68, 5, 28, 1, 15],
  };
  echartsInstance: any;

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Charts" },
      { label: "E - Chart", active: true },
    ];
    console.log(this.gaugeArray, "combined data");
    if(this.gaugeArray.length != 0) {
    this.gaugeChart(this.gaugeArray);
    }
  }
  ngOnChanges(): void {
    console.log(this.gaugeArray, "resss..............");
    if(this.gaugeArray.length != 0) {
    this.gaugeChart(this.gaugeArray);
    }
  }
  /**
   * Fetch the chart data
   */
  options: any;
  // gaugeChart(data) {
  //   console.log(data.time,"timee")
  //   // this.echartsInstance = echarts.init(
  //   //   this.elementRef.nativeElement.querySelector("#combine")
  //   // );
  //   this.options = {
  //     title: {
  //       text: "current Vs status",
  //       left: "center",
  //       show: false,
  //     },
  //     tooltip: {
  //       trigger: "axis",
  //       axisPointer: {
  //         animation: false,
  //       },
  //     },
  //     legend: {
  //       data: ["Status", "Current"],
  //       left: 10,
  //     },
  //     // toolbox: {
  //     //   feature: {
  //     //     dataZoom: {
  //     //       yAxisIndex: "none",
  //     //     },
  //     //     restore: {},
  //     //     saveAsImage: {},
  //     //   },
  //     // },
  //     axisPointer: {
  //       link: [
  //         {
  //           xAxisIndex: "all",
  //         },
  //       ],
  //     },
  //     dataZoom: [
  //       {
  //         show: true,
  //         realtime: true,
  //         start: 70,
  //         end: 100,
  //         xAxisIndex: [0, 1],
  //       },
  //       {
  //         type: "inside",
  //         realtime: true,
  //         start: 70,
  //         end: 100,
  //         xAxisIndex: [0, 1],
  //       },
  //     ],
  //     grid: [
  //       {
  //         left: 60,
  //         right: 50,
  //         height: "25%",
  //       },
  //       {
  //         left: 60,
  //         right: 50,
  //         top: "55%",
  //         height: "35%",
  //       },
  //     ],
  //     xAxis: [
  //       {
  //         type: "category",
  //         boundaryGap: false,
  //         axisLine: { onZero: false },
  //         data: data.time[0],
  //         // data: data.timeData,

  //         show: false,
  //       },
  //       {
  //         gridIndex: 1,
  //         type: "category",
  //         boundaryGap: false,
  //         axisLine: { onZero: true },
  //         data: data.time[0],
  //         // data: data.timeData,
  //       },
  //     ],
  //     yAxis: [
  //       {
  //         name: "Status",
  //         type: "value",
  //         max: 1,
  //         min: -1,
  //         show: false,
  //       },
  //       {
  //         gridIndex: 1,
  //         name: "Current(amps)",
  //         type: "value",
  //         inverse: false,
  //       },
  //     ],
  //     visualMap: {
  //       top: 50,
  //       right: 10,
  //       show:false,
  //       pieces: [
  //         {
  //           gte: 0,
  //           lt: 1,
  //           color: '#f8fb20'
  //         },
  //         {
  //           gte: 1,
  //           lt: 2,
  //           color: '#55FF33'
  //         },
  //       ],
  //       outOfRange: {
  //         color: '#f8fb20'
  //       },
  //       seriesIndex: 0
  //     },
  //     series: [
  //       {
  //         name: "status",
  //         type: "line",
  //         step: "end",
  //         // color: "blue",
  //         // color: data.status[0] === 0 ? "yellow" : "green",
  //         showSymbol: false,
  //         lineStyle:{
  //           width: 2
  //         },
  //         // symbolSize: 8,
  //         // prettier-ignore
  //         // data: data.status[0],
  //         data: data.status[0].map(function (item) {
  //           return item;
  //         }),
  //         markLine: {
  //           silent: true,
  //           lineStyle: {
  //             color: ''
  //           },

  //         }
  //       },
  //       {
  //         name: "",
  //         type: "scatter",

  //         areaStyle: {},
  //         xAxisIndex: 1,
  //         yAxisIndex: 1,
  //         symbolSize: 10,
  //         color: "#f20713",
  //         tooltip: {
  //           show: false,
  //         },
  //         // prettier-ignore
  //         data: data.parts?data.parts[0]:[],
  //         // data: data.parts,
  //       },
  //       {
  //         name: "current",
  //         type: "line",
  //         smooth: true,
  //         style: "area",
  //         showSymbol: false,
  //         areaStyle: {},
  //         xAxisIndex: 1,
  //         yAxisIndex: 1,
  //         symbolSize: 8,
  //         color: "#007d79",
  //         // prettier-ignore
  //         data: data.current[0],
  //         // data: data.current,
  //       },
  //     ],
  //     // option && this.echartsInstance.setOption(option);
  //   };
  //   console.log(this.options,"optionss")
  // }
  gaugeChart(data) {
    
    let today = moment(new Date()).format("YYYY-MM-DD");
    var overall_status = data[0].time[0].map((time, index) => {
      const dateStr = `${today}T${time}`;
      return [dateStr, data[1].overall_status[0][index].value];
    });
    var current = data[0].time[0].map((time, index) => {
      const dateStr = `${today}T${time}`;
      return [dateStr, data[0].current[0][index]];
    });
    // var status = data.time[0].map((time, index) => {
    //   const dateStr = `${today}T${time}`;
    //   return [dateStr, data.status[0][index]];
    // });
    // var parts = data.time[0].map((time, index) => {
    //   const dateStr = `${today}T${time}`;
    //   return [dateStr, data.parts[0][index]];
    // });
    this.options = {
      title: {
        text: "current Vs status",
        left: "center",
        show: false,
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          var tooltipContent = "";
          params.forEach(function (item) {
            var seriesName =
              item.seriesType === "bar" ? item.seriesName : item.seriesName;

            var value = item.seriesType === "bar" ? "" : item.value;
            var axisValue = moment(item.value[0]).format("HH:mm:ss");
              
              var barvalue = item.seriesName === "Overall_Status" ? data[1].overall_status[0][item.dataIndex].name : item.value[1];
           
            tooltipContent +=
              seriesName +
              ": " +
              barvalue +
              "<br/>Time: " +
              axisValue +
              "<br/><br/>";
          });

          return tooltipContent;
        },
        axisPointer: {
          animation: false,
        },
      },

      legend: {
        data: ["Status", "Current"],
        left: 10,
        show: false,
      },
      // toolbox: {
      //   feature: {
      //     dataZoom: {
      //       yAxisIndex: "none",
      //     },
      //     restore: {},
      //     saveAsImage: {},
      //   },
      // },
      axisPointer: {
        link: [
          {
            xAxisIndex: "all",
          },
        ],
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
        },
      ],
      grid: [
        {
          left: 60,
          right: 50,
          height: "25%",
        },
        {
          left: 60,
          right: 50,
          top: "55%",
          height: "35%",
        },
      ],
      xAxis: [
        {
          name: "Time",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 10,
            fontWeight: "bold",
            fontFamily: "sans-serif",
          },
          nameGap: 40,
          nameRotate: 0,
          type: "time",
          boundaryGap: false,
          axisLine: { onZero: false },
          // data: data.time ? data.time[0] : [],
          show: false,
          splitLine: {
            show: false,
          },
        },
        {
          gridIndex: 1,
          type: "time",
          splitLine: {
            show: false,
          },
          boundaryGap: false,
          axisLine: { onZero: true },
          axisLabel: {
            formatter: function (value) {
              const date = new Date(value);
              const hours = date.getHours().toString().padStart(2, "0");
              const minutes = date.getMinutes().toString().padStart(2, "0");
              const seconds = date.getSeconds().toString().padStart(2, "0");
              return `${hours}:${minutes}:${seconds}`;
            },
          },
          name: "Time",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 10,
            fontWeight: "bold",
            fontFamily: "sans-serif",
          },
          nameGap: 40,
          nameRotate: 0,
          // data: data.time ? data.time[0] : [],
        },
      ],
      yAxis: [
        {
          name: "Status",
          type: "value",
          // max: 1,
          // min: 0,
          show: false,
          splitLine: {
            show: false,
          },
        },
        {
          gridIndex: 1,
          splitLine: {
            show: false,
          },
          // nameTextStyle: {
          //   align: "left",
          //   verticalAlign: "top",
          //   lineHeight: 18,
          //   fontSize: 16,
          //   fontWeight: "bold",
          //   fontFamily: "sans-serif",
          // },
          // nameRotate: 0,
          // position: "left",
          // padding: [5, 6, 5, 47],
          name: "Current (amp)",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 12,
            fontWeight: "bold",
            fontFamily: "sans-serif",
          },
          nameGap: 40,
          nameRotate: 90,
          type: "value",
          inverse: false,
        },
      ],

      series: [
        {
          name: "Overall_Status",
          type: "bar",
          // tooltip: {
          //   show: false,
          // },
          // step: "end",
          // color: "blue",
          showSymbol: false,
          barCategoryGap: "0%",
          // symbolSize: 8,
          // prettier-ignore
          // data: data.overall_status ? data.overall_status[0]:[],
          data: overall_status,

          itemStyle: {
            normal: {
              color: function (params) {
                return data[1].overall_status[0][params.dataIndex].itemStyle.color;
              },
            },
          },
        },
        // {
        //   name: "",
        //   type: "scatter",

        //   areaStyle: {},
        //   xAxisIndex: 1,
        //   yAxisIndex: 1,
        //   symbolSize: 10,
        //   color: "#f20713",
        //   tooltip: {
        //     show: false,
        //   },
        //   // prettier-ignore
        //   // data: data.parts?data.parts[0]:[],
        //   data: parts,
        // },
        {
          name: "current",
          type: "bar",
          smooth: true,
          // style: "area",
          showSymbol: false,
          // areaStyle: {},
          barCategoryGap: "0%",
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbolSize: 8,
          color: "#007d79",
          // prettier-ignore
          // data: data.current?data.current[0]:[],
          data: current,
          itemStyle: {
            // borderRadius: [20, 20, 0, 0]
            normal: {
              borderRadius: function (params) {
                return [20, 20, 0, 0];
              },
            },
          },
        },
        // {
        //   name: "Status",
        //   type: "line",
        //   step: "end",
        //   color: "blue",
        //   showSymbol: false,
        //   xAxisIndex: 1,
        //   yAxisIndex: 1,
        //   // prettier-ignore
        //   // data: data.status?data.status[0]:[],
        //   data: status,
        // },
      ],
      // option && this.echartsInstance.setOption(option);
    };
  }

}
