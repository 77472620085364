import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../Services/data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-pagetitle",
  templateUrl: "./pagetitle.component.html",
  styleUrls: ["./pagetitle.component.scss"],
})
export class PagetitleComponent implements OnInit {
  @Input() breadcrumbItems;
  @Input() title: string;
  @Input() deviceInfo: string;
  validationform: FormGroup;
  deviceData: any;
  submitted: false;
  deviceId: any;

  constructor(
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    console.log(this.deviceInfo, "deviceInfo");
    if (this.deviceInfo) {
      const machineInfo = JSON.parse(sessionStorage.getItem("machineInfio"));
      this.deviceId = machineInfo.deviceID;
      console.log(this.deviceId);
      this.getDeviceInfo();
    }
  }

  getDeviceInfo() {
    this.dataService
      .get(`api/deviceInfo?TenantID=${this.deviceId}`)
      .subscribe((res: any) => {
        this.deviceData = res;
        console.log(res, "res");
      });
  }

  initializeForm() {
    this.validationform = this.formBuilder.group({
      MAC: ["", [Validators.required]],
      wifiName: ["", [Validators.required]],
      firmwareVer: ["", [Validators.required]],
      signalQuality: ["", [Validators.required]],
      Ip: ["", [Validators.required]],
      chipVersion: ["", [Validators.required]],
      compileTime: ["", [Validators.required]],
      buildDateTime: ["", [Validators.required]],
      "ESP-IDF": ["", [Validators.required]],
      lastDeviceResetReason: ["", [Validators.required]],
      otaStatus: ["", [Validators.required]],
      otaUrl: ["", [Validators.required]],
      deviceConfigUrl: ["", [Validators.required]],
      Ki: ["", [Validators.required]],
      Ku: ["", [Validators.required]],
      "PLConstant Value": ["", [Validators.required]],
    });
  }

  clearWifiCredentials() {
    let msg = {
      ts: new Date().getTime(),
      TenantID: this.deviceData.TenantID,
    };
    this.dataService
      .postFirmware(
        `api/device/sendCommand?action=clearWifiCredentials&deviceId=${this.deviceId}`,
        msg
      )
      .subscribe((res: any) => {
        console.log(res, "res");
        this.toastr.success("Wifi Credentials have been cleared");
        this.modalService.dismissAll();
      });
  }

  deviceReset() {
    let msg = {
      ts: new Date().getTime(),
      TenantID: this.deviceData.TenantID,
    };
    this.dataService
      .postFirmware(
        `api/device/sendCommand?action=deviceReset&deviceId=${this.deviceId}`,
        msg
      )
      .subscribe((res: any) => {
        console.log(res, "res");
        this.toastr.success("Device have been reseted");
        this.modalService.dismissAll();
      });
  }

  factoryReset() {
    let msg = {
      ts: new Date().getTime(),
      TenantID: this.deviceData.TenantID,
    };
    this.dataService
      .postFirmware(
        `api/device/sendCommand?action=factoryReset&deviceId=${this.deviceId}`,
        msg
      )
      .subscribe((res: any) => {
        console.log(res, "res");
        this.toastr.success("Factory Reset have been done");
        this.modalService.dismissAll();
      });
  }

  openModal(content: any, data: any, act: any) {
    console.log(this.deviceData, "data");
    if (Object.keys(this.deviceData).length) {
      this.modalService.open(content, {
        backdrop: "static",
        centered: true,
        size: "lg",
      });
      if (act === "Add") {
        this.initializeForm();
      } else if (act === "Edit") {
        if (data) {
          console.log(data, "data");
          this.initializeForm();
          this.pathValue(this.validationform, data);
        }
      }
    } else {
      this.toastr.error("Device Details Not Found");
    }
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      controls[key].patchValue(data[key]);
    }
    this.validationform.disable();
  }

  get form() {
    return this.validationform.controls;
  }
}
