import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-vertical",
  templateUrl: "./vertical.component.html",
  styleUrls: ["./vertical.component.scss"],
})
export class VerticalComponent implements OnInit {
  private _message$ = new Subject<string>();
  successMessage = "";

  constructor() {}

  ngOnInit(): void {
    document.body.setAttribute("data-sidebar", "dark");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
    document.body.classList.remove("auth-body-bg");
    document.body.classList.add("sidebar-enable");
    document.body.classList.add("vertical-collpsed");
  }
  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle("sidebar-enable");
    document.body.classList.toggle("vertical-collpsed");

    if (window.screen.width <= 768) {
      document.body.classList.remove("vertical-collpsed");
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle("right-bar-enabled");
  }
}
