import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/shared/Services/data.service";
import { ToastrService } from "ngx-toastr";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
import { binFileValidator } from "src/app/shared/Services/custom-validator";
@Component({
  selector: "app-firmware",
  templateUrl: "./firmware.component.html",
  styleUrls: ["./firmware.component.scss"],
})
export class FirmwareComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  firmwareForm: FormGroup;
  submitted: boolean = false;
  currentPage = 1;
  itemsPerPage = 3;
  pageSize;
  loginRole: any = "";
  delVersion = "";
  isEdit: boolean = false;
  isAdd: boolean = false;
  formData = new FormData();
  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
    private toastr: ToastrService,
    public formBuilder: FormBuilder,
    private http: HttpClient
  ) {}
  versions: any = [];
  ngOnInit(): void {
    this.get();
    this.breadCrumbItems = [
      { label: "Firmware" },
      { label: "Dashboard", active: true },
    ];
  }

  createItem(data): FormGroup {
    return this.formBuilder.group(data);
  }

  get() {
    this.dataService.getFirmware(`api/otaService`).subscribe(
      (res) => {
        console.log(res, "getapi");
        let response = JSON.parse(JSON.stringify(res));
        this.versions = response.reduce((acc, obj) => {
          const existingObj = acc.find((item) => item.version === obj.version);
          if (existingObj) {
            existingObj.project = obj.project;
            existingObj.name.push(obj.name);
            existingObj.lastModify.push(obj.lastModify);
          } else {
            acc.push({
              project: obj.project,
              version: obj.version,
              name: [obj.name],
              lastModify: [obj.lastModify],
            });
          }
          return acc;
        }, []);
        console.log(this.versions, "versionss");
      },
      (error) => {
        console.log(error, "get error");
      }
    );
  }
  get files(): FormArray {
    return this.firmwareForm.get("files") as FormArray;
  }
  initializeForm() {
    this.firmwareForm = this.formBuilder.group({
      project: ["", [Validators.required]],
      version: ["", Validators.required],
      // files: this.formBuilder.array([], Validators.required),
      files: this.formBuilder.array(
        [],
        [Validators.required, binFileValidator]
      ),
    });
  }
  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  openModalDelete(deletetemplate, project) {
    this.delVersion = project;
    this.modalService.open(deletetemplate, { centered: true });
  }
  delete(project) {
    this.dataService
      .deleteFirmware(`api/otaService?project=${project}`)
      .subscribe((res: any) => {
        console.log(res, "res");
        this.modalService.dismissAll();
        this.toastr.success(res.message);
        this.get();
      });
  }
  edit(item) {
    console.log(item, "edititem");
    this.isEdit = true;
    this.initializeForm();
    if (item) {
      const { project, version } = item;
      this.firmwareForm.patchValue({
        project: project || "",
        version: version || "",
        // files: files || [""],
      });
      for (let i = 0; i < item?.name?.length; i++) {
        const file = item.name[i];
        this.files.push(this.formBuilder.control(file));
      }
      this.firmwareForm.get("version").disable();
      this.firmwareForm.get("project").disable();
    }
  }
  cancel() {
    this.isEdit = false;
    this.submitted = false;
    this.isAdd = false;
  }
  get type() {
    return this.firmwareForm.controls;
  }
  updateObj: any;
  save() {
    this.submitted = true;
    this.firmwareForm.enable();
    this.formData = new FormData();
    console.log(this.firmwareForm.value, this.firmwareForm.status);
    if (this.firmwareForm.status == "INVALID") {
      return;
    } else {
      console.log(this.files, "files");
      // for (let i = 0; i < this.files.length; i++) {
      //   console.log(this.files.at(i).value, " this.files.at(i).value");
      //   this.formData.append(`file${i}`, this.files.at(i).value.file);
      // }
      // this.formData.append("version", this.firmwareForm.get("version").value);
      // this.formData.append("project", this.firmwareForm.get("project").value);
      let project = this.firmwareForm.get("project").value;
      let files = [];

      for (let i = 0; i < this.files.length; i++) {
        let file = this.files.at(i).value.file;
        let fileName = this.files.at(i).value.file["name"];

        let reader = new FileReader();
        reader.onload = (event) => {
          let base64String = event.target.result;
          if (typeof base64String === "string") {
            base64String = base64String.replace(/^data:.+;base64,/, "");
            files.push({
              fileContent: base64String,
              fileName: fileName,
              version: this.firmwareForm.get("version").value,
              project: project,
            });
            // this.formData.append(`fileContent`, base64String);
            // this.formData.append(`fileName`, fileName);
            // this.formData.append(
            //   "version",
            //   this.firmwareForm.get("version").value
            // );
            // this.formData.append(
            //   "project",
            //   this.firmwareForm.get("project").value
            // );
            this.dataService
              .postFirmware("api/otaService/upload", files[0])
              .subscribe(
                (res: any) => {
                  console.log(res, "postapi");
                  this.toastr.success(res.message);
                  this.get();
                  this.submitted = false;
                  this.initializeForm();
                },
                (error) => {
                  console.log(error);
                  this.toastr.error(error.message);
                }
              );
          } else {
            console.error("File could not be processed as a Base64 string.");
          }
        };
        reader.readAsDataURL(file);
      }
      console.log(this.formData, "formupdate");
      // this.http
      //   .post(
      //     `http://20.212.30.248:3015/device/OTASERVICE?project=${project}`,
      //     this.formData
      //   )
      //   .subscribe(
      //     (res: any) => {
      //       console.log(res, "postapi");
      //       this.toastr.success(res);
      //       this.get();
      //       this.submitted = false;
      //       this.initializeForm();
      //     },
      //     (error) => {
      //       this.toastr.error(error.message);
      //     }
      //   );
      this.isEdit = false;
      this.isAdd = false;
    }
  }
  format(date) {
    return moment(date).format("DD/MM/YY HH:mm:ss a");
  }
  addFirmware() {
    this.isAdd = true;
    this.initializeForm();
  }
  fileInputChange(event) {
    let arrayoffiles = event.target.files;
    while (this.files.length !== 0) {
      this.files.removeAt(0);
    }
    this.submitted = false;
    // this.formData.append("file",event.target.files)
    // console.log(this.formData,"formdataafter append")
    if (arrayoffiles) {
      for (let file of arrayoffiles) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.files.push(
            this.createItem({
              file,
              // ,
              // url: e.target.result
            })
          );
        };
        reader.readAsDataURL(file);
      }
    }
  }
  showFiles(control) {
    let fileName;
    fileName =
      typeof control.value == "object"
        ? control.value.file.name
        : control.value;
    let maxLength = 15;
    if (fileName.length <= maxLength) {
      return fileName;
    }

    const extension = fileName.split(".").pop();
    const truncatedName = fileName.substring(0, maxLength - 3);
    return `${truncatedName}...${extension}`;
  }
  removeFile(index) {
    this.files.removeAt(index);
  }
}
