<div class="container-fluid">
  <app-pagetitle
    title="Sample"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>

  <span *ngIf="!hideMachine">
    <div class="row" *ngIf="showRecord">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <div>{{ selectedMachineName }}</div>

            <button type="button" class="btn btn-danger" (click)="close()">
              Back
            </button>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive mt-3" *ngIf="sampleData.length != 0">
                <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="
                    border-collapse: collapse;
                    border-spacing: 0;
                    width: 100%;
                  "
                >
                  <thead class="thead-light">
                    <tr>
                      <th>Sample</th>
                      <th>Program</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Duration</th>
                      <th style="width: 120px">Action</th>
                    </tr>
                  </thead>
                  <tbody
                    *ngFor="
                      let item of sampleData
                        | slice : pageSize
                        | slice : 0 : itemsPerPage;
                      let i = index
                    "
                  >
                    <tr>
                      <td>{{ item.sample }}</td>
                      <td>{{ item.program }}</td>
                      <td>{{ converttohh(item.startTime) }}</td>
                      <td>{{ converttohh(item.endTime) }}</td>
                      <td>{{ item.duration }}</td>

                      <td>
                        <a
                          class="text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          style="cursor: pointer"
                        >
                          <i class="mdi mdi-trash-can font-size-18" (click)="openModalDelete(deletetemp, item.id)"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-md-6" *ngIf="sampleData.length > 2">
                  <ngb-pagination
                    class="pagination-rounded"
                    [collectionSize]="sampleData?.length"
                    #numPages
                    [pageSize]="itemsPerPage"
                    [(page)]="currentPage"
                    [maxSize]="1"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    (pageChange)="onPageChange(currentPage)"
                  >
                  </ngb-pagination>
                </div>
              </div>
              <div class="card" *ngIf="sampleData.length == 0">
                <h5 *ngIf="errmsg!=''">Server Unreachable. Please try again</h5>
                <h5 *ngIf="errmsg==''">No Records to view</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>

  <div class="row" *ngIf="hideMachine">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <div>
            {{ selectedMachineName }}<br />
            <span>[ {{ livecurrentVal }} amps]</span>
          </div>
          <div>
            <button
            [disabled]="!addRecord"
          type="button"
          class="btn btn-danger"
          (click)="close()"
        >
          Back
        </button>
          </div>
        </div>
        
        
        <div class="card-body">
          <form
            (ngSubmit)="saveData()"
            [formGroup]="validationform"
            autocomplete="off"
          >
            <div class="container">
              <div class="row" *ngIf="showfields">
                <div class="col-md-6">
                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="startTime">Start Time</label>
                          <div>{{ startTime }}</div>
                        </div>
                        <div class="col-md-6">
                          <label for="endTime">End Time</label>
                          <div>{{ endTime }}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="duration">Duration</label>
                          <div>{{ duration }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Sample Name and Program Name Fields -->
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="form-group">
                        <label for="sample">Sample Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="sample"
                          placeholder="Sample Name"
                          formControlName="sample_name"
                          [ngClass]="{
                            'is-invalid': submitted && form.sample_name.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && form.sample_name.errors"
                          class="invalid-feedback"
                        >
                          <span *ngIf="form.sample_name.errors.required"
                            >Sample is mandatory.</span
                          >
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="program">Program Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="program"
                          placeholder="Enter program"
                          formControlName="program"
                          [ngClass]="{
                            'is-invalid': submitted && form.program.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && form.program.errors"
                          class="invalid-feedback"
                        >
                          <span *ngIf="form.program.errors.required"
                            >Program Name is mandatory.</span
                          >
                        </div>
                      </div>
                      <div class="text-right">
                        <button
                          type="button"
                          class="btn btn-success"
                          (click)="saveData()"
                        >
                          Submit
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          class="btn btn-secondary"
                          (click)="close()"
                        >
                          Cancel
                        </button>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="!showfields">
              <div class="row justify-content-between">
                <h5>Current Time : {{ currentTime | date : "mediumTime" }}</h5>
              </div>
              <div class="row">
                <div class="col-sm-6">Start : {{ startTime }}</div>
                <!-- <div class="col-sm-6">Stop : {{ endTime }}</div> -->
              </div>
              <div class="row d-flex justify-content-center">
                <button
                  #candeactivate
                  type="button"
                  class="btn btn-success jobs"
                  *ngIf="!timerRunning"
                  (click)="startTimer()"
                >
                  Start
                </button>
                <button
                  type="button"
                  class="btn btn-danger jobs"
                  *ngIf="timerRunning"
                  (click)="stopTimer()"
                >
                  Stop
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Delete model  -->
<ng-template #deletetemp role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Delete Reason</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="example-container">
      <form>
        <div>
          <p>Are you sure to Delete?</p>
        </div>
        <button type="submit" class="btn btn-danger round waves-effect headr" (click)="deleteRecords(sampleId)">
          Delete
        </button>
        &nbsp;
        <button type="submit" class="btn btn-info round waves-effect headr" (click)="modal('Cross click')">
          Cancel
        </button>
      </form>
    </div>
  </div>
</ng-template>
