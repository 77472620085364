import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../../core/services/authfake.service";
import { LanguageService } from "../../../core/services/language.service";
import { environment } from "../../../../environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "src/app/shared/Services/data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  element: any;
  configData: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  custName: any;
  shift_Time;
  action;
  role: any;
  validationform: FormGroup;
  usersData: any;
  errmsg: any;
  id: any;
  submitted: boolean = false;
  existingdataflag: boolean = true;
  customerData: any;
  idleTime: any;
  productionCost: any;
  powerUnitCost: any;
  existingdata: object = {};
  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public cookiesService: CookieService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private toastr: ToastrService
  ) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custName = user["user"];
    this.shift_Time = this.custName["currentShift"];
    console.log(this.shift_Time, user);
    this.role = this.custName["role"];
    this.id = this.custName["_id"];
    this.idleTime = this.custName["idleTime"];
    this.productionCost = this.custName["productionCost"];
    this.powerUnitCost = this.custName["powerUnitCost"];
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.element = document.documentElement;
    this.validationform = this.formBuilder.group({
      idleTime: ["", [Validators.required]],
      productionCost: ["", [Validators.required]],
      powerUnitCost: ["", [Validators.required]],
    });
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };

    this.cookieValue = this.cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    console.log(this.custName, "navbar");
    this.getUser();
    this.getCustomer();

    if (
      this.idleTime == "" &&
      this.productionCost == "" &&
      this.powerUnitCost == ""
    ) {
      this.existingdataflag = false;
    } else {
      this.existingdataflag = true;
      this.existingdata["idleTime"] = this.idleTime;
      this.existingdata["productionCost"] = this.productionCost;
      this.existingdata["powerUnitCost"] = this.powerUnitCost;
    }
  }

  get form() {
    return this.validationform.controls;
  }

  convertTimestampToTime(timestamp) {
    // Convert timestamp to milliseconds
    var date = new Date(timestamp);

    // Get hours, minutes, and seconds
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();

    // Format hours in 12-hour format
    var formattedHours = hours % 12 || 12; // Handle midnight (0) as 12 AM
    var amPm = hours >= 12 ? "PM" : "AM";

    // Construct the time string
    var formattedTime =
      formattedHours +
      ":" +
      minutes.substr(-2) +
      ":" +
      seconds.substr(-2) +
      " " +
      amPm;

    return formattedTime;
  }
  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Translate language
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Logout the user
   */
  logout() {
    let deactivate = sessionStorage.getItem("Deactivate");
    console.log(deactivate, "hgfghjhgfg");
    if (deactivate == null || deactivate == "true") {
      if (environment.defaultauth === "firebase") {
        this.authService.logout();
      } else {
        this.authFackservice.logout();
      }
      sessionStorage.clear();
      this.router.navigate(["/account/login"]);
    } else {
      this.toastr.error(
        "You are not allowed to navigate away.",
        "Permission Denied"
      );
    }
  }

  openModal(content: any, data: any, act: any) {
    this.action = act;
    this.modalService.open(content, { centered: true });
    if (act === "Add") {
      this.validationform = this.formBuilder.group({
        idleTime: "",
        productionCost: "",
        powerUnitCost: "",
      });
    } else if (act === "Edit") {
      if (data) {
        this.pathValue(this.validationform, data);
      }
    }
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      controls[key].patchValue(data[key]);
    }
  }
  saveData() {
    this.submitted = true;
    if (this.validationform.invalid) {
      return this.validationform.controls;
    } else {
      let data = {};
      (data["idleTime"] = this.validationform.value["idleTime"]),
        (data["productionCost"] = this.validationform.value["productionCost"]),
        (data["powerUnitCost"] = this.validationform.value["powerUnitCost"]);
      this.cancel();
      this.dataservice
        .put(`api/user/${this.customerData[0]["userId"]}`, data)
        .subscribe((res) => {
          console.log(res, "response");
          if (res) {
            this.cancel();
            this.toastr.success("User Added Successfully");
            this.getCustomer();
          }
        });
    }
  }

  cancel() {
    this.submitted = false;
    this.action = "";
    this.modalService.dismissAll();
  }
  getUser() {
    this.dataservice.get("api/user").subscribe((res) => {
      console.log(res);
      if (res) {
        this.usersData = res;
      } else {
        this.usersData = [];
      }
      (error) => {
        console.log(error);
        this.errmsg = "Server Unreachable";
        this.usersData = [];
        this.toastr.error("Server Unreachable");
      };
    });
  }

  getCustomer() {
    this.dataservice.get(`api/customer?id=${this.id}`).subscribe((res) => {
      console.log(res, "customeres");

      this.customerData = res;
      if (this.customerData.length != 0) {
        if (
          this.customerData[0]["idleTime"] &&
          this.customerData[0]["productionCost"] &&
          this.customerData[0]["powerUnitCost"]
        ) {
          if (
            this.customerData[0]["idleTime"] == "" &&
            this.customerData[0]["productionCost"] == "" &&
            this.customerData[0]["powerUnitCost"] == ""
          ) {
            this.existingdataflag = false;
          } else {
            this.existingdataflag = true;
            this.existingdata["idleTime"] = this.customerData[0]["idleTime"];
            this.existingdata["productionCost"] =
              this.customerData[0]["productionCost"];
            this.existingdata["powerUnitCost"] =
              this.customerData[0]["powerUnitCost"];
          }
        } else {
          this.existingdataflag = false;
        }
      }
    });
  }
}
