import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedDecimal'
})
export class FixedDecimalPipe implements PipeTransform {
  transform(value: number, decimals: number = 2): string {
    if (isNaN(value)) {
      return null;
    }
    return value.toFixed(decimals);
  }
}