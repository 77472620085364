<div class="container-fluid">
  <app-pagetitle
    title="Reports"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>

  <div class="row">
    <div class="col-xl-12">
      <div class="row">
        <!-- <div *ngFor="let stat of statData" class="col-md-3"> -->
        <!-- <div class="col-md-2">
          <div class="form-group">
            <label class="mt-3">Multiple Select</label>
            <ng-multiselect-dropdown
              [placeholder]="'custom placeholder'"
              [settings]="dropdownSettings"
              [data]="deviceList"
              [(ngModel)]="selectedItems"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
            >
            </ng-multiselect-dropdown>
           <ng-select [items]="deviceList" [multiple]="true"></ng-select> 
          </div>
        </div> -->
        <div class="col-md-2">
          <div class="form-group">
            <label>Device</label>
            <!-- <ng-select
              id="device"
              name="deviceNameName"
              [(ngModel)]="deviceName"
              (ngModelChange)="select_Device($event)"
            >
              <ng-option
                *ngFor="let range of deviceList"
                [value]="range.deviceId"
              >
                {{ range.deviceName }}
              </ng-option>
            </ng-select> -->

            <select
              class="form-control custom-select"
              (ngModelChange)="select_Device($event)"
              name="deviceNameName"
              id="device"
              ngModel
              disableOptionCentering
              [(ngModel)]="deviceName"
            >
              <option *ngFor="let range of deviceList" [ngValue]="range">
                {{ range.deviceName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label>Report Type </label>
            <select
              class="form-control custom-select"
              (ngModelChange)="select_Report($event)"
              name="reportType"
              id="report"
              ngModel
              disableOptionCentering
              [(ngModel)]="reportType"
            >
              <option
                *ngFor="let range of reportList"
                [ngValue]="range.reportName"
              >
                {{ range.reportName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <!-- <div class="form-control"> -->
          <!-- <label>From Date</label>
          <input
            class="form-control"
            type="date"
            [(ngModel)]="fromDate"
            placeholder="{{ 'From-date' }}"
            id="example-date-input"
            [min]="mindate"
            [max]="maxdate"
            (click)="toggle()"
            (change)="changeMin()"
          /> -->
          <!-- </div> -->
          <div class="form-group">
            <label>Select Range</label>

            <select
              class="form-control"
              (ngModelChange)="rangeDataa($event)"
              name="rangeName"
              id="range"
              ngModel
              disableOptionCentering
              [(ngModel)]="selectedRange"
            >
              <option *ngFor="let range of byRange" [ngValue]="range">
                {{ range }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group col-md-2" [hidden]="selectedRange !== 'Custom'">
          <label>From Date</label>
          <input
            class="form-control"
            type="date"
            [(ngModel)]="fromDate"
            placeholder="{{ 'From-date' }}"
            id="example-date-input"
            [min]="mindate"
            [max]="maxdate"
            (click)="toggle()"
            (change)="changeMin()"
          />
          <!-- <input
            class="form-control"
            [(ngModel)]="fromDate"
            placeholder="{{ 'From-date' }}"
            [minDate]="mindate"
            [maxDate]="maxdate"
            name="dp"
            ngbDatepicker
            #d="ngbDatepicker"
            (click)="d.toggle()"
            (dateSelect)="changeMax()"
            required
          /> -->
        </div>
        <div class="form-group col-md-2" [hidden]="selectedRange !== 'Custom'">
          <label>To Date</label>
          <div class="input-group">
            <!-- <input
              class="form-control"
              [(ngModel)]="toDate"
              placeholder="{{ 'To-date' }}"
              name="dp"
              [minDate]="mindate"
              [maxDate]="maxdate"
              ngbDatepicker
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              required
            /> -->
            <input
              class="form-control"
              type="date"
              [(ngModel)]="toDate"
              placeholder="{{ 'From-date' }}"
              id="example-date-input"
              [min]="fromDate"
              [max]="maxdate"
              (click)="toggle()"
              (change)="changeMax()"
            />
          </div>
        </div>
        <div class="col-md-1">
          <button
            style="width: 100%; margin-top: 1.8rem"
            type="button"
            class="btn btn-success btn-rounded"
            (click)="changeDate()"
          >
            Submit
          </button>
          <!-- <button
            type="button"
            class="btn btn-primary btn-sm btns"
            (click)="changeDate()"
          >
            {{ "submit" }}
          </button> -->
        </div>
        <div class="col-md-1">
          <button
            type="button"
            style="width: 100%; margin-top: 1.8rem"
            class="btn btn-primary btn-rounded"
            (click)="openModal(templatepdf, 'downloadpdfcsv', 'Export')"
          >
            Export
          </button>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <span class="text-primary"> Energy</span>
          <span class="d-flex">
            <h4 class="mb-0">
              {{ currantAverage ? currantAverage : 0 }}
              <span class="txt-font">KwH</span>
            </h4>
            &nbsp; /&nbsp;
            <h4 class="mb-0">
              {{ totalPart ? totalPart : 0 }}
              <span class="txt-font">Parts</span>
            </h4>
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <span class="text-primary"> T-Energy</span>
          <h4 class="mb-0">
            {{ total_energy ? total_energy : 0 }}
            <span class="txt-font"> KwH</span>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <span class="text-primary"> R-Power</span>
          <h4 class="mb-0">
            {{ react_energy ? react_energy : 0 }}
            <span class="txt-font">W</span>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <span class="text-primary"> A-Power</span>
          <h4 class="mb-0">
            {{ appa_energy ? appa_energy : 0 }} <span class="txt-font">VA</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body" *ngIf="!showTable">
          Please wait we are fetching data / nodata
          <img src="assets/images/noData.jpg" width="70%" />
        </div>
        <div class="card-body" *ngIf="showTable">
          <div class="d-inline-block mr-2">
            <p class="badge" style="background-color: #08de70; color: white">
              Availability
            </p>
          </div>
          <div class="d-inline-block mr-2">
            <p
              class="badge"
              style="background-color: #08debb; color: rgb(255, 253, 253)"
            >
              Performance
            </p>
          </div>

          <div class="d-inline-block mr-2">
            <p
              class="badge"
              style="background-color: #0865de; color: rgb(255, 253, 253)"
            >
              Quality
            </p>
          </div>

          <div class="d-inline-block mr-2">
            <p
              class="badge"
              style="background-color: #de7a08; color: rgb(255, 254, 254)"
            >
              Oee
            </p>
          </div>
          <app-barchart
            [seriesdata]="seriesdata"
            [labels]="filteredData"
          ></app-barchart>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div *ngIf="!showTable">
            Please wait we are fetching data / nodata
            <img src="assets/images/noData.jpg" width="70%" />
          </div>
          <app-statusbarchart
            *ngIf="showTable"
            [seriesdata]="statusGraph"
            [labels]="shift_list"
          ></app-statusbarchart>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div *ngIf="!showTable">
            Please wait we are fetching data / nodata
            <img src="assets/images/noData.jpg" width="70%" />
          </div>
          <!-- <h4 class="card-title">Dark Table</h4> -->
          <!-- <p class="card-title-desc">
            You can also invert the colors—with light text on dark
            backgrounds—with <code>.table-dark</code>.
          </p> -->

          <div
            class="table-responsive"
            *ngIf="showTable && selectedType == 'Shift'"
          >
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Shift_Name</th>
                  <th>Current (amp)</th>
                  <!-- <th>Availability %</th>
                  <th>Performance %</th>
                  <th>Quality %</th>
                  <th>OEE %</th> -->
                  <th>Part Count</th>
                  <th>Real Power</th>
                  <th>Apparent Power</th>
                  <th>Energy Consumption</th>
                  <th>Total Energy</th>
                  <!-- <th>Idle</th>
                  <th>Dryrun</th>
                  <th>Running</th>
                  <th>Warning</th>
                  <th>Alarm</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of tableData
                      | slice : pageSize
                      | slice : 0 : itemsPerPage;
                    let i = index
                  "
                >
                  <th>{{ data.date }}</th>
                  <td>{{ data.shift_name }}</td>
                  <td>{{ data.current_avg }}</td>
                  <!-- <td>{{ data.avail }}</td>
                  <td>{{ data.perf }}</td>
                  <td>{{ data.quality }}</td>
                  <td>{{ data.oee }}</td> -->
                  <td>{{ data.part_count ? data.part_count : "-" }}</td>
                  <td>{{ data.real_power ? data.real_power : "-" }}</td>
                  <td>{{ data.apparent_power ? data.apparent_power : "-" }}</td>
                  <td>
                    {{
                      data.energy_consumption ? data.energy_consumption : "-"
                    }}
                  </td>
                  <td>{{ data.total_energy ? data.total_energy : "-" }}</td>
                  <!-- <td>
                    {{ data.idle ? secondsToHms(data.idle) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.dryrun ? secondsToHms(data.dryrun) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.running ? secondsToHms(data.running) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.warning ? secondsToHms(data.warning) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.alarm ? secondsToHms(data.alarm) : "00:00:00" }}
                  </td> -->
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="tableData.length > 5">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="tableData?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
          <div
            class="table-responsive"
            *ngIf="showTable && selectedType == 'Day'"
          >
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>Date</th>
                  <!-- <th>Shift_Name</th> -->
                  <th>Current (amp)</th>
                  <!-- <th>Availability %</th>
                  <th>Performance %</th>
                  <th>Quality %</th>
                  <th>OEE %</th> -->
                  <th>Part Count</th>
                  <th>Real Power</th>
                  <th>Apparent Power</th>
                  <th>Energy Consumption</th>
                  <th>Total Energy</th>
                  <th>Cost</th>
                  <th>Prod_Loss</th>
                  <th>Prod_Loss_Hours</th>
                  <!-- <th>Idle</th>
                  <th>Dryrun</th>
                  <th>Running</th>
                  <th>Warning</th>
                  <th>Alarm</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of tableDayData
                      | slice : pageSize
                      | slice : 0 : itemsPerPage;
                    let i = index
                  "
                >
                  <th>{{ data.date }}</th>
                  <!-- <td>{{ data.shift_name }}</td> -->
                  <td>{{ data.avg_current }}</td>
                  <!-- <td>{{ data.avail }}</td>
                  <td>{{ data.perf }}</td>
                  <td>{{ data.quality }}</td>
                  <td>{{ data.oee }}</td> -->
                  <td>
                    {{ data.total_part_count ? data.total_part_count : "-" }}
                  </td>
                  <td>{{ data.avg_real_power ? data.avg_real_power : "-" }}</td>
                  <td>
                    {{
                      data.avg_apparent_power ? data.avg_apparent_power : "-"
                    }}
                  </td>
                  <td>
                    {{
                      data.avg_energy_consumption
                        ? data.avg_energy_consumption
                        : "-"
                    }}
                  </td>
                  <td>
                    {{ data.avg_total_energy ? data.avg_total_energy : "-" }}
                  </td>
                  <td>
                    {{ data.power_unit_cost ? data.power_unit_cost : "-" }}
                  </td>
                  <td>
                    {{ data.total_prod_loss ? data.total_prod_loss : "-" }}
                  </td>
                  <td>
                    {{ data.prod_loss_hours ? formattime(data.prod_loss_hours) : "-" }}
                  </td>
                  <!-- <td>
                    {{ data.idle ? secondsToHms(data.idle) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.dryrun ? secondsToHms(data.dryrun) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.running ? secondsToHms(data.running) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.warning ? secondsToHms(data.warning) : "00:00:00" }}
                  </td>
                  <td>
                    {{ data.alarm ? secondsToHms(data.alarm) : "00:00:00" }}
                  </td> -->
                </tr>
              </tbody>
            </table>
            <div class="col-md-6" *ngIf="tableDayData?.length > 5">
              <ngb-pagination
                class="pagination-rounded"
                [collectionSize]="tableDayData?.length"
                #numPages
                [pageSize]="itemsPerPage"
                [(page)]="currentPage"
                [maxSize]="1"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                (pageChange)="onPageChange(currentPage)"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templatepdf role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ action }}</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click'); cancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body card-body">
    <div class="example-container">
      <ul
        ngbNav
        #justifiednav="ngbNav"
        [activeId]="1"
        class="nav-pills nav-justified"
      >
        <li [ngbNavItem]="1">
          <a ngbNavLink>
            <span class="d-none d-sm-block" (click)="directExpo()"
              >Direct Export</span
            >
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="directExport"></ng-template>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>
            <span class="d-none d-sm-block" (click)="mailexport()"
              >Export via Email</span
            >
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="mailExport"></ng-template>
          </ng-template>
        </li>
      </ul>
      &nbsp;&nbsp;
      <div [ngbNavOutlet]="justifiednav"></div>
      <div class="text-right mt-3">
        <button
          type="submit"
          class="btn btn-info round waves-effect headr"
          (click)="modal('Cross click'); download()"
          [disabled]="exportViaMail && !mailform.get('email').value"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #directExport>
  <form>
    <div>
      <p>Select the file type you would like to export</p>
    </div>
    <span
      class="pdf"
      [ngStyle]="{ 'background-color': pdfBool ? '#C4A484' : null }"
      ><i class="fas fa-file-pdf" (click)="pdf()"> PDF </i></span
    >&nbsp;&nbsp;
    <span
      class="csv"
      [ngStyle]="{ 'background-color': csvBool ? '#90EE90' : null }"
      ><i class="fas fa-file-csv" (click)="csv()"> CSV </i></span
    >
    &nbsp;&nbsp;
  </form>
</ng-template>
<ng-template #mailExport>
  <div>
    <p>Select the file type you would like to export</p>
  </div>
  <span
    class="pdf"
    [ngStyle]="{ 'background-color': pdfBool ? '#C4A484' : null }"
    ><i class="fas fa-file-pdf" (click)="pdf()"> PDF </i></span
  >&nbsp;&nbsp;
  <span
    class="csv"
    [ngStyle]="{ 'background-color': csvBool ? '#90EE90' : null }"
    ><i class="fas fa-file-csv" (click)="csv()"> CSV </i></span
  >&nbsp;&nbsp;
  <div>
    <form [formGroup]="mailform">
      <div class="form-group col-md-8">
        &nbsp;&nbsp;

        <input
          class="form-control"
          id="email"
          placeholder="user@gmail.com"
          formControlName="email"
          required
          [ngClass]="{ 'is-invalid': isEmailInvalid() }"
        />
        <div *ngIf="isEmailInvalid()">
          <span *ngIf="email.hasError('required')" style="color: red"
            >Email is required</span
          >
          <span *ngIf="email.hasError('email')" style="color: red"
            >Invalid email address</span
          >
        </div>
      </div>
    </form>
  </div>
</ng-template>
