import { MenuItem } from "./menu.model";

export const Admin: MenuItem[] = [
  {
    id: 1,
    label: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/admin",
  },

  {
    id: 2,
    label: "Configuration",
    icon: "ri-settings-5-line",
    link: "/customer",
    // subItems: [
    // {
    //   id: 3,
    //   label: "Customer",
    //   link: "/customer",
    //   parentId: 2,
    // },
    // {
    //   id: 4,
    //   label: "Machine",
    //   link: "/device",
    //   parentId: 2,
    // },
    // {
    //   id: 5,
    //   label: "Shift",
    //   link: "/shift",
    //   parentId: 2,
    // },
    // ],
  },
  {
    id:3,
    label: 'Firmware',
    icon:"fab fa-slack",
    link:"/firmware"
  }
];
