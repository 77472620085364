import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";
import * as echarts from "echarts";

// import { Stat, Chat, Transaction } from "./dashboard.model";
import { Router } from "@angular/router";
// import {
//   statData,
//   revenueChart,
//   salesAnalytics,
//   sparklineEarning,
//   sparklineMonthly,
//   chatData,
//   transactions,
// } from "./data";
import { DataService } from "src/app/shared/Services/data.service";
import { HttpClient } from "@angular/common/http";
// import { ChartComponent } from "ng-apexcharts";
import { Subject } from "rxjs";
import { ShareService } from "src/app/shared/Services/shared.service";
export interface ChartType {
  series?: any;
  plotOptions?: any;
  stroke?: any;
  dataLabels?: any;
  chart?: any;
  legend?: any;
  colors?: any;
  labels?: any;
}
import * as moment from "moment-timezone";

@Component({
  selector: "app-graphview",
  templateUrl: "./graphview.component.html",
  styleUrls: ["./graphview.component.scss"],
})

/**
 * graphview Component
 */
export class GraphViewComponent implements OnInit, OnDestroy {
  options: any;

  // @ViewChild("chart") chart: ChartComponent;
  private destroy$: Subject<void> = new Subject<void>();

  // public sparklineEarning: Partial<any>;
  sparklineEarning: any = {};
  term: any;
  // chatData: Chat[];
  // transactions: Transaction[];
  custId;
  apiInterval: any;
  unit_cost;
  constructor(
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private http: HttpClient,
    private router: Router,
    private sharedService: ShareService
  ) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = user["user"]["custId"];
    this.unit_cost = user["user"]["powerUnitCost"];
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  chatSubmit: boolean;

  formData: FormGroup;

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "K2" },
      { label: "Dashboard", active: true },
    ];
    this.getdata(this.custId);
    this.apiInterval = setInterval(() => {
      this.getdata(this.custId);
    }, 30000);
  }
  getdata(custId) {
    var device = {
      custID: custId,
    };
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/devices_current_graph?code=8l1T0Rl1lzwv9r6Rdr_EqCIVKn8S8ZnBvSp9sI1YgG09AzFuWtDLZQ==",
        device
      )
      .subscribe((res) => {
        var series = [];
        var keys = Object.keys(res);
        let valueToRemove = "time";
        let keyArray = keys.filter((element) => element !== valueToRemove);
        console.log(res, keys, keyArray, res["time"], "graphdata");
        let today = moment(new Date()).format("YYYY-MM-DD");
        var overall_status = res["time"][0].map((time, index) => {
          const dateStr = `${today}T${time}`;

          return dateStr;
        });
        console.log(overall_status, "datestr");
        let finaldata = keyArray.forEach((item) => {
          let data = res[item][0];

          let final = overall_status.map((time, index) => {
            return [time, data[index]];
          });

          var obj = {
            name: item,
            type: "bar",
            data: final,
          };
          series.push(obj);
        });
        console.log(series, "series");

        this.generateChart(series, keyArray);
        console.log(series, "connnn");
      });
  }
  ngOnDestroy() {
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  generateChart(data, label) {
    console.log(data, "dataa");

    // const formattedData = data.map((item) => {
    //   const timeParts = item[0].split(":");
    //   return [
    //     new Date(
    //       1970,
    //       0,
    //       1,
    //       parseInt(timeParts[0]),
    //       parseInt(timeParts[1]),
    //       parseInt(timeParts[2])
    //     ).getTime(),
    //     item[1],
    //     item[2],
    //   ];
    // });
    // console.log(formattedData, "formattedData");
    const chartOption = {
      title: {
        text: "Current Comparison",
      },
      legend: {
        data: label,
      },
      xAxis: {
        type: "time",
        axisLabel: {
          formatter: function (value) {
            const date = new Date(value);
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      yAxis: {
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
          },
        },
        scale: true,
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          realtime: true,
          start: 70,
          end: 100,
          xAxisIndex: 0,
        },
      ],
      tooltip: {
        formatter: function (params) {
          console.log(params);
          const date = new Date(params.value[0]);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          return `${
            params.seriesName
          }  <br/> Time: ${hours}:${minutes}:${seconds}<br/>Value: ${params.value[1].toFixed(
            2
          )}  `;
        },
      },
      series: data,
    };

    this.options = chartOption;
    console.log(this.options, "optionsss");
  }
  /**
   * Save the message in chat
   */
}
