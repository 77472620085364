<div class="container-fluid">
  <!-- start page title -->
  <app-pagetitle
    title="Shift-Info"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>
  <!-- end page title -->

  <div class="d-flex justify-content-end">
    <div class="example-button-row" [hidden]="is24hr == true">
      <button
        *ngIf="loginRole == 'SUPERADMIN' && !this.isEdit"
        class="btn btn-primary"
        title="Add"
        (click)="addshift()"
      >
        Add
      </button>
    </div>
  </div>

  <div class="row mt-2" *ngIf="shiftsArr?.length != 0 && !isAdd">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>Shift Name</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Duration</th>
                  <th style="width: 120px">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of shiftsArr; let i = index">
                <tr>
                  <th scope="row">{{ item.shiftName }}</th>
                  <td>{{ item.startTime }}</td>
                  <td>{{ item.endTime }}</td>
                  <td>{{ item.duration }}</td>
                  <td>
                    <a class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                      <i
                        class="mdi mdi-pencil font-size-18"
                        style="cursor: pointer"
                        (click)="edit(item)"
                      ></i>
                    </a>
                    <a
                      *ngIf="i == shiftsArr.length - 1"
                      [hidden]="loginRole == 'CUSTOMER'"
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                    >
                      <i
                        class="mdi mdi-trash-can font-size-18"
                        style="cursor: pointer"
                        (click)="openModal(deletetemp, item.id)"
                      ></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row mt-2" *ngIf="isAdd">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form
            class="needs-validation"
            [formGroup]="validationform"
            autocomplete="off"
          >
            <div class="row">
              <div class="col-md-6" [hidden]="true">
                <div class="form-group">
                  <label for="validationCustom01">Shift Id</label>
                  <input
                    type="text"
                    class="form-control"
                    id="validationCustom01"
                    formControlName="usershiftId"
                    placeholder="Shift Id"
                    [ngClass]="{
                      'is-invalid': submit && form.usershiftId.errors
                    }"
                  />
                  <div
                    *ngIf="submit && form.usershiftId.errors"
                    class="invalid-feedback"
                  >
                    <span *ngIf="form.usershiftId.errors.required"
                      >Please provide a shift id.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="validationCustom02">Shift Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="validationCustom02"
                    formControlName="shiftName"
                    placeholder="Shift name"
                    [ngClass]="{
                      'is-invalid': submit && form.shiftName.errors
                    }"
                  />
                  <div
                    *ngIf="submit && form.shiftName.errors"
                    class="invalid-feedback"
                  >
                    <span *ngIf="form.shiftName.errors.required"
                      >Please provide a shift name.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="validationCustom03">Start Time</label>
                  <!-- <input type="time" class="form-control" id="validationCustom03" formControlName="starttime"
                    placeholder="Start Time" step="1"> -->
                  <select
                    class="form-control"
                    id="validationCustom03"
                    formControlName="startTime"
                    placeholder="Start Time"
                  >
                    <option value="" disabled selected>Select Time</option>
                    <option
                      *ngFor="let time of selectValue; let k = index"
                      [value]="time"
                    >
                      {{ time }}
                    </option>
                  </select>
                  <div
                    *ngIf="submit && form.startTime.errors"
                    class="invalid-feedback"
                  >
                    <span *ngIf="form.startTime.errors.required"
                      >Please provide a start time.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="validationCustom04">End Time</label>
                  <!-- <input type="time" class="form-control" id="validationCustom04" formControlName="endtime"
                    placeholder="End Time" step="1"> -->
                  <select
                    class="form-control"
                    id="validationCustom04"
                    formControlName="endTime"
                    placeholder="End Time"
                  >
                    <option value="" disabled selected>Select Time</option>
                    <option
                      *ngFor="let time of selectValue; let index = index"
                      [value]="time"
                    >
                      {{ time }}
                    </option>
                  </select>
                  <div
                    *ngIf="submit && form.endTime.errors"
                    class="invalid-feedback"
                  >
                    <span *ngIf="form.endTime.errors.required"
                      >Please provide a end time.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      formControlName="checkplanned"
                      (click)="downtimeAdd($event)"
                      class="custom-control-input"
                      id="invalidCheck"
                    />
                    <label class="custom-control-label" for="invalidCheck"
                      >Is this shift contains planned downtime?</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="validationform.get('checkplanned')['value'] == true">
              <form [formGroup]="validationform" autocomplete="off">
                <button
                  *ngIf="showadd"
                  class="btn btn-primary"
                  (click)="getInterval(); addItem()"
                  type="button"
                >
                  Add
                </button>
                <table class="responsive">
                  <thead
                    *ngIf="
                      validationform.get('planneddowntime').value &&
                      validationform.get('planneddowntime').value.length > 0
                    "
                  >
                    <tr>
                      <td>Downtime Name</td>
                      <td>Duration</td>
                      <td>Start Time</td>
                      <!-- <td>End Time</td> -->
                      <td>Delete</td>
                    </tr>
                  </thead>
                  <tbody
                    formArrayName="planneddowntime"
                    *ngFor="
                      let item of validationform.get('planneddowntime')[
                        'controls'
                      ];
                      let i = index
                    "
                  >
                    <tr [formGroupName]="i">
                      <td>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="validationCustom05"
                            formControlName="downtimename"
                            placeholder="Name"
                            (change)="plannedendTime($event)"
                          />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="validationCustom06"
                            formControlName="duration"
                            placeholder="Duration"
                          >
                            <option value="" selected disabled>
                              Select Time
                            </option>
                            <option
                              *ngFor="let time of shiftInterval"
                              [value]="time"
                            >
                              {{ time }}
                            </option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="validationCustom07"
                            formControlName="plannedstarttime"
                            placeholder="Start time"
                            (change)="plannedendTime($event)"
                          >
                            <!-- <option value="" *ngFor="let dis of disabledTime"  disabled>
                                                        {{dis}}
                                                    </option> -->
                            <option value="" selected disabled>
                              Select Time
                            </option>
                            <option
                              *ngFor="
                                let time of plannedInterval;
                                let j = index
                              "
                              [value]="time"
                              [attr.hidden]="isOptionDisabled(j) ? true : null"
                            >
                              {{ time }}
                            </option>
                          </select>
                        </div>
                      </td>
                      <!-- <td>
                                            <div class="form-group">
                                                <input disabled type="text" class="form-control"
                                                    id="validationCustom08" formControlName="plannedendtime"
                                                    placeholder="End Time" [(ngModel)]="item.plannedendtime">
                                            </div>
                                        </td> -->
                      <td>
                        <button class="btn btn-danger" (click)="removeRow(i)">
                          X
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
            <div class="d-flex justify-content-between">
              <button
                *ngIf="!isEdit"
                class="btn btn-primary"
                (click)="save()"
                type="submit"
              >
                Submit
              </button>
              <button
                *ngIf="isEdit"
                class="btn btn-primary"
                (click)="save()"
                type="submit"
              >
                Update
              </button>
              <button class="btn btn-primary" (click)="cancel()">Cancel</button>
            </div>
          </form>
        </div>
      </div>
      <!-- end card -->
    </div>
  </div>

  <ng-template #deletetemp role="document" let-modal="close">
    <div class="modal-header">
      <h5 class="modal-title">Delete Shift</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body card-body">
      <div class="example-container">
        <form>
          <div>
            <p>Are you sure to Delete?</p>
          </div>
          <button
            type="submit"
            class="btn btn-danger round waves-effect headr"
            (click)="delete(delShiftId)"
          >
            Delete
          </button>
          &nbsp;
          <button
            type="submit"
            class="btn btn-info round waves-effect headr"
            (click)="modal('Cross click')"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </ng-template>

  <div *ngIf="shiftsArr?.length == 0 && !isAdd">
    <strong>{{ errmsg }} </strong>
  </div>
</div>
<!-- container-fluid -->
