import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";

import { Router } from "@angular/router";
// import {
//   statData,
//   revenueChart,
//   salesAnalytics,
//   sparklineEarning,
//   sparklineMonthly,
//   chatData,
//   transactions,
// } from "./data";
import { DataService } from "src/app/shared/Services/data.service";
import { HttpClient } from "@angular/common/http";
// import { ChartComponent } from "ng-apexcharts";
import { Subject } from "rxjs";
import { ShareService } from "src/app/shared/Services/shared.service";

export interface ChartType {
  series?: any;
  plotOptions?: any;
  stroke?: any;
  dataLabels?: any;
  chart?: any;
  legend?: any;
  colors?: any;
  labels?: any;
}

@Component({
  selector: "app-admin_dashboard",
  templateUrl: "./admin_dashboard.component.html",
  styleUrls: ["./admin_dashboard.component.scss"],
})

/**
 * Dashboard Component
 */
export class AdminDashboardComponent implements OnInit, OnDestroy {
  // @ViewChild("chart") chart: ChartComponent;
  private destroy$: Subject<void> = new Subject<void>();

  // public sparklineEarning: Partial<any>;
  sparklineEarning: any = {};
  term: any;

  custId;
  apiInterval: any;
  selectedCustomerId;
  customerList = [];
  constructor(
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private http: HttpClient,
    private router: Router,
    private sharedService: ShareService
  ) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = user["user"]["custId"];
  }
  customerId;
  // machine_List: any = [
  //   {
  //     device_id: "348518678A68",
  //     device_name: "IOT_Prod_8A68",
  //     current: 4.97,
  //     status: 1,
  //     LUT: "1707999917280",
  //     part_count: 2,
  //     oee: 56,
  //     custId: "YFQUK",
  //   },
  //   {
  //     device_id: "348518677310",
  //     device_name: "IOT_Prod_7310",
  //     current: 0.01,
  //     LUT: "1707290844930",
  //     custId: "YFQUK",
  //   },
  //   {
  //     device_id: "348518678830",
  //     device_name: "IOT_Prod_8830",
  //     current: 5.14,
  //     status: 0,
  //     LUT: "1707999910740",
  //     part_count: 15,
  //     oee: 87,
  //     custId: "YFQUK",
  //   },
  //   {
  //     device_id: "348518677FC4",
  //     device_name: "IOT_Prod_7FC4",
  //     current: 0.18,
  //     status: 0,
  //     LUT: "1707294803920",
  //     custId: "YFQUK",
  //   },
  //   {
  //     device_id: "3485186775B8",
  //     device_name: "IOT_Prod_75B8",
  //     current: 0.01,
  //     LUT: "1706796278110",
  //     custId: "YFQUK",
  //   },
  //   {
  //     device_id: "3465186775B8",
  //     device_name: "IOT_Prod_7545",
  //     current: 0.05,
  //     LUT: "1706796277110",
  //     custId: "3D3BR",
  //   },
  //   {
  //     device_id: "3465186775B8",
  //     device_name: "IOT_Prod_7578",
  //     current: 0.1,
  //     status: 5,
  //     LUT: "1706796278610",
  //     custId: "3D3BR",
  //   },
  //   {
  //     device_id: "3465186775B8",
  //     device_name: "IOT_Prod_7596",
  //     current: 0.21,
  //     status: 1,
  //     LUT: "1706796278710",
  //     custId: "3D3BR",
  //   },
  //   {
  //     device_id: "3465186775B8",
  //     device_name: "IOT_Prod_7512",
  //     current: 0.81,
  //     status: 0,
  //     LUT: "1706796278810",
  //     custId: "3D3BR",
  //   },
  // ];
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // revenueChart: ChartType;
  // salesAnalytics: ChartType;
  // sparklineEarning: ChartType;
  // sparklineMonthly: ChartType;

  // Form submit
  chatSubmit: boolean;

  formData: FormGroup;

  options = {
    layers: [
      tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909),
  };

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "K2" },
      { label: "Dashboard", active: true },
    ];
    this.formData = this.formBuilder.group({
      message: ["", [Validators.required]],
    });
    // var custId = JSON.parse(sessionStorage.getItem("Selected_CustomerID"));
    console.log(this.customerId);
    // if (custId === null) {
    this.getUser();
    // } else {
    // this.getCustData(custId);
    // }

    // this.getCustData(this.customerId);
    // this.apiInterval = setInterval(() => {
    //   this.getCustData(this.customerId);
    // }, 30000);
  }
  ngOnDestroy() {
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Returns form
   */

  getUser() {
    this.dataservice.get("api/user").subscribe((res: any) => {
      console.log(res, "res");
      let response = res;
      var custList = response.filter((val) => {
        return val.name !== "SUPERADMIN";
      });
      console.log(custList, "abcd");
      this.validate_customer(custList);
    });
  }
  validate_customer(data) {
    const filteredData = data.filter((item) => {
      return (
        item.status &&
        Array.isArray(item.status.deviceDetails) &&
        item.status.deviceDetails.length > 0
      );
    });
    this.customerList = filteredData.map((item) => {
      return {
        custName: item.status.customerName,
        custId: item.status.custId,
      };
    });
    var custId = sessionStorage.getItem("Selected_CustomerID");
    if (custId === null) {
      console.log(custId);
      this.customerId = this.customerList[0]["custId"];
      this.selectedCustomerId = this.customerList[0];
      sessionStorage.setItem("Selected_CustomerName", this.selectedCustomerId.custName);
      sessionStorage.setItem("Selected_CustomerID", this.selectedCustomerId.custId);
      this.getCustData(this.customerId);
    } else {
      console.log(custId);
      this.customerId = custId;
      let selected = this.customerList.filter((val) => {
        return val.custId == custId;
      });
      this.selectedCustomerId = selected[0];
      this.getCustData(this.selectedCustomerId.custId);
    }

    // this.getCustData(this.customerList[0]["custId"]);
    console.log(filteredData, this.customerList[0]);
  }

  get form() {
    return this.formData.controls;
  }
  current: any;
  avail: any;
  m_effic: any;
  parts: any;
  machine_List;
  listData;
  totalCustomers;
  Shift_details;
  showMe: boolean = false;
  alldevice() {
    this.http
      .get(
        "https://k2-oee-prod.azurewebsites.net/api/all_customer_devices?code=HxEbYAVmBylHuCL2upq74beobBDE6a6Mmeh_tC6H6kXuAzFum8ytOw=="
      )
      .subscribe((res) => {
        console.log(res);
        var list = this.convertData(res["customerData"]);
        var machine_List = list;
        console.log(machine_List);
        this.runningMachine(list);
      });
  }
  getCustData(customerId) {
    console.log(customerId);
    // this.alldevice();
    this.showMe = false;
    var device = {
      custID: customerId,
    };
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/devices_oee?code=IDafVwREaejNu5cZkPFu9KcbltWw2a4YKmBewQD5HrteAzFuh1QEQg==",
        device
      )
      // this.http
      //   .get(
      //     "https://k2-oee-prod.azurewebsites.net/api/all_customer_devices?code=HxEbYAVmBylHuCL2upq74beobBDE6a6Mmeh_tC6H6kXuAzFum8ytOw=="
      //   )
      .subscribe(
        (res) => {
          console.log(res, "abcd");
          var data = res;
          this.Shift_details = data["Shift_List"];
          // var customers = res["customerData"];

          // var list = this.convertData(res["customerData"]);
          // this.machine_List = list;
          this.machine_List = data["device_List"];
          // console.log(this.machine_List);
          // this.runningMachine(list);
          this.showMe = true;
          // this.listData = this.machine_List;
          // if (customerId == "All") {
          //   this.machine_List = this.listData;
          // } else {
          //   this.machine_List = this.listData.filter(
          //     (val) => val.custId == customerId
          //   );
          // }
          // console.log(list);
          // this.customerList = this.customerArray(res["customerData"]);
          console.log(this.customerList);
          // this.current = res["current"];
          // this.avail = res["avail"];
          // this.m_effic = res["oee"];
          // this.parts = res["part_count"];
          // this.machine_List = res["device_List"];
          // this.sharedService.setShiftData({
          //   shift: res["Shift_List"],
          // });
        },
        (err) => {
          console.log(err);
          if (err) {
            this.showMe = false;
          }
        }
      );
  }
  refresh() {
    console.log(sessionStorage.getItem("Selected_CustomerID"))
    var custId = sessionStorage.getItem("Selected_CustomerID");
    if (custId === null) {
      this.customerId = this.customerList[0]["custId"];
      this.selectedCustomerId = this.customerId;
      this.getCustData(this.customerId);
    } else {
      this.selectedCustomerId = custId;
      this.getCustData(this.selectedCustomerId);
    }
    // this.getCustData(customerId);
  }
  running;
  idle;
  off_machine;
  totalMachine;
  dry_run;
  warning;
  alarm;
  runningMachine(newData) {
    this.totalMachine = newData;
    this.running = newData.filter((item) => item.status === 2);
    this.idle = newData.filter((item) => item.status === 0);
    this.dry_run = newData.filter((item) => item.status === 1);
    this.warning = newData.filter((item) => item.status === 3);
    this.alarm = newData.filter((item) => item.status === 4);

    this.off_machine = newData.filter(
      (item) => item.status === undefined || item.status === null
    );
    console.log(this.running, this.idle, this.off_machine, newData);
  }
  totalCustomer(data) {
    var arr = data.forEach((customer) => {
      customer.device_List.length;
    });

    return arr;
  }
  convertData(input) {
    const outputArray = [];

    input.forEach((customer) => {
      customer.device_List.forEach((device) => {
        const newData = {
          device_id: device.device_id,
          device_name: device.device_name,
          deviceType: device.deviceType ? device.deviceType : "-",
          current: device.current,
          LUT: device.LUT,
          status: device.status,
          custId: customer.customerId,
          shift: customer.Shift_List,
          oee: device.oee,
          real_power: device.real_power,
          apparent_power: device.apparent_power,
          energy_consumption: device.energy_consumption,
          total_energy: device.total_energy,
          custName: customer.custName ? customer.custName : "None",
        };
        outputArray.push(newData);
      });
    });

    return outputArray;
  }
  customerArray(input) {
    const outputArray = [];
    const seenIds = new Set();
    input.forEach((customer) => {
      customer.device_List.forEach((device) => {
        if (!seenIds.has(customer.customerId)) {
          const newData = {
            custId: customer.customerId,
            custName: customer.customerName ? customer.customerName : "None",
          };
          outputArray.push(newData);
          seenIds.add(customer.customerId);
        }
      });
    });

    return outputArray;
  }
  filter(data) {
    this.customerId;
    if (this.customerId === "All") {
      this.machine_List = this.listData;
      if (data === "running") {
        this.machine_List = this.listData.filter((val) => val.status === 1);
      } else if (data === "idle") {
        this.machine_List = this.listData.filter((val) => val.status === 0);
      } else if (data === "all") {
        this.machine_List = this.listData;
      }
    }
    if (this.customerId != "All") {
      var filterCust = this.listData.filter(
        (val) => val.custId == this.customerId
      );
      if (data === "running") {
        this.machine_List = filterCust.filter((val) => val.status === 1);
      } else if (data === "idle") {
        this.machine_List = filterCust.filter((val) => val.status === 0);
      } else if (data === "all") {
        this.machine_List = filterCust.filter(
          (val) => val.custId == this.customerId
        );
      }
    }

    // else if (data === "running") {
    //   this.machine_List = this.listData.filter((val) => val.status === 1);
    // } else if (data === "idle") {
    //   this.machine_List = this.listData.filter((val) => val.status === 0);
    // }
  }
  onChange(custId: any) {
    console.log(custId)
    sessionStorage.setItem("Selected_CustomerName", custId.custName);
    sessionStorage.setItem("Selected_CustomerID", custId.custId);
    this.getCustData(custId.custId);
    this.customerId = custId.custId;
    // if (custId == "All") {
    //   this.machine_List = this.listData;
    //   this.runningMachine(this.machine_List);
    // } else {
    //   this.machine_List = this.listData.filter((val) => val.custId == custId);
    //   this.runningMachine(this.machine_List);
    // }
  }
  epochToHHMMSS(epochTime) {
    if (typeof epochTime === 'undefined') {
      return '-';
  }
    console.log(epochTime)

    const isMilliseconds = epochTime > 9999999999;
    const date = new Date(isMilliseconds ? epochTime : epochTime * 1000);

    // Extract day, month, year, hours, minutes, and seconds in local time
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0');

    // Format the date and time as dd/mm/yy hh:mm:ss AM/PM in local time
    return `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  }
  statusColor(data) {
    switch (data) {
      case 1: {
        return "#ffff94";
        break;
      }
      case 0: {
        return "#f7c030";
        break;
      }
      case 2: {
        return "#548237";
        break;
      }
      case 3: {
        return "#bfe0a5";
        break;
      }
      case 4: {
        return "#ee4932";
        break;
      }
      default: {
        return "#0092ff";
        //statements;
        break;
      }
    }
  }

  redirctTO(data) {
    console.log(data, this.customerId, this.selectedCustomerId);

    this.sharedService.setCheckedList({
      custID: this.customerId,
      deviceID: data.device_id,
      deviceName: data.device_name,
      deviceType: data.deviceType,
    });
    this.sharedService.setShiftData({
      shift: this.Shift_details,
    });
    var shift: any = {
      shift: this.Shift_details,
    };
    var record: any = {
      custID: this.customerId,
      deviceID: data.device_id,
      deviceName: data.device_name,
      deviceType: data.deviceType,
    };
    sessionStorage.setItem("shiftInfio", JSON.stringify(shift));
    sessionStorage.setItem("machineInfio", JSON.stringify(record));
    this.router.navigate(["/machineInfo"]);
  }
  /**
   * Save the message in chat
   */
}
