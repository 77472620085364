import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment-timezone";
import bsCustomFileInput from "bs-custom-file-input";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/Services/data.service";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import { ngxphoneNumberValidator } from "src/app/shared/Services/custom-validator";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-customer_info",
  templateUrl: "./customer_info.component.html",
  styleUrls: ["./customer_info.component.scss"],
})

/**
 * Form-device component
 */
export class CustomerInfoComponent implements OnInit {
  // bread crumb items
  typesubmit: boolean;
  timeZones: string[];
  deviceForm: FormGroup; // bootstrap validation form
  custList: any;
  currentPage = 1;
  itemsPerPage = 5;
  validationform: FormGroup;
  submitted: boolean = false;
  pageSize;
  customerProfile: boolean = false;
  id: "";
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedStates,
  ];

  breadCrumbItems: Array<{}>;

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dataservice: DataService,
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.timeZones = moment.tz.names();
    this.breadCrumbItems = [
      { label: "Configuration" },
      { label: "Customer", active: true },
    ];
    this.getUsers();
    bsCustomFileInput.init();
  }

  getUsers() {
    this.dataservice.get("api/user").subscribe((res: any) => {
      console.log(res, "res");
      let response = res;
      this.custList = response.filter((val) => {
        return val.name !== "SUPERADMIN";
      });
    });   
  }

  acc(data) {
    if (data) return "Completed";
    else return "Pending";
  }

  navigate(data, page) {
    console.log(data, page);
    if (page == "device") {
      this.router.navigate(["/device", { id: data["status"].custId }]);
    } else {
      this.router.navigate(["/shift", { id: data["status"].custId }]);
    }
  }

  openModal(infotemplate: any, data: any) {
    console.log(data, "data");
    this.id = data.id;
    this.modalService.open(infotemplate, { centered: true });
    this.validationform = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      mobileNumber: ["", [Validators.required, ngxphoneNumberValidator]],
      alernateMobileNumber: ["", [ngxphoneNumberValidator]],
      plant: ["", [Validators.required]],
      noOfMachines: ["", [Validators.required]],
      timeZone: ["", [Validators.required]],
    });
    console.log(this.validationform, "this.validationform");
    if (data) {
      if (!data?.plant) {
        this.customerProfile = true;
      }
      this.pathValue(this.validationform, data);
      this.validationform.get("email").disable();
      // this.validationform.get("mobileNumber").disable();
    }
  }

  get form() {
    return this.validationform.controls;
  }

  pathValue(formGroup: FormGroup, data: any): void {
    const controls = formGroup.controls;
    for (const key in controls) {
      controls[key].patchValue(data[key]);
    }
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
    console.log(pageNum, this.pageSize, "pageNum");
  }

  saveData() {
    this.submitted = true;
    this.validationform.enable();
    console.log(this.validationform);
    if (this.validationform.valid) {
      this.dataservice
        .put("api/user/" + this.id, this.validationform.value)
        .subscribe((res) => {
          console.log(res, "response");
          if (res) {
            this.submitted = false;
            this.modalService.dismissAll();
            this.toastr.success("Customer Details Updated");
            if (this.customerProfile) {
              this.createCustomer(this.validationform.value);
            } else {
              this.getUsers();
            }
          }
        });
    } else {
      if (
        this.validationform.controls["mobileNumber"].errors.invalidPhoneNumber
      ) {
        this.toastr.error(
          "Invalid phone number or enter Phone number with country code"
        );
      } else if (this.validationform.controls["mobileNumber"].errors) {
        this.toastr.error("Please enter Mobile Number");
      } else {
        return this.validationform.controls;
      }
    }
  }

  createCustomer(data) {
    this.dataservice
      .post(`api/customer?id=${this.id}`, data)
      .subscribe((res) => {
        console.log(res, "response");
        if (res) {
          this.toastr.success("Customer Details Updated");
          this.getUsers();
        }
      });
  }

  cancel() {
    this.submitted = false;
    this.modalService.dismissAll();
  }
}
